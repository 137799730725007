/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewProduct page components
import ProductInfo from "layouts/ecommerce/products/new-product/components/ProductInfo";
import Media from "layouts/ecommerce/products/new-product/components/Media";
import Socials from "layouts/ecommerce/products/new-product/components/Socials";
import Pricing from "layouts/ecommerce/products/new-product/components/Pricing"; 
import BasicInfo from "./components/BasicInfo";
import Requirements from "./components/Requirements";
import Benefits from "./components/Benefits";
import Location from "./components/location";
import Application from "./components/Application";
import VacancyFewDetail from "../vacancy-detail/vacancyFewDetail";
import { CircularProgress, LinearProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { HttpService } from "Api-Services/httpService";
import { useCurrentUser } from "Api-contexts/currentUserContext";

function getSteps() {
  return ["1. Basic Info", "2. Requirments", "3. Benefits ",  "4. location ","5. Application"];
}


function NewVacancy() {
  
  const [currentContextData, updateContext  ] = useCurrentUser();  
  const {myBusinessEmploymentAgency,currentUser }  = currentContextData;  
 

  const [ loading, setLoading] = useState(false);
  const [  vacancySubmittedResponse, setVacancySubmittedResponse] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [isCurrentSectionValid, setIsCurrentSectionValid] = useState(false);
  const [vacancyData, setVacancyData] = useState({});
  const [isReviewSection, setIsReviewSection] = useState(false);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

   const [basicInfoSectionValues, setBasicInfoSectionValues] = useState(
    {title:{value:"",required:true},
    description:{value:"",required:false} ,
    employmentTypeId:{value:1,required:false},
    applicantGender:{value:"any",required:true},
    requiredNumberOfEmployees:{value:"",required:false},
    deadLineDateTime:{value:"",required:true}}); 

   const [requirementsSectionValues, setRequirementsSectionValues] = useState(
      { 
        requiredFieldOfStudies: {value:"",required:false}, 
        employeeResponsiblity:{value:"",required:true},    
        requiredSkills: {value:"",required:false},    
        requiredExperiances: {value:"",required:false},
        minimumExperianceInYear:{value:0,required:false},
        maximumExperianceInYear: {value:0,required:false}, 
    });

   const [benefitsSectionValues, setBenefitsSectionValues] = useState(
         { 
          salaryDealTypeId:{value:1,required:true}, 
          // isSalaryMentioned:{value:"",required:false}, 
          salary: {value:"",required:false},     
          salaryCurrency:{value:"ETB",required:false},     
          allowances: {value:"",required:false},   
          salaryDuration: {value:"Monthly",required:false},   
       });

   const [locationSectionValues, setLocationSectionValues] = useState(
            {   
              jobLocationTypeId:{value:1,required:false},//inPerson//remote//ontheroad
              // country: {value:"",required:false},
              city: {value:"Addis Ababa",required:false},
              subCity: {value:"Any",required:false},
              specificArea: {value:"Any",required:false},
          });

   const [applicationSectionValues, setApplicationSectionValues] = useState(
               {howToApply:{value:"",required:false}
             });
  

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  const handleReview = () => {
    let vacancyDataD={basicInfo:{},requirements:{},benefits:{},location:{},application:{}}
  
    let basicInfoD= Object.entries(basicInfoSectionValues)  
    let basicInfoD2={}
    basicInfoD.forEach(element => {
     if(element.length){
      basicInfoD2[element[0]]=element[1].value
     }
    });
    vacancyDataD.basicInfo=basicInfoD2

    let requirementsD= Object.entries(requirementsSectionValues)  
    let requirementsD2={}
    requirementsD.forEach(element => {
     if(element.length){
      requirementsD2[element[0]]=element[1].value
     }
    });
    vacancyDataD.requirements=requirementsD2
    
    let benefitsD= Object.entries(benefitsSectionValues)  
    let benefitsD2={}
    benefitsD.forEach(element => {
      
    // console.log("  vacancyDataD element",   element)
     if(element.length){
      benefitsD2[element[0]]=element[1].value  
     }
    });
    // console.log("  vacancyDataD benefitsD2",   benefitsD2)
    vacancyDataD.benefits=benefitsD2
    
    let locationD= Object.entries(locationSectionValues)  
    let locationD2={}
    locationD.forEach(element => {
     if(element.length){
      locationD2[element[0]]=element[1].value
     }
    });
    vacancyDataD.location=locationD2
    
    let applicationD= Object.entries(applicationSectionValues)  
    let applicationD2={}
    applicationD.forEach(element => {
     if(element.length){
      applicationD2[element[0]]=element[1].value
     }
    });
    vacancyDataD.application=applicationD2
    // console.log("  vacancyDataD",   vacancyDataD)

      setVacancyData(vacancyDataD)
      setLoading(false)
      setVacancySubmittedResponse(null)
      setIsReviewSection(true)
  };

  const handleSubmit = async() => {
    try {
      if(myBusinessEmploymentAgency){
        if(myBusinessEmploymentAgency.id){

    let vacancyDataD= Object.entries(vacancyData)  
    let data={}

    vacancyDataD.forEach(element => {
     if(element.length){
      if(element[1]){
          let vacancyDataDProperties=Object.entries(element[1])  
           vacancyDataDProperties.forEach(elementP => {
            if(element.length){
            // console.log("  elementP",   elementP[0]," : ",elementP[1])
            data[elementP[0]]=elementP[1]
            }
          })
      } 
     }
    });

    // console.log("  myBusinessEmploymentAgency",   myBusinessEmploymentAgency)
    data.employmentAgencyId=myBusinessEmploymentAgency.id
    data.employmentAgencyFullName=myBusinessEmploymentAgency.fullName||myBusinessEmploymentAgency.brandName
    data.employmentAgencyEmail=myBusinessEmploymentAgency.contactEmail
    data.employmentAgencyPhone=myBusinessEmploymentAgency.phone1||myBusinessEmploymentAgency.phone2

    // vacancyDataD.application=applicationD2
    // console.log("  data",   data)
    // return
    let relativePath = "/api/employmentjobvacancy/new";
    setLoading(true)
    let postvacancyRes= await postDocuments("postvacancy",relativePath,data)
    setLoading(false)
    setVacancySubmittedResponse(postvacancyRes)
      // setVacancyData(vacancyDataD)
      // setLoading(false)
      // setVacancySubmittedResponse(null)
      // setIsReviewSection(true)
      
        }
      }
    } catch (error) {
      
    }
    
  };

  
  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false }; 

    documentsResponse = await HttpService.postService(
      relativePath,
      data,
      {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    } else {
      responseD.message = documentsResponse.message;
 
    }  
    return responseD; 
  };


  const validateCurrentSection = (values) =>
  {
    // setIsCurrentSectionValid(isValid)
    let isValidSec=true
    let valuesEnt= Object.entries(values)   
     valuesEnt.forEach(element => {
      if(element.length){
       let elementD=element[1]||{}
       if(elementD.required){
        if(elementD.value==""||elementD.value==null){
           isValidSec=false
          }
       }
      }
     });
    //  console.log("  isValidSec",   isValidSec)
     setIsCurrentSectionValid(isValidSec)

  }

  const changeSectionValues = (field,values) =>
  {
    // console.log("values index",field,values)
    if("basicInfoSectionValues"==field){
      setBasicInfoSectionValues(values)
    } 
    if("requirementsSectionValues"==field){
      setRequirementsSectionValues(values)
    } 
    if("benefitsSectionValues"==field){
      setBenefitsSectionValues(values)
    } 
    if("locationSectionValues"==field){
      setLocationSectionValues(values)
    } 
    if("applicationSectionValues"==field){
      setApplicationSectionValues(values)
    } 
    validateCurrentSection(values)
    // setForceRerender(!forceRerender)
  }
  
  const getDataFromArray = (dataArray, searchField, searchValue, returnRows, returnField) => {
    let res = {};
    // console.log("dataArray   ", dataArray,searchField, searchValue, returnRows, returnField);
    try {
      if (dataArray && searchField && searchValue) {
        if (dataArray.length > 0) {
          let filteredLevels = dataArray.filter((level) => level[searchField] == searchValue);
          if (filteredLevels.length) {
            res = filteredLevels;
            if (returnRows == "single") {
              let row = filteredLevels[0];
              res = row;
              if (returnField) {
                let rowField = row[returnField];
                res = rowField;
              }
            }
          }
        }
      }
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
    return res;
  };

  const getStepContent=(stepIndex)=> {
    switch (stepIndex) {
      case 0:
        return <BasicInfo getDataFromArray={getDataFromArray} validateSection={validateCurrentSection} sectionValues={basicInfoSectionValues} changeSectionValues={changeSectionValues}/>;
      case 1:
        return <Requirements getDataFromArray={getDataFromArray} validateSection={validateCurrentSection} sectionValues={requirementsSectionValues} changeSectionValues={changeSectionValues} />;
      case 2:
        return <Benefits getDataFromArray={getDataFromArray} validateSection={validateCurrentSection} sectionValues={benefitsSectionValues} changeSectionValues={changeSectionValues} />;
      case 3:
        return <Location getDataFromArray={getDataFromArray} validateSection={validateCurrentSection} sectionValues={locationSectionValues} changeSectionValues={changeSectionValues} />;
      case 4:
        return <Application getDataFromArray={getDataFromArray}  validateSection={validateCurrentSection} sectionValues={applicationSectionValues} changeSectionValues={changeSectionValues} />;
      default:
        return null;
    }
  }
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center"> 
        {(!loading&&(vacancySubmittedResponse==null))&&  <Grid item xs={12} lg={10}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Add New Vacancy
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" fontWeight="regular" color="secondary">
                This information will describe more about the Vacancy.
              </MDTypography>
            </MDBox>
             {!isReviewSection?
            <Card> <div>
              <MDBox mt={-3} mb={3} mx={2}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                <MDBox>
                  {getStepContent(activeStep)}
                  <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                    {activeStep === 0 ? (
                      <MDBox />
                    ) : (
                      <MDButton variant="gradient" color="light" onClick={handleBack}>
                        back
                      </MDButton>
                    )}
                    <MDButton
                      disabled={!isCurrentSectionValid}
                      variant="gradient"
                      color="dark"
                      onClick={!isLastStep ? handleNext : handleReview}
                    >
                      {isLastStep ? "Finish and Review" : "next"}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox></div> 
               </Card>:<>
              {
              <>
              <VacancyFewDetail 
              vacancyData={vacancyData}
              // vacancyData={{basicInfo:basicInfoSectionValues,requirements:requirementsSectionValues,benefits:benefitsSectionValues,location:locationSectionValues,application:applicationSectionValues}}
              />
             
              <MDBox p={2}>
                <MDBox> 
                  <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                      <MDButton variant="gradient" color="light" onClick={()=>setIsReviewSection(false)}>
                        Edit
                      </MDButton> 
                    <MDButton
                      // disabled={!isCurrentSectionValid}
                      variant="gradient"
                      color="success"
                      onClick={!isLastStep ? handleNext : handleSubmit}
                    >
                      {"Submit"}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox> </>}
          </>}
       
          </Grid>}

          {loading&&<Grid item xs={12} lg={10}>
            <MDBox p={2}>
                <MDBox> 
                <MDBox mb={1}>
              </MDBox>
              {/* <MDTypography variant="h5" fontWeight="regular" color="secondary">
                This information will describe more about the Vacancy.
              </MDTypography> */}
                  <MDBox mb={1} width="100%" display="flex" justifyContent="center">
                       <LinearProgress />
                       <CircularProgress />
                   </MDBox>
                  <MDBox mt={3} width="100%" display="flex" justifyContent="center">
                      <MDBox mb={1} width="100%" display="flex" justifyContent="center">
                        <MDTypography variant="h5" >
                          Submitting The Vacancy
                        </MDTypography>
                      </MDBox> 
                  </MDBox>
                </MDBox>
              </MDBox> 
            </Grid>}
            
          { vacancySubmittedResponse&&<Grid item xs={12} lg={10}>
            <MDBox p={2}>
                <MDBox>  
              {/* <MDTypography variant="h5" fontWeight="regular" color="secondary">
                This information will describe more about the Vacancy.
              </MDTypography> */} 
                  <MDBox mt={3} width="100%" display="flex" justifyContent="center">
                      <MDBox mb={1} width="100%" display="flex" justifyContent="center">
                        <MDTypography variant="h5" >
                          {vacancySubmittedResponse.message}
                        </MDTypography>
                      </MDBox> 
                  </MDBox>
 
                  <MDBox p={2}>
                  <MDBox mt={3}   width="100%" display="flex" justifyContent="center">
                     {!vacancySubmittedResponse.success&&
                       <MDButton variant="gradient" color="light" onClick={handleReview}>
                        Back To Review
                      </MDButton> }
                    {vacancySubmittedResponse.success&&<MDButton
                      // disabled={!isCurrentSectionValid}
                      variant="gradient"
                      color="success"
                       
                        size={"small"}
                        to={"/Employment/Job-Vacancies"}
                        component={Link}  
                      // onClick={!isLastStep ? handleNext : handleReview}
                    >
                      {"Go To Vacancy List"}
                    </MDButton>}
                  </MDBox> 
                </MDBox> 

                </MDBox>
              </MDBox> 
            </Grid>}

          {/* set vacancySubmittedResponse */}



        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default NewVacancy;
