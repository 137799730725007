/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React from "react";
import { HttpService } from "Api-Services/httpService";
import { CommonServices } from "Api-Services/commonServices";
import { Avatar, Divider } from "@mui/material";
import { UseAuth } from "Api-Services/useAuth";
import { CheckCircleRounded } from "@mui/icons-material";

function MyEmploymentAgencies({ title, profiles, shadow }) {
  const [currentBusiness, setCurrentBusiness] = React.useState(null);
  const [forceRerender, setForceRerender] = React.useState(false);
  const [currentContextData, updateContext] = useCurrentUser();
  const {myCurrentProfile,myBusinesses } = currentContextData;

  /////////////////
  React.useEffect(() => {
    (async () => { 
      setForceRerender(!forceRerender)
    })();
  }, [myCurrentProfile,myBusinesses]);

  const loginToWebsite = async (websiteData) => {
    let addUserToWebsiteRes=await UseAuth.switchProfileById(websiteData.id);   
    if (addUserToWebsiteRes.success) {
      localStorage.clear();
      let path = "/";
      window.location.assign(path);
    } else {
      // setPopUpFields({type:"information",title:"info ",subTitle:addUserToWebsiteRes.message,contents:[]})
      // setOpenPopUp(true)
    }
  };

  const renderProfiles = ()=>{ 
    let res=<></> 
    if(myBusinesses?(myBusinesses.length?true:false):false){
      res= <>{ myBusinesses.map((agency) =>(
        ((myCurrentProfile?((myCurrentProfile.id==agency.id)?false:true):true))?
           <MDBox
          key={name}
          component="li"
          display="flex"
          alignItems="center"
          py={1}
          mb={1}
        >
          <MDBox mr={2}>
            <Avatar src={agency.profileImageUrl} alt="something here" shadow="md" />
          </MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <MDTypography variant="button" fontWeight="medium">
              {CommonServices.getProfileName(agency)}
            </MDTypography>
            {/* <MDTypography variant="caption" color="text">
              {"description"}
            </MDTypography> */}
          </MDBox>
          <MDBox ml="auto">
            <MDButton
              // component={Link}
              // to={"action.route"}
              onClick={()=>{loginToWebsite(agency)}}
              variant="contained"
              size="small"
              color="info"
            >
              {"Enter"}
            </MDButton>
          </MDBox>
        </MDBox>:<></> 
      ))}</>
    }
    return res
    }

  return (<>{forceRerender}
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={2} px={2}>
       {myBusinesses&& <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {"My Profiles "}
        </MDTypography>}
      </MDBox>
     { myCurrentProfile&&<MDBox p={2}>
         <MDBox
          key={name}
          component="li"
          display="flex"
          alignItems="center"
          py={1}
          mb={1}
        >
          <MDBox mr={2}>
            <Avatar src={myCurrentProfile.profileImageUrl} alt="something here" shadow="md" />
          </MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <MDTypography variant="button" fontWeight="medium">
              {CommonServices.getProfileName(myCurrentProfile)}
            </MDTypography>
            {/* <MDTypography variant="caption" color="text">
              {"description"}
            </MDTypography> */}
          </MDBox>
          <MDBox ml="auto">
             <Avatar><CheckCircleRounded/></Avatar>
          </MDBox>
        </MDBox>
        <Divider/>
      </MDBox>}
      <MDBox p={2}>  
       { (myBusinesses?(myBusinesses.length?true:false):false)&&
       <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles()}
        </MDBox>}
      </MDBox>
    </Card>
 </> );
}

// Setting default props for the MyEmploymentAgencies
MyEmploymentAgencies.defaultProps = {
  shadow: true,
};

// Typechecking props for the MyEmploymentAgencies
MyEmploymentAgencies.propTypes = {
  title: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  shadow: PropTypes.bool,
};

export default MyEmploymentAgencies;
