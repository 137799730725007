/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import AllProjects from "layouts/pages/profile/all-projects";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Timeline from "layouts/pages/projects/timeline";
import PricingPage from "layouts/pages/pricing-page";
import Widgets from "layouts/pages/widgets";
import RTL from "layouts/pages/rtl";
import Charts from "layouts/pages/charts";
import Notifications from "layouts/pages/notifications";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import AllPlatforms from "layouts/applications/all-platforms";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ResetCover from "layouts/authentication/reset-password/cover";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";
import { Message, NotificationsActiveRounded, People, PermPhoneMsg, Web, Work, WorkOutline } from "@mui/icons-material";
import Messages from "layouts/CRM/Messages";
import ContactUsMessages from "layouts/CRM/ContactUsMessages";
import JobSeekers from "layouts/Employment/Job-Seekers";
import JobHiringRequests from "layouts/Employment/Job-Hiring-Requests";
import WebContents from "layouts/pages/account/web-contents";
import MyAgencies from "layouts/pages/account/profile";
import JobVacancies from "layouts/Employment/job-vacancies";

const routesAgencyLevel = [
   
  { type: "divider", key: "divider-0" },
  { type: "title", title: "My Agency", key: "title-pages" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: "/dashboards/analytics",
        component: <Analytics />,
      },
      {
        name: "Sales",
        key: "sales",
        route: "/dashboards/sales",
        component: <Sales />,
      },
    ],
  },
  // {
  //   type: "collapse",
  //   icon: <Icon fontSize="medium">shopping_basket</Icon>,
  //   name: "Account",
  //   key: "account",
  //   collapse: [
  //     {
  //       name: "Settings",
  //       key: "settings",
  //       route: "/pages/account/settings",
  //       component: <Settings />,
  //     },
  //     {
  //       name: "Payment & Billing",
  //       key: "billing",
  //       route: "/pages/account/billing",
  //       component: <Billing />,
  //     },
  //     {
  //       name: "Invoice",
  //       key: "invoice",
  //       route: "/pages/account/invoice",
  //       component: <Invoice />,
  //     },
  //   ],
  // }, 
  // {
  //   type: "collapse",
  //   name: "Web Contents",
  //   key: "Web Contents",
  //   icon: <Web/>,
  //   route: "/My-Agency/Web-Contents",
  //   component: <WebContents/>,
  //   noCollapse: true,
  // },  
  // { type: "divider", key: "divider-1" },
  { type: "title", title: "Employment", key: "title-pages" },
  {
    type: "collapse",
    name: "Job Vacancies",
    key: "Job Vacancies",
    icon: <WorkOutline/>,
    route: "/Employment/Job-Vacancies",
    component: <JobVacancies/>,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Job Seekers",
  //   key: "Job Seekers",
  //   icon: <People/>,
  //   route: "/Employment/Job-Seekers",
  //   component: <JobSeekers/>,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Hiring Requests",
  //   key: "Hiring Requests",
  //   icon: <Work/>,
  //   route: "/Employment/Hiring-Requests",
  //   component: <JobHiringRequests/>,
  //   noCollapse: true,
  // }, 
  // {
  //   type: "collapse",
  //   name: "AllPlatforms",
  //   key: "AllPlatforms",
  //   icon: <Message/>,
  //   route: "/Employment/All-Platforms",
  //   component: <AllPlatforms/>,
  //   noCollapse: true,
  // }, 
//   {
// type: "collapse",
// icon: <Icon fontSize="medium">shopping_basket</Icon>,
//     name: "Products",
//     key: "products",
//     collapse: [
//       {
//         name: "All products",
//         key: "all-products",
//         route: "/ecommerce/products/new-products",
//         component: <AllPlatforms />,
//       },
//       {
//         name: "New Product",
//         key: "new-product",
//         route: "/ecommerce/products/new-product",
//         component: <NewProduct />,
//       },
//       {
//         name: "Edit Product",
//         key: "edit-product",
//         route: "/ecommerce/products/edit-product",
//         component: <EditProduct />,
//       },
//       {
//         name: "Product Page",
//         key: "product-page",
//         route: "/ecommerce/products/product-page",
//         component: <ProductPage />,
//       },
//     ],
//   },
//   {
//     type: "collapse",
//     icon: <Icon fontSize="medium">shopping_basket</Icon>,
//     name: "Orders",
//     key: "orders",
//     collapse: [
//       {
//         name: "Order List",
//         key: "order-list",
//         route: "/ecommerce/orders/order-list",
//         component: <OrderList />,
//       },
//       {
//         name: "Order Details",
//         key: "order-details",
//         route: "/ecommerce/orders/order-details",
//         component: <OrderDetails />,
//       },
//     ],
//   }, 
  { type: "divider", key: "divider-1" },
  { type: "title", title: "customers management", key: "title-customers" },
  {
    type: "collapse",
    name: "Messages",
    key: "Messages",
    icon: <Message/>,
    route: "/crm/messages",
    component: <Messages/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Contact Messages",
    key: "Contact Messages",
    icon: <PermPhoneMsg  />,
    route: "/crm/contact-us-messages",
    component: <ContactUsMessages/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "Notifications",
    icon: <NotificationsActiveRounded/>,
    route: "/pages/notifications",
    component: <Notifications />,
    noCollapse: true,
  },
  { type: "divider", key: "divider-1" },
  { type: "title", title: "Docs", key: "title-docs" },
  
  {
    type: "collapse",
    name: "Support",
    key: "Support",
    href: "https://bfamilt.et",
    icon: <Icon fontSize="medium">receipt_long</Icon>,
    noCollapse: true,
  },
];

export default routesAgencyLevel;
