/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

import parse from "html-react-parser";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/pages/profile/components/Header";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings";

// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useEffect, useState } from "react";
import { Button, Card,CardContent, Dialog, DialogActions, DialogContent, DialogContentText, Icon, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import { jobLocationTypes } from "Api-master-datas/masterDatas";
import { salaryDealTypes } from "Api-master-datas/masterDatas";
import { employmentTypes } from "Api-master-datas/masterDatas";

function VacancyFewDetail(props) {
  
  const [openedDialog, setOpenedDialog] = useState(""); 
  const [vacancyData, setVacancyData] = useState({}); 
  const [forceRerender, setForceRerender] = useState(false); 
  const [moreText, setMoreText] =  useState({ content: "", title: "" });

  useEffect(async() => {
    // console.log("props. ",props )

    if(props.vacancyData){
      //  console.log("vacancyData",props.vacancyData)
    }
    let vacancyD={}
    if(props.isFullData){



    let vacD={
      basicInfo: {
          title:  "", 
          description: "", 
          employmentTypeId: "", 
          applicantGender: "", 
          requiredNumberOfEmployees: "", 
          deadLineDateTime: "", 
        },

        requirements: { 
            requiredFieldOfStudies: "", 
            employeeResponsiblity:"",    
            requiredSkills: "",    
            requiredExperiances: "",
            minimumExperianceInYear:"",
            maximumExperianceInYear: "", 
        },
        benefits:{ 
            salaryDealTypeId:"", 
            // isSalaryMentioned:"", 
            salary: "",     
            salaryCurrency:"",     
            allowances: "",   
            salaryDuration: "",   
        },
        location:{   
            jobLocationTypeId: "",//inPerson//remote//ontheroad
            // country: "",
            city: "",
            subCity: "",
            specificArea:"",
        },
        application:{howToApply:""
           } 
          }

      let vacD2=Object.entries(vacD)  
      let vacD3={}
      vacD2.forEach(element => {
            if(element.length){
              vacD3[element[0]]={}
              if(element[1]){
                let vacD3Prop={}
                 let vacancyDataDProperties=Object.entries(element[1])  
                  vacancyDataDProperties.forEach(elementP => {
                   if(element.length){
                    if(props.vacancyData[elementP[0]] ){
                      vacD3Prop[elementP[0]]=props.vacancyData[elementP[0]] 
                    }
                   }
                 })
                 vacD3[element[0]]= vacD3Prop
             } 
            }
           });
           vacancyD=vacD3
          //  console.log("vacD vacD3",vacD3)
    }
    else{
      vacancyD=props.vacancyData
    }
    setVacancyData(vacancyD)
    setForceRerender(!forceRerender)
  }, [props.vacancyData]);
  
  const listItem=(label,value,valueType, options, optionsSelectedField ,optionsSearchField)=>{
    let res=null
    try {
      if((value!=null)&&(value!="")){
      res=(
        <MDBox key={label} display="flex" py={1} pr={2}>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
            {label}: &nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text">
            &nbsp;
            {valueType?<>
            {(valueType=="file")&& 
                   <MDButton
                   component="a"
                   href={value} 
                   target="_blank"
                   rel="noreferrer"
                   variant="text"
                   color={"info"}
                 >
                   {"Open file"}
                 </MDButton> 
                  
                  }
            {(valueType=="boolean")&&
                  <MDTypography variant={"button"} >
                    {value?"YES":"NO"}
                  </MDTypography>}

            {(valueType=="textarea")&&
                  < >
                    {truncateString(value, 100)}
                  </>}


            {(valueType=="option")&&
                  <MDTypography variant={"button"} >
                    {getDataFromArray(
                    options,
                    optionsSearchField,
                     value,
                    "single",optionsSelectedField

                  )}

             </MDTypography>}


            </>:<>{value}
            </>}
          </MDTypography>
        </MDBox>)
      }
    } catch (error) {
      
    }
    return res
  }
  
  const parseDate = (dt) => {
    let d = new Date(dt);
    let m = d.getMonth() + 1;
    let dateD = m + "/" + d.getDate() + "/" + d.getFullYear();
    return dateD;
  };

  const getDataFromArray = (dataArray, searchField, searchValue, returnRows, returnField) => {
    let res = {};
    // console.log("dataArray   ", dataArray,searchField, searchValue, returnRows, returnField);
    try {
      if (dataArray && searchField && searchValue) {
        if (dataArray.length > 0) {
          let filteredLevels = dataArray.filter((level) => level[searchField] == searchValue);
          if (filteredLevels.length) {
            res = filteredLevels;
            if (returnRows == "single") {
              let row = filteredLevels[0];
              res = row;
              if (returnField) {
                let rowField = row[returnField];
                res = rowField;
              }
            }
          }
        }
      }
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
    return res;
  };
  
  
  const truncateString = (str, num, lableName) => {
    let res = parse(str);
    try {
      if (str) {
        if (str.length > num) {
          let btn = (
           < ><Button
              variant="text"
              onClick={() => {
                setMoreText({ content: str, title: lableName });
                setOpenedDialog("moreText");
                // setForceRerender(!forceRerender);
              }}
            >
              {"View more"}
            </Button></> 
          );
          // str=parse(str)
          let txt = str.slice(0, num) + "...";
          res = (
            <span>
              {parse(txt)} {btn}
            </span>
          );
        }
      }
    } catch (error) {
      // console.log("errrr",error)
    }
    return res;
  };

  return (<> 
  {forceRerender}
      <MDBox mb={2} />
    { (vacancyData)&& <Card my={3} >
        <CardContent>
        <MDBox  mb={3}>
          <Grid container spacing={1}>
            {(vacancyData)&&
            <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <>
                <Card sx={{ height: "100%", boxShadow: !false && "none" }}>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                  <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                    {"Vacancy information"}
                  </MDTypography> 
                </MDBox>
                   <Divider orientation="horizontal"  />

                <MDBox px={3}  >
 
                  <MDBox opacity={0.3}>
                    <Divider />
                  </MDBox>
                <MDBox>     
                 {vacancyData.basicInfo&& <>
                  <MDTypography variant="body2" fontWeight="medium" color="text" textTransform="capitalize" mt={1}>
                                  {"Basic Info:"}
                  </MDTypography> 
                          {listItem("Title", vacancyData.basicInfo.title  )}
                          {listItem("description", vacancyData.basicInfo.description ,"textarea" )}
                         
                         {employmentTypes?listItem("employment Type ", vacancyData.basicInfo.employmentTypeId  ,"option",employmentTypes,"title","id"):null}
 
                          {/* {listItem("employmentTypeId", vacancyData.basicInfo.employmentTypeId  )} */}
                          {listItem("required Number Of Employees", vacancyData.basicInfo.requiredNumberOfEmployees  )}
                          {listItem("Applicant Gender", vacancyData.basicInfo.applicantGender  )}
                          {listItem("DeadLine Date Time", vacancyData.basicInfo.deadLineDateTime  )}
                  </>}


                  {vacancyData.requirements&& <>
                  <Divider />
                  <MDTypography variant="body2" fontWeight="medium" color="text" textTransform="capitalize" mt={3}>
                    {"Requirements:"}
                  </MDTypography>
                          {listItem("required Field Of Studies", vacancyData.requirements.requiredFieldOfStudies  )}
                          {listItem("employee Responsiblity", vacancyData.requirements.employeeResponsiblity ,"textarea" )}
                          {listItem("required Skills", vacancyData.requirements.requiredSkills ,"textarea" )}
                          {listItem("required Experiances", vacancyData.requirements.requiredExperiances  ,"textarea")}
                          {listItem("minimum Experiance In Year", vacancyData.requirements.minimumExperianceInYear  )}
                          {listItem("maximum Experiance In Year", vacancyData.requirements.maximumExperianceInYear  )}
                         
           </> }
    
           {vacancyData.benefits&& <>
                          <Divider />
                <MDTypography variant="body2" fontWeight="medium" color="text" textTransform="capitalize" mt={3}>
                                {"Benefits:"}
                </MDTypography>
                {salaryDealTypes?listItem("salary Deal Type ", vacancyData.benefits.salaryDealTypeId  ,"option",salaryDealTypes,"title","id"):null}
 
                        {listItem("salary", vacancyData.benefits.salary  )}
                        {listItem("salary Currency", vacancyData.benefits.salaryCurrency  )}
                        {listItem("salary Duration", vacancyData.benefits.salaryDuration  )}
                        {listItem("allowances", vacancyData.benefits.allowances ,"textarea" )}

</>}

{vacancyData.location&& <>
                        <Divider />
                        
                <MDTypography variant="body2" fontWeight="medium" color="text" textTransform="capitalize" mt={3}>
                                {"Location:"}
                </MDTypography>
                        {jobLocationTypes?listItem("job Location Type", vacancyData.location.jobLocationTypeId  ,"option",jobLocationTypes,"title","id"):null}
                        {listItem("city", vacancyData.location.city  )}
                        {listItem("sub City", vacancyData.location.subCity  )}
                        {listItem("specific Area", vacancyData.location.specificArea  )} 
</>}


{vacancyData.application&& <>
                        <Divider />


                <MDTypography variant="body2" fontWeight="medium" color="text" textTransform="capitalize" mt={3}>
                                {"application:"}
                </MDTypography>
                        {listItem("how To Apply", vacancyData.application.howToApply  ,"textarea")}
                {/* {listItem("Document",vacancyData,"file")}  */}
                 </>}
                  </MDBox> 
                   
                   
                </MDBox>
              </Card>

              </>
             
              {/* <Divider orientation="vertical" sx={{ mx: 0 }} /> */}
            </Grid>  }  
          </Grid>
        </MDBox>  </CardContent>
      </Card>} 


      
      <Dialog
        open={openedDialog == "moreText"}
        onClose={() => {
          setOpenedDialog("");
        }}
      >
        {moreText && (
          <>
            {moreText.title && <DialogTitle>{parse(moreText.title)}</DialogTitle>}
            {moreText.content && (
              <DialogContent>
                <DialogContentText>{parse(moreText.content)}</DialogContentText>
              </DialogContent>
            )}
          </>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setOpenedDialog("");
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
  </>);
}

export default VacancyFewDetail;
