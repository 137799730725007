/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
// import { DataGrid } from '@mui/x-data-grid';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import parse from "html-react-parser";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { HttpService } from "Api-Services/httpService";
import React, { useEffect, useState } from "react";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Slide,
  TablePagination,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { Close, Delete, Details, DetailsRounded, FileCopy, Folder, MoreOutlined, Preview, Refresh, Security } from "@mui/icons-material";
import MDButton from "components/MDButton";
import JobHiringRequestDetail from "./components/JobHiringRequestDetail";
import { isMobile } from "react-device-detect";
import JobSeekerFewDetail from "./components/JobSeekerDetail/jobSeekerFewDetail";
import DashboardNavbar2 from "examples/Navbars/DashboardNavbar2";
import { subCities } from "Api-master-datas/masterDatas";
import { specificAreas } from "Api-master-datas/masterDatas";
import { cities } from "Api-master-datas/masterDatas";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Data

 
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    // backgroundColor: '#44b700',
    // color: '#44b700',
  //   boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  //   '&::after': {
  //     // position: 'absolute',
  //     top: 0,
  //     left: 0,
  //     width: '100%',
  //     height: '100%',
  //     // borderRadius: '50%',
  //     // animation: 'ripple 1.2s infinite ease-in-out',
  //     // border: '1px solid currentColor',
  //     content: '""',
  //   },
  },
  // '@keyframes ripple': {
  //   '0%': {
  //     transform: 'scale(.8)',
  //     opacity: 1,
  //   },
  //   '100%': {
  //     transform: 'scale(2.4)',
  //     opacity: 0,
  //   },
  // },
}));

function JobSeekers() {
  const [currentContextData, updateContext] = useCurrentUser();
  const {
    employeeHiringRequests,
    employmentAgencyJobSeekers,
    websiteUserTypes,
  } = currentContextData;

  // const [currentUser ,updateCurrentUser ] = useCurrentUser();
  const [forceRerender, setForceRerender] = useState(true);

  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const [openedDialog, setOpenedDialog] = useState("");
  const [
    employmentAgencyJobSeekerDetailsData,
    setEmploymentAgencyJobSeekerDetailsData,
  ] = useState(null);
  const [allPlatforms, setAllPlatforms] = useState(null);
  const [dataTableData, setDataTableData] = useState({ columns: [], rows: [] });

  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // / Setting the dir attribute for the body element
  // useEffect(async() => {
  //   if(employeeHiringRequests){
  //     if(employeeHiringRequests.length){
  //       let isAuthD= await getStarted(employeeHiringRequests)
  //     }
  //   }
  // }, [employeeHiringRequests,employmentAgencyJobSeekers]);

  useEffect(async () => {
    if (employmentAgencyJobSeekers) {
      if (employmentAgencyJobSeekers.length) {
        let isAuthD = await getStarted(employmentAgencyJobSeekers);
      }
    }
  }, [employmentAgencyJobSeekers]);

  const constructCurrentViewData = (newPage) => {
    // if(page)
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getAgencyJobSeekerById = (id) => {
    let res = null;
    try {
      if (employmentAgencyJobSeekers) {
        if (employmentAgencyJobSeekers.length) {
          let jobSeekerD = employmentAgencyJobSeekers.filter(
            (dataD) => dataD.jobSeekerId == id
          );
          // console.log("jobSeekerD",jobSeekerD)
          if (jobSeekerD) {
            if (jobSeekerD.length) {
              let jobSeekerDataD = jobSeekerD[0];
              if (jobSeekerDataD) {
                res = jobSeekerDataD;
              }
            }
          }
        }
      }
    } catch (error) {}
    return res;
  };

  // const getStarted2 = async (employmentAgencyJobSeekersD) => {
  //   let dataTableDataD = {
  //     columns: [
  //       { Header: "Photo", accessor: "profileImageUrl", width: "7%" },
  //       { Header: "Full Name", accessor: "name" },
  //       { Header: "Status", accessor: "isActive" }, 
  //       { Header: "Joined date", accessor: "createdAt" }, 
  //       { Header: "action", accessor: "action" },
  //     ],
  //     rows: [],
  //   };

  //   employmentAgencyJobSeekersD.forEach((element) => {
  //     let row = element.jobSeeker;
  //     let employmentAgencyJobSeeker = JSON.stringify(element);
  //     employmentAgencyJobSeeker = JSON.parse(employmentAgencyJobSeeker);
  //     if (row) {
  //       row.name = row.firstName+" "+row.lastName+" "+row.middleName
  //       row.isActive = element.isActive?"Active":"InActive"
  //       row.createdAt = parseDate(row.createdAt);
  //       // row.createdAt=row.firstName

  //       row.profileImageUrl = <Avatar src={row.profileImageUrl}>more</Avatar>;

  //       row.action = (
  //         <Button
  //           onClick={() => {
  //             console.log("row", employmentAgencyJobSeeker);
  //             setEmploymentAgencyJobSeekerDetailsData(
  //               employmentAgencyJobSeeker
  //             );
  //             setOpenedDialog("JobSeekerDetails");
  //           }}
  //         >
  //           more
  //         </Button>
  //       );
  //       dataTableDataD.rows.push(row);
  //     }
  //   });

  //   setDataTableData(dataTableDataD);
  //   setAllPlatforms(employmentAgencyJobSeekersD);
  // };


  const getStarted  = async (employmentAgencyJobSeekersD) => {  

    let citiesD=[]
    let subCitiesD=[]
    let specificAreasD=[]
    if(cities){
      if(cities.length){
        cities.forEach(element => {
          citiesD.push(element.TOWN_NAME)
        });
      }
    }

    if(subCities){
      if(subCities.length){
        subCities.forEach(element => {
          subCitiesD.push(element.title)
        });
      }
    }
    
    if(specificAreas){
      if(specificAreas.length){
        specificAreas.forEach(element => {
          specificAreasD.push(element.title)
        });
      }
    }


    // let actionBtn=  { headerName: "", field: "action" , width :20}
    let RollNo= { headerName: "#", field: "rollNumber" ,width: 5,}
let columnsD= [  
  {
    field: 'action',
    type: 'actions',
    width: 10,
    getActions: (params) => [
      // <GridActionsCellItem
      //   icon={<Details />}
      //   label="Details"
      //   // onClick={deleteUser(params.id)}
      // />,
      <GridActionsCellItem
        icon={<Preview />}
        label="Show More"
        // onClick={toggleAdmin(params.id)}
        onClick={() => { 
          // console.log("row params", params);
          let employmentAgencyJobSeekerDD = params.row.employmentAgencyJobSeeker||null
          if(employmentAgencyJobSeekerDD){
          // console.log("row", employmentAgencyJobSeekerDD);
          setEmploymentAgencyJobSeekerDetailsData(
            employmentAgencyJobSeekerDD
          );
          setOpenedDialog("JobSeekerDetails");}
        }}
        showInMenu
      />,
      // <GridActionsCellItem
      //   icon={<FileCopy />}
      //   label="Duplicate User"
      //   // onClick={duplicateUser(params.id)}
      //   showInMenu
      // />,
    ],
  },
    // { headerName: "Photo", field: "profileImageUrl", width:  70  },
    {
      field: 'profileImageUrl',
      type: 'actions',
      width: 30,
      getActions: (params) => [
        <> 
          <StyledBadge
          color={(params.row.isAvailable&&params.row.isActive)?"success":"primary"}
          // overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          variant="dot"
        >
          <Avatar alt="Remy Sharp"  src={params.row.profileImageUrl||""} />
        </StyledBadge>
         </>
        // <GridActionsCellItem
        //   icon={<Details />}
        //   label="Details"
        //   onClick={()=>console.log(params.row.profileImageUrl)}
        // />,
        // <GridActionsCellItem
        //   icon={<Security />}
        //   label="Toggle Admin"
        //   // onClick={toggleAdmin(params.id)}
        //   showInMenu
        // />,
        // <GridActionsCellItem
        //   icon={<FileCopy />}
        //   label="Duplicate User"
        //   // onClick={duplicateUser(params.id)}
        //   showInMenu
        // />,
      ],
    },
    { headerName: "Full Name", field: "name" , width: 200,},
    { headerName: "Is Active", field: "isActive" ,type:"boolean"}, 
    { headerName: "availablity", field: "isAvailable",type:"boolean" }, 
    { headerName: "City", field: "city",type: 'singleSelect' ,valueOptions:citiesD},
    { headerName: "Sub City", field: "subCity" ,type: 'singleSelect' ,valueOptions:subCitiesD},
    { headerName: "Specific Area", field: "specificArea" ,type: 'singleSelect' ,valueOptions:specificAreasD},
    { headerName: "Joined date", field: "createdAt" ,type:'dateTime',width: 180,},

    { headerName: "Phone", field: "phone" , width: 180,},

    // type: 'singleSelect',
    // valueOptions: ['United Kingdom', 'Spain', 'Brazil']


    // { Header: "action", accessor: "action" },
  ]

  if(isMobile){
  // columnsD.splice(0, 0, actionBtn);
  }
  else{
     columnsD.splice(0, 0, RollNo);
    //  columnsD.push(actionBtn)
  }

 let dataTableDataD={ 
  columns: columnsD,
  rows:[]
};


let rollNumbers=0


employmentAgencyJobSeekersD.forEach((element) => {
  if (element) {
    if (element.jobSeeker) {
  let row = element.jobSeeker;
  let employmentAgencyJobSeeker = JSON.stringify(element);
  employmentAgencyJobSeeker = JSON.parse(employmentAgencyJobSeeker);
  if (row) {
    row.employmentAgencyJobSeeker=employmentAgencyJobSeeker
    row.name = row.firstName+" "+row.lastName+" "+row.middleName
    row.isActive = element.isActive//?"Active":"InActive"
    row.createdAt =  row.createdAt?(new Date(row.createdAt)):null ;
    // row.createdAt=row.firstName

    // row.profileImageUrl = <Avatar src={row.profileImageUrl}>more</Avatar>;
    // row.profileImageUrl = <span >more</span>;
 
    rollNumbers=rollNumbers+1      
    row.rollNumber=rollNumbers

    row.action=isMobile?<>{rollNumbers+"  "}<MoreOutlined color="success" fontSize="large" 
    onClick={() => {
      // console.log("row", employmentAgencyJobSeeker);
      setEmploymentAgencyJobSeekerDetailsData(
        employmentAgencyJobSeeker
      );
      setOpenedDialog("JobSeekerDetails");
    }}>more</MoreOutlined></>:<Button 
  onClick={() => {
    // console.log("row", employmentAgencyJobSeeker);
    setEmploymentAgencyJobSeekerDetailsData(
      employmentAgencyJobSeeker
    );
    setOpenedDialog("JobSeekerDetails");
  }}>more</Button>

    dataTableDataD.rows.push(row);
  }}}
});


// employmentAgencyJobSeekersD.forEach(element => {
//   let row=element
//   let agencyJobSeeker=getAgencyJobSeekerById(row.jobSeekerId)
//   let jobSeeker
//   if(agencyJobSeeker.jobSeeker){
//     jobSeeker=agencyJobSeeker.jobSeeker
//     row.jobSeeker=jobSeeker
//     row.agencyJobSeeker=agencyJobSeeker
//     rollNumbers=rollNumbers+1      
//     row.rollNumber=rollNumbers
//     row.createdAt=parseDate(row.createdAt)
//     row.jobSeekerFullName= jobSeeker.firstName+"  "+jobSeeker.lastName 
     

//  dataTableDataD.rows.push(row)
// }
// });

setDataTableData(dataTableDataD);
setAllPlatforms(employmentAgencyJobSeekersD); 
  } 



  const getStarted3 = async (employeeHiringRequestsD) => {
    let actionBtn = { Header: "", accessor: "action", width: "2%" };
    let RollNo = { Header: "#", accessor: "rollNumber", width: "2%" };
    let columnsD = [
      { Header: "Requester", accessor: "requesterFullName" },
      { Header: "job Seeker", accessor: "jobSeekerFullName" },
      { Header: "Requester Phone", accessor: "requesterPhone" },
      { Header: "requester Working City", accessor: "requesterWorkingCity" },
      {
        Header: "requester Working Specific Area",
        accessor: "requesterWorkingSpecificArea",
      },
      { Header: "date", accessor: "createdAt" },
    ];

    if (isMobile) {
      // columnsD.splice(0, 0, actionBtn);
    } else {
      columnsD.splice(0, 0, RollNo);
      // columnsD.push(actionBtn);
    }

    let dataTableDataD = {
      columns: columnsD,
      rows: [],
    };

    let rollNumbers = 0;
    employeeHiringRequestsD.forEach((element) => {
      let row = element;
      let agencyJobSeeker = getAgencyJobSeekerById(row.jobSeekerId);
      let jobSeeker;
      if (agencyJobSeeker.jobSeeker) {
        jobSeeker = agencyJobSeeker.jobSeeker;
        row.jobSeeker = jobSeeker;
        row.agencyJobSeeker = agencyJobSeeker;
        rollNumbers = rollNumbers + 1;
        // console.log(" data:JobSeeker ",element, JobSeeker   );
        row.rollNumber = rollNumbers;
        row.createdAt = parseDate(row.createdAt);
        row.jobSeekerFullName = jobSeeker.firstName + "  " + jobSeeker.lastName;

        // row.profileImageUrl=<Avatar src={row.profileImageUrl}>more</Avatar>

        row.action = isMobile ? (
          <>
            {rollNumbers + "  "}
            <MoreOutlined
              color="success"
              fontSize="large"
              onClick={() => {
                setEmploymentAgencyJobSeekerDetailsData(row);
                setOpenedDialog("requestDetails");
              }}
            >
              more
            </MoreOutlined>
          </>
        ) : (
          <Button
            onClick={() => {
              setEmploymentAgencyJobSeekerDetailsData(row);
              setOpenedDialog("requestDetails");
            }}
          >
            more
          </Button>
        );

        dataTableDataD.rows.push(row);
      }
    });

    setDataTableData(dataTableDataD);
    setAllPlatforms(employeeHiringRequestsD);
  };

  const getDocumentsAll = async (
    documentName,
    relativePath,
    additionalData
  ) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    ////////// UPDATED/////////////
    documentsResponse = await HttpService.getService(
      relativePath,
      additionalData ? additionalData : {},
      ""
    );
    // console.log(documentName, " data: ", documentsResponse);

    if (documentsResponse.success) {
      responseD.success = true;
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
      let record = responseD.record;
    }
    setForceRerender(!forceRerender);
    return responseD;
  };

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    setLoading(true);

    documentsResponse = await HttpService.postService(
      relativePath,
      data,
      {},
      ""
    );
    setLoading(false);

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    } else {
      responseD.message = documentsResponse.message;

      if (documentName == "login") {
        // setLoginResponse(responseD)
      }
    }

    // console.log(documentName, " responseD: ", responseD   );
    setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  //  const initializePlatform = async (data)=>{
  //   let dataD={copyPlatformId:data.copyPlatformId,pastePlatformId:data.pastePlatformId}

  //   setLoading(true);
  //   let relativePath="/api/platformUser/initializenewplatform";
  //   await postDocuments("initializenewplatform",relativePath,dataD)
  //   setLoading(false);
  //  }

  const parseDate = (oldD) => {
    let d = new Date(oldD);
    let fDate = d.getMonth() + 1 + "/" + d.getDate() + "/" + d.getFullYear();
    return fDate;
  };

  return (
    <>
      {forceRerender}
      <DashboardLayout>
        <DashboardNavbar2 />
        <MDBox pt={6} pb={3} >
          <Card>
            {/* <MDBox p={3} lineHeight={1}> */}
            <MDBox
            pt={2}
            pb={2}
            px={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
              <MDTypography variant="h5" fontWeight="medium">
                Job Seekers
              </MDTypography>
              
            <MDButton
                variant="outlined"
                color="dark"
                size={"small"}
                // component={Link}
                onClick={()=>{
                  updateContext("employmentAgencyJobSeekers"); 
                }}
              >
                <Refresh sx={{ fontWeight: "bold" }}></Refresh>
                &nbsp;Refresh
              </MDButton>
              {/* <MDTypography variant="button" color="text">
              A lightweight, extendable, dependency-free javascript HTML table plugin.
            </MDTypography> */}
            </MDBox>
              <>
                {/* <h1>all platforms: {allPlatforms.length}</h1> */}
                {/* <DataTable table={dataTableData} canSearch /> */}



<Box sx={{ flexGrow: 1, maxWidth: "100%" }}> 
      <Grid container spacing={2}> 
        <Grid item xs={12} md={12}> 
          

            {(dataTableData&&allPlatforms) ? (
          <div style={{   width: '100%' }}>
      <DataGrid
        columns={dataTableData.columns}
        rows={dataTableData.rows}
        slots={{
          toolbar: GridToolbar,
        }}
        
        slotProps={{ toolbar: { showQuickFilter: true } }}
        initialState={{
          // ...data.initialState,
          pagination: { paginationModel: { pageSize: 10 } }, 
           filter: {
            filterModel: {
              items: [],
              // quickFilterValues: ['ab'],
              // quickFilterValues: ['quick', 'filter'],
              quickFilterExcludeHiddenColumns: true,
            },
          },
        }}
        pageSizeOptions={[5, 10, 25,50,100]}
        // pageSizeOptions={[5, 10, 25]}
      />
    </div>
            ):
            <>
            <Box sx={{  width: '100%'  ,padding:3}}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Box>
            
            </>}


          {/* </Demo> */}
        </Grid>
      </Grid>
    </Box> 


              </>
          </Card>
        </MDBox>
        
        <Footer />
      </DashboardLayout>

      <Dialog
        fullScreen
        open={openedDialog == "JobSeekerDetails"}
        onClose={() => {
          setOpenedDialog("");
        }}
        TransitionComponent={Transition}
      >
        <AppBar style={{ position: "fixed" }} sx={{ position: "relative" }}>
          <Toolbar>
            {/* <IconButton
              edge="start"
              color="inherit"
              label="Back"
              onClick={()=>{setOpenedDialog("")}}
              aria-label="close"
            >
              <Close />
            </IconButton> */}
            <MDButton
              variant="contained"
              color="warning"
              onClick={() => {
                setOpenedDialog("");
              }}
            >
              Back
            </MDButton>
            <MDTypography ml={3}>Job seeker few details</MDTypography>
          </Toolbar>
        </AppBar>
        <JobSeekerFewDetail
          employmentAgencyJobSeekerDetailsData={
            employmentAgencyJobSeekerDetailsData
          }
        />
        {/* /////// */}
      </Dialog>
    </>
  );
}

export default JobSeekers;
