/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// Material Kit 2 PRO React components
// import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import queryString from "query-string";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// Images
import {
  cities,
  subCities,
  specificAreas,
} from "Api-master-datas/masterDatas.js";

import profilePicture from "assets/images/bruce-mars.jpg";
import { Link, useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import Card from "@mui/material/Card";
import React, { useEffect } from "react";
import {
  ArrowDropDown,
  ArrowDropDownSharp,
  Edit,
  Favorite,
  PersonPin,
  Phone,
  Settings,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import MDInput from "components/MDInput";
import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FilledInput,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Input,
  InputAdornment, 
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Tabs,
  TextField,
  styled,
} from "@mui/material";
import { HttpService } from "Api-Services/httpService"; 
import axios from "axios";
import { CommonServices } from "Api-Services/commonServices";
import { isMobile } from "react-device-detect";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FileUploadComp from "components/common-components/fileUploadComp";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import DashboardNavbar2 from "examples/Navbars/DashboardNavbar2";
const ratings = {
  0.5: [
    <Icon key={1}>star_outline</Icon>,
    <Icon key={2}>star_outline</Icon>,
    <Icon key={3}>star_outline</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  1: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star_outline</Icon>,
    <Icon key={3}>star_outline</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  1.5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star_half</Icon>,
    <Icon key={3}>star_outline</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  2: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star_outline</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  2.5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star_half</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  3: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star_outline</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  3.5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star_half</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  4: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star</Icon>,
    <Icon key={5}>star_outline</Icon>,
  ],
  4.5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star</Icon>,
    <Icon key={5}>star_half</Icon>,
  ],
  5: [
    <Icon key={1}>star</Icon>,
    <Icon key={2}>star</Icon>,
    <Icon key={3}>star</Icon>,
    <Icon key={4}>star</Icon>,
    <Icon key={5}>star</Icon>,
  ],
};

function AgencySettings() {
  const [currentData, updateContext] = useCurrentUser();
  const {myBusinessEmploymentAgency,currentUser ,educationLevels,jobs,jobCategories}  = currentData;  
 
  const [agencymyBusinessEmploymentAgency, setAgencymyBusinessEmploymentAgency] = React.useState(null); //setmyBusinessEmploymentAgency
  const [categoryJobs, setCategoryJobs] = React.useState([]); //setmyBusinessEmploymentAgency

  const [expanded, setExpanded] = React.useState(false);
  const [expanded2, setExpanded2] = React.useState(false);
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [loadingBuildmyBusinessEmploymentAgency, setLoadingBuildmyBusinessEmploymentAgency] =
    React.useState(false);
  const [userInformation, setUserInformation] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [profileSectionIndex, setProfileSectionIndex] = React.useState(0);
  const [forceRerender, setForceRerender] = React.useState(false);
  const [dropzoneFiles, setDropzoneFiles] = React.useState([]);
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });
  const [newWorkExperianceData, setNewWorkExperianceData] = React.useState({
    description: "",
    expectingSalaryPerHour: null,
    expectingSalaryPerMonth: null,
    isAvailable: true,
    jobId: null,
    workExperianceInMonth: null,
    skillsOrSubjects: [],
  });
  // user
  // {id: DataTypes.DOUBLE,
  // userFrontImage: DataTypes.TEXT,
  // userEmail: DataTypes.TEXT,
  // googleEmail: DataTypes.TEXT,
  // facebookId: DataTypes.TEXT,
  // userStatus: DataTypes.DOUBLE,

  // currentEducationLevel:DataTypes.DOUBLE ,
  // informationApproved:DataTypes.BOOLEAN ,
  // informationApprovedDate:DataTypes.TEXT ,
  // TotoringEducationLevelStart:DataTypes.DOUBLE ,
  // TotoringEducationLevelEnd:DataTypes.DOUBLE ,

  // isInstructor :DataTypes.BOOLEAN,
  // status :DataTypes.TEXT,
  // registeredDate :DataTypes.TEXT,
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  }));
  // useEffect(() => {
  //   console.log("cities id", cities);
  //   console.log("location id", location);
  //   if (location.search) {
  //     let locationSearch = queryString.parse(location.search);
  //     if (locationSearch.id) {
  //       getStarted(locationSearch.id);
  //     }
  //   }
  // }, [location.search]);

  //   useEffect(() => {
  //     // console.log("currentUser", currentUser);
  //     // console.log(" myBusinessEmploymentAgency", myBusinessEmploymentAgency);
  // setForceRerender()
  //   }, [currentUser, educationLevels, agencyData, myAgencies, jobs]);
 

  useEffect(() => {
    // tab=2
    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch.tab) {
        setProfileSectionIndex(locationSearch.tab);
      }
    }
  }, [location.search]);

  useEffect(() => {
    filterCategoryJobs()
  }, [jobs,myBusinessEmploymentAgency]);

  const filterCategoryJobs=(catId)=>{
    setCategoryJobs(null);
    try {
    let id=catId||myBusinessEmploymentAgency.jobsCategoryId
    if (jobs&&myBusinessEmploymentAgency) {
      if (jobs.length) {
        if (jobs.length>0) { 
          let filteredD = jobs.filter((level) => level.jobCategoryId == id);
          if (filteredD.length) {
            setCategoryJobs(filteredD);
          }
        }
      }
    }
      
    } catch (error) {
      
    }
    setForceRerender(!forceRerender)
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangeExpanded2 = (panel) => (event, isExpanded) => {
    setExpanded2(isExpanded ? panel : false);
    if (!isExpanded) {
      setExpanded(false);
    }
  };

  const navigate = useNavigate();
  const goToPage = () => {
    navigate("/pages/SendTutorRequest");
  };
 

  const postDocuments = async (
    documentName,
    relativePath,
    data,
    additionalData
  ) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    // setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(
      relativePath,
      data,
      additionalData || {},
      ""
    );
    // console.log(documentName, " data: ", documentsResponse   );
    // setLoading(false);

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
      let record = responseD.record;

      //write codtions down here//
    } else {
      responseD.record = documentsResponse.data;
    }

    // console.log(documentName, " responseD: ", responseD);
    // setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  const getDocumentsAll = async (
    documentName,
    relativePath,
    additionalData
  ) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    ////////// UPDATED/////////////
    documentsResponse = await HttpService.getService(
      relativePath,
      additionalData ? additionalData : {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }

    if (documentsResponse.success) {
      responseD.success = true;
    }
    setForceRerender(!forceRerender);
    return responseD;
  };

  const getEducationLevelById = (id, rows, field) => {
    let res = {};
    if (educationLevels && id) {
      let filteredLevels = educationLevels.filter((level) => level.id == id);
      if (filteredLevels.length) {
        res = filteredLevels;
        if (rows == "single") {
          let row = filteredLevels[0];
          res = row;
          if (field) {
            let rowField = row[field];
            res = rowField;
          }
        }
      }
      // console.log("filtered educat error", res);
    }
    return res;
  };

  const getCityById = (city, rows, field) => {
    let res = {};
    try {
      if (cities && city) {
        let filteredLevels = cities.filter((level) => level.TOWN_NAME == city);
        if (filteredLevels.length) {
          res = filteredLevels;
          if (rows == "single") {
            let row = filteredLevels[0];
            res = row;
            if (field) {
              let rowField = row[field];
              res = rowField;
            }
          }
        }
      }
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
    return res;
  };

  const getDataFromArray = (
    dataArray,
    searchField,
    searchValue,
    returnRows,
    returnField
  ) => {
    let res = returnField ? "" : {};

    try {
      if (searchValue) {
        if (dataArray && searchField && searchValue) {
          if (dataArray.length > 0) {
            let filteredLevels = dataArray.filter(
              (level) => level[searchField] == searchValue
            );
            if (filteredLevels.length) {
              res = filteredLevels;
              if (returnRows == "single") {
                let row = filteredLevels[0];
                res = row;
                if (returnField) {
                  let rowField = row[returnField];
                  res = rowField;
                }
              }
            }
          }
        }
      }
      // console.log("filtered city  ", res);
    } catch (error) {
      console.log("filtered city error", error);
    }
    console.log("filtered city  ", res);
    return res;
  };

  const filterJobsFromString = (jobsString, field) => {
    let res = [];
    if (jobs && jobsString) {
      let jobsArray = jobsString.split(",");
      if (jobsArray) {
        let filteredLevels = jobs.filter((job) => {
          let ress = false;
          jobsArray.forEach((element) => {
            let id = parseInt(element);
            if (job.id == id) {
              ress = true;
            }
          });

          return ress;
        });
        res = filteredLevels;
      }
      // console.log("filtered jobs", res);
    }
    return res;
  };

  const startBuildingmyBusinessEmploymentAgency = async () => {
    // console.log("data", dataD);

    try {
      if (user) {
        let data = {};
        let relativePath = "/api/employmentmyBusinessEmploymentAgency/initializemyBusinessEmploymentAgency";
        let refreshVariable = "myBusinessEmploymentAgency";

        setLoadingBuildmyBusinessEmploymentAgency(true);
        let changeUserProfileRes = await postDocuments(
          "changeUserInfo",
          relativePath,
          data
        );
        //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});
        setLoadingBuildmyBusinessEmploymentAgency(false);
        if (changeUserProfileRes.success) {
          setExpanded(false);
          updateContext(refreshVariable);
        }
      }
    } catch (error) {}
  };

  const handleNewWorkExperianceData = async (field, value) => {
    try {
      let data = newWorkExperianceData;
      if (field && value) {
        data[field] = value;
        setNewWorkExperianceData(data);
        setForceRerender(!forceRerender);
      }
    } catch (error) {}
  };

  const removeAgencyIJoined = async (agencyId) => {
    // return
    try {
      let ressss = await CommonServices.confirmPopUp(
        "Remove",
        "Are you sure to do this."
      );
      console.log("ressss", ressss);
      if (!ressss) {
        return;
      }
      if (agencyId) {
        let relativePath = "/api/employmentmyBusinessEmploymentAgency/removeagencyijoined";
        let refreshVariable = "myAgencies";

        // setLoadingSave(true);
        // console.log("data", dataD,parentData, dataParentName,fieldType);
        let changeUserProfileRes = await postDocuments(
          "changeUserInfo",
          relativePath,
          { employmentAgencyId: agencyId }
        );
        //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});
        // setLoadingSave(false);

        if (changeUserProfileRes.success) {
          setExpanded(false);
          updateContext(refreshVariable);
        }
      }
    } catch (error) {}
  };

  const deleteWorkExperiance = async (id) => {
    // return
    try {
      let ressss = await CommonServices.confirmPopUp(
        "Delete",
        "Are you sure to do this."
      );
      console.log("ressss", ressss);
      if (!ressss) {
        return;
      }
      if (id) {
        let relativePath =
          "/api/employmentmyBusinessEmploymentAgencyworkexperiance/delete/" + id;
        let refreshVariable = "myBusinessEmploymentAgency";

        // setLoadingSave(true);
        // console.log("data", dataD,parentData, dataParentName,fieldType);
        let changeUserProfileRes = await getDocumentsAll(
          "changeUserInfo",
          relativePath,
          {}
        );
        //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});
        // setLoadingSave(false);

        if (changeUserProfileRes.success) {
          setExpanded(false);
          // getStarted(agencymyBusinessEmploymentAgency.id);
        }
      }
    } catch (error) {}
  };

  const addNewWorkExperiance = async () => {
    // console.log("data", dataD,parentData, dataParentName,fieldType);
    // return
    try {
      let data = newWorkExperianceData;
      let relativePath = "/api/employmentmyBusinessEmploymentAgencyworkexperiance/new";
      let refreshVariable = "myBusinessEmploymentAgency";
      if (data) {
        data.myBusinessEmploymentAgencyId = myBusinessEmploymentAgency.id;

        if (data.skillsOrSubjects) {
          let skils = "";
          if (data.skillsOrSubjects.length) {
            data.skillsOrSubjects.forEach((element) => {
              skils = skils + (skils ? "," : "") + element;
            });
          }
          data.skillsOrSubjects = skils;
        }
      }

      if (data.myBusinessEmploymentAgencyId) {
        if (data) {
          // setLoadingSave(true);
          let changeUserProfileRes = await postDocuments(
            "changeUserInfo",
            relativePath,
            data
          );
          //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});
          // setLoadingSave(false);

          if (changeUserProfileRes.success) {
            setExpanded(false);
            setOpenedDialog("");
            updateContext(refreshVariable);
          }
        }
      }
    } catch (error) {}
  };

  const updateUserInformation = async (
    dataD,
    parentData,
    dataParentName,
    fieldType
  ) => {
    console.log("data", dataD, parentData, dataParentName, fieldType);
    // return
    try {
      let id;
      let relativePath;
      let refreshVariable = "";
      let value;
      if (dataD) {
        if (dataD.value) {
          value = dataD.value;
        }
      }

      // if (dataParentName == "agencymyBusinessEmploymentAgency") {
      //   id = myBusinessEmploymentAgency.id;
      //   relativePath = "/api/employmentagencymyBusinessEmploymentAgency/edit";
      //   refreshVariable="myBusinessEmploymentAgency"
      // }
      if (dataParentName == "employmentagency") {
        id = myBusinessEmploymentAgency.id;
        relativePath="/api/employmentagency/edit"
        refreshVariable = "myBusinessEmploymentAgency";
      }
      if (dataParentName == "agencymyBusinessEmploymentAgency") {
        id = agencymyBusinessEmploymentAgency.id;
        relativePath = "/api/employmentagencymyBusinessEmploymentAgency/edit";
        refreshVariable = "agencymyBusinessEmploymentAgency";
      }
      if (dataParentName == "myBusinessEmploymentAgency") {
        id = myBusinessEmploymentAgency.id;
        relativePath = "/api/employmentmyBusinessEmploymentAgency/edit";
        refreshVariable = "myBusinessEmploymentAgency";
      }
      if (dataParentName == "workExperiance") {
        id = parentData.id;
        relativePath = "/api/employmentmyBusinessEmploymentAgencyworkexperiance/edit";
        refreshVariable = "myBusinessEmploymentAgency";
      } else if (dataParentName == "userInformation") {
        id = userInformation.id;
        relativePath = "/api/employee/edit";
        refreshVariable = "currentUser";
      } else if (dataParentName == "user") {
        id = user.id;
        relativePath = "/api/auth/edit";
        refreshVariable = "currentUser";
      }

      if (relativePath && id) {
        let data = {
          id: id,
        };
        if ("fileUrl" == fieldType) {
          if (dataD.value) {
            let filesD = await uploadFiles(dataD.value);
            if (filesD) {
              if (filesD.length) {
                if (filesD.length > 0) {
                  value = filesD[0];
                }
              }
            }
          }
        }
        if ("imageUrl" == fieldType) {
          if (dataD.value) {
            let filesD = await uploadFiles(dataD.value);
            if (filesD) {
              if (filesD.length) {
                if (filesD.length > 0) {
                  value = filesD[0];
                }
              }
            }
          }
        }
        if (value) {
          data[dataD.fieldName] = value;
          // console.log("data", data);

          // let toastId = toast.loading("Updating Profile data...",{
          //   position: toast.POSITION.BOTTOM_LEFT
          // })
          setLoadingSave(true);
          let changeUserProfileRes = await postDocuments(
            "changeUserInfo",
            relativePath,
            data
          );
          //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});
          setLoadingSave(false);

          if (changeUserProfileRes.success) {
            setExpanded(false);
              updateContext(refreshVariable);
            // if (dataParentName == "agencymyBusinessEmploymentAgency") {
            // getStarted(agencymyBusinessEmploymentAgency.id);
            //   }else{
            //   updateContext(refreshVariable);
            // }
          }
        }
      }
    } catch (error) {}
  };

  const truncateString = (str, num, lableName) => {
    let res = str;
    try {
      if (str) {
        if (str.length > num) {
          let btn = (
            <Button
              variant="text"
              onClick={() => {
                setMoreText({ content: str, title: lableName });
                setOpenedDialog("moreText");
                setForceRerender(!forceRerender);
              }}
            >
              {"View more"}
            </Button>
          );
          let txt = str.slice(0, num) + "...";
          res = (
            <>
              {txt} {btn}
            </>
          );
        }
      }
    } catch (error) {
      // console.log("errrr",error)
    }
    return res;
  };

  const parseDate = (dt) => {
    let d = new Date(dt);
    let m = d.getMonth() + 1;
    let dateD = m + "/" + d.getDate() + "/" + d.getFullYear();
    return dateD;
  };

  const editInputComponent = (
    parentData,
    parentFieldName,
    fieldName,
    fieldLabel,
    valueD,
    fieldType,
    options,
    optionsSelectedField,
    optionsSelectedLable,
    optionsGroupedBy
  ) => {
    
    // const editInputComponent = (fieldName, fieldLabel, valueD, fieldType, options, selectedField) => {
    // console.log("fieldName",fieldName, valueD);
    let res = <></>;
    let inputComp = <></>;
    let value = valueD;
    try {
      if (fieldType == "textArea") {
        inputComp = (
          <>
            {" "}
            {/* <TextField
                id="standard-multiline-static"
                label={fieldLabel}
                defaultValue={value}
                onChange={(e) => {                 
                   console.log("aaa", e.target.value);

                  value = e.target.value;
                }} 
                variant="standard"
              /> */}
            <MDInput
              placeholder="Enter text"
              label={fieldLabel}
              multiline
              defaultValue={value}
              rows={4}
              onChange={(e) => {
                // console.log("aaa", e.target.value);
                value = e.target.value;
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </>
        );
      }
      if (
        fieldType == "textField" ||
        fieldType == "email" ||
        fieldType == "phone"
      ) {
        inputComp = (
          <>
            {" "}
            {value ? (
              <MDInput
                placeholder="Enter text"
                label={fieldLabel}
                defaultValue={value}
                onChange={(e) => {
                  // console.log("aaa", e.target.value);
                  value = e.target.value;
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            ) : (
              <MDInput
                placeholder="Enter text"
                label={fieldLabel}
                onChange={(e) => {
                  // console.log("aaa", e.target.value);
                  value = e.target.value;
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            )}
          </>
        );
      }
      if (fieldType == "number") {
        inputComp = (
          <>
            {" "}
            {value ? (
              <MDInput
                placeholder="Enter Number"
                label={fieldLabel}
                defaultValue={value}
                inputProps={{ type: "number" }}
                onChange={(e) => {
                  // console.log("aaa", e.target.value);
                  value = e.target.value;
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            ) : (
              <MDInput
                placeholder="Enter Number"
                inputProps={{ type: "number" }}
                label={fieldLabel}
                onChange={(e) => {
                  // console.log("aaa", e.target.value);
                  value = e.target.value;
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            )}
          </>
        );
      } else if (fieldType == "radio") {
        inputComp = (
          <>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                {fieldLabel}
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                defaultValue={value}
                onChange={(e) => {
                  // console.log("aaa", e.target.value);
                  value = e.target.value;
                }}
              >
                {options && (
                  <>
                    {options.length ? (
                      <>
                        {options.map((option) => (
                          <FormControlLabel
                            value={
                              optionsSelectedField
                                ? option[optionsSelectedField]
                                : option
                            }
                            control={<Radio />}
                            label={
                              optionsSelectedField
                                ? option[optionsSelectedField]
                                : option
                            }
                          />
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </RadioGroup>
            </FormControl>
          </>
        );
      } else if (fieldType == "fileUrl") {
        // let currentIcon={ c:input.value }
        inputComp = (
          <>
            <FormControl variant="outlined" fullWidth>
              {/* <span style={{ marginRight: 10,  fontSize: 18,}}> 
                <i className={"fab "+input.value } />
            </span>  */}
            </FormControl>
            {/* <GridItem xs={12} sm={12} md={12}> */}
            <Divider variant="middle" />
            <h4 divider> {fieldLabel} </h4>
            <div className="container">
              <div className="row">
                <div className="col s12">
                  <FileUploadComp
                    filesLimit={1}
                    initialFiles={[value]}
                    handleDropZoneFiles={(files) => {
                      value = files;
                    }}
                  />
                  {/* <h1>mmm</h1> */}
                  {/* <ServerPhotoGallery fileType="document"  filesLimit={1} initialFiles={[value]} 
                  uplodedImages={(data)=>{
                        if(data){
                          if(data.length){
                               value =data[0]
                            // onInputChange(data[0],input)
                          }
                        } 
                  }}  
                            /> */}
                </div>
              </div>
            </div>
            {/* </GridItem>  */}
          </>
        );
      } else if (fieldType == "imageUrl") {
        // let currentIcon={ c:input.value }
        inputComp = (
          <>
            <FormControl variant="outlined" fullWidth>
              {/* <span style={{ marginRight: 10,  fontSize: 18,}}> 
                <i className={"fab "+input.value } />
            </span>  */}
            </FormControl>
            {/* <GridItem xs={12} sm={12} md={12}> */}
            <Divider variant="middle" />
            <h4 divider> {fieldLabel} </h4>
            <div className="container">
              <div className="row">
                <div className="col s12">
                  <FileUploadComp
                    filesLimit={1}
                    acceptedFiles={["image/*"]}
                    initialFiles={[value]}
                    handleDropZoneFiles={(files) => {
                      value = files;
                    }}
                  />
                  {/* <h1>mmm</h1> */}
                  {/* <ServerPhotoGallery fileType="document"  filesLimit={1} initialFiles={[value]} 
                  uplodedImages={(data)=>{
                        if(data){
                          if(data.length){
                               value =data[0]
                            // onInputChange(data[0],input)
                          }
                        } 
                  }}  
                            /> */}
                </div>
              </div>
            </div>
            {/* </GridItem>  */}
          </>
        );
      } else if (fieldType == "boolean") {
        inputComp = (
          <>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                {fieldLabel}
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                defaultValue={value ? 1 : 0}
                onChange={(e) => {
                  console.log("aaa", e.target.value);
                  value = e.target.value;
                }}
              >
                <FormControlLabel value={1} control={<Radio />} label="Yes" />
                <FormControlLabel value={0} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </>
        );
      } else if (fieldType == "selectOption") {
        inputComp = (
          <>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                {fieldLabel}
              </FormLabel>
              {/* <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  defaultValue={value?true:false}
                  onChange={(e) => {
                    // console.log("aaa", e.target.value);
                    value = e.target.value;
                  }}
                > 
                            <FormControlLabel
                              value={ true }
                              control={<Radio />}
                              label= "true" 
                            /> 
                            <FormControlLabel
                              value={ false }
                              control={<Radio />}
                              label= "false" 
                            /> 
                </RadioGroup> */}
              {options && (
                <Autocomplete
                  fullWidth
                  id="optionsListComp"
                  sx={{ width: 400 }}
                  groupBy={(option) => optionsGroupedBy?option[optionsGroupedBy]:null} 
                  onChange={(e, val) => {
                    if (val) {
                      if (val) {
                        // console.log("val,id",val.id);
                        value = val;
                        // console.log("value",value);
                      }
                    }
                  }}
                  options={options} 
                  defaultValue={options?(optionsSelectedField? value :{}):{}}
                  getOptionLabel={(option) =>
                    optionsSelectedLable ? option[optionsSelectedLable] : option
                  }
                  renderInput={(params) => (
                    <MDInput {...params} variant="standard" id="optionsList"/>
                  )}
                />
              )}
            </FormControl>
          </>
        );
      } else if (fieldType == "chipsText") {
        let chipNewText = "";
        let chipsData = [];
        if (value) {
          if (value.length) {
            chipsData = value.split(",");
          }
        }

        inputComp = (
          <>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                {fieldLabel}
              </FormLabel>
              {/* <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
          <FilledInput
            id="filled-adornment-password"   
             onChange={(e,data)=>{
              chipNewText=e.target.value
             }}

            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>{
                    value=value+(value?", ":"")+chipNewText
                    chipsData=value.split(",")
                    console.log("chipNewText value",chipsData)
                    // setForceRerender(!forceRerender)
                  }}
                  // onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                 Add 
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl> */}
              {/* {forceRerender} */}
              {/* {chipsData&&<>
        {chipsData.length&&<>
       {chipsData.map((data)=><Chip label={data} onDelete={()=>{}} />) }
        </> }
        </> } */}

              <Autocomplete
                multiple
                id="tags-filled"
                options={[]}
                // options={top100Films.map((option) => option.title)}
                defaultValue={chipsData}
                // value={chipsData}
                freeSolo
                // onChange={(e,data)=>{
                //   // handleNewWorkExperianceData("skillsOrSubjects",data)
                //       let skills=""
                //         if(data.length){
                //           data.forEach(element => {
                //             skills=skills+(skills?",":"")+element
                //           });
                //         }
                //           value=skills
                //           chipsData=value.split(",")
                // }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => {
                  let newValue = "";
                  console.log("params", params);
                  return (
                    <>
                      {/* <TextField
            {...params}
            value={newValue}
            onChange={(e)=>{ newValue=e.target.value}}
            variant="filled"
            label="freeSolo"
            placeholder="Favorites"  
          /> */}
                      <Input
                        {...params}
                        value={newValue}
                        id="outlined-adornment-password"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              // onClick={handleClickShowPassword}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              <Visibility />
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                    </>
                  );
                }}
              />

              {/* <Autocomplete fullWidth
                  // sx={{ width: 400 }}
                  multiple
                  id="tags-filled"
                  options={[]}
                  // defaultValue={chipsData}
                  value={chipsData}
                      // defaultValue={newWorkExperianceData.skillsOrSubjects}
                  onChange={(e,data)=>{
                    // handleNewWorkExperianceData("skillsOrSubjects",data)
                        let skills=""
                          if(data.length){ 
                            data.forEach(element => {
                              skills=skills+(skills?",":"")+element
                            });
                          } 
                            value=skills 
                  }}
                  freeSolo 
                  renderTags={(value, getTagProps) =>  
                    value.map((option, index) => 
                      (
                      <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ) )  
                  }
                  renderInput={(params) => ( 
                    <TextField
                      {...params}
                      variant="filled"
                      label="Skills Or Subjects"
                      placeholder="Add Skill" 
                    />  
                  )}
                />   */}
              {/* {(options)&&<Autocomplete fullWidth 
                sx={{ width: 400 }}
                onChange={(e, val) => {
                    if(val){
                      if(val){
                      // console.log("val,id",val.id);
                       value=val;
                      // console.log("value",value);
                      }}
                }}
                options={options}
                // defaultValue={options?(optionsSelectedField?value[optionsSelectedLable]:value):""}
                getOptionLabel={(option) => optionsSelectedLable?option[optionsSelectedLable]:option}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
              />} */}
            </FormControl>
          </>
        );
      } 
      else if(fieldType=="chip"){ 
        inputComp = ( 
        <>
        
        <FormControl fullWidth >
          
        <FormLabel id="demo-controlled-radio-buttons-group">
                {fieldLabel}
              </FormLabel>  
              {options && <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={options}
                        // value={value}
                        getOptionLabel={(optionData) => optionsSelectedLable ? optionData[optionsSelectedLable] : optionData}
                        defaultValue={value}
                        onChange={(e,data)=>{
                            console.log("val,ee",e,data)
                          if(data){   
                            value =data
                          } 
                        }}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            variant="outlined"
                            label={"Add "+fieldLabel}
                            placeholder="Write Job Title"
                          />
                        )}
                      />}
      </FormControl>
      </>  
        );
      }

      let accordionTitle = "value not assigned";
      if (fieldType == "fileUrl") {
        accordionTitle = (
          <Button
            href={value}
            target="_blank"
            onClick={() => {
              // setDocumentUrl(value);
              // setOpenedDialog("documentviewer")
            }}
            color="primary"
            round
            size="sm"
          >
            open file
          </Button>
        );
      } else if (fieldType == "imageUrl") {
        accordionTitle = <Avatar src={value}></Avatar>;
      } else if ((fieldType == "chipsText")) {
        let chipsData = [];
        if (value) {
          if (value.length) {
            chipsData = value.split(",");
          }
        }
        accordionTitle = (
          <>
            {chipsData.map((chipValue) => (
              <Chip label={chipValue} variant="outlined" />
            ))}
          </>
        );
      } else if (fieldType == "chip") {
        accordionTitle = (
          <>
            {value.map((chipValue) => (
              <Chip
                label={
                  optionsSelectedLable
                    ? chipValue[optionsSelectedLable]
                    : chipValue
                }
                variant="outlined"
              />
            ))}
          </>
        );
      } else if (fieldType == "boolean") {
        accordionTitle = <>{value ? "Yes" : "No"}</>;
      }
      //  else if (fieldType == "selectOption") {

      //   accordionTitle = (
      //     <>
      //       {value?getEducationLevelById(value, "single","title"):"Not selected" }
      //     </>
      //   );
      // }
      else {
        if (value) {
          accordionTitle = optionsSelectedLable
            ? value[optionsSelectedLable]
              ? value[optionsSelectedLable]
              : ""
            : value;
          accordionTitle = truncateString(accordionTitle, 50);
        }
      }

      res = (
        <>
          <Accordion
            expanded={expanded === fieldName}
            onChange={
              (
                parentFieldName == "workExperiance" ||
                parentFieldName == "myBusinessEmploymentAgency"
                  ? !myBusinessEmploymentAgency.isUserManaged
                  : true
              )
                ? handleChange(fieldName)
                : null
            }
          >
            <AccordionSummary
              expandIcon={
                (
                  parentFieldName == "workExperiance" ||
                  parentFieldName == "myBusinessEmploymentAgency"
                    ? !myBusinessEmploymentAgency.isUserManaged
                    : true
                ) ? (
                  <Edit
                    color="success"
                    fontSize="large"
                    //  disabled={parentFieldName==myBusinessEmploymentAgency}
                    // onChange={handleChange(fieldName)}
                  />
                ) : null
              }
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <MDTypography
                sx={{ width: "43%", flexShrink: 0 }}
                variant="body2"
                color="text"
                fontWeight="regular"
                pl={1}
              >
                {fieldLabel}{" "}
              </MDTypography>
              <MDTypography
                sx={{ color: "text.secondary" }}
                variant="body2"
                color="text"
                fontWeight="regular"
                pl={1}
              >
                <strong>{accordionTitle}</strong>{" "}
              </MDTypography>
              {/* <Typography sx={{ width: "43%", flexShrink: 0 }}>{fieldLabel}</Typography> */}
              {/* <Typography sx={{ color: "text.secondary" }}><strong>{accordionTitle}</strong></Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              <Grid container alignItems="center">
                <Grid py={1} item xs={12} lg={6}>
                  {inputComp}
                </Grid>
                <Grid py={1} item xs={12} lg={6}>
                  <MDBox px={4}>
                    <Stack direction="row" spacing={2}>
                      <Box sx={{ m: 1, position: "relative" }}>
                        <Button
                          variant="contained"
                          color="success"
                          disabled={loadingSave}
                          onClick={() => {
                            let dataDD = {};
                            if (fieldType == "chip") {
                              let valuesD = "";
                              value.forEach((val) => {
                                valuesD =
                                  valuesD +
                                  (optionsSelectedField
                                    ? val[optionsSelectedField]
                                    : val) +
                                  ",";
                              });
                              dataDD = {
                                value: valuesD,
                                fieldName: fieldName,
                              };
                            } else {
                              dataDD = {
                                value: optionsSelectedField
                                  ? value[optionsSelectedField]
                                  : value,
                                fieldName: fieldName,
                              };
                              console.log("data DD", dataDD, value);
                            }
                            updateUserInformation(
                              dataDD,
                              parentData,
                              parentFieldName,
                              fieldType
                            );
                            // console.log("value", value);
                          }}
                        >
                          Save
                        </Button>

                        {loadingSave && (
                          <CircularProgress
                            size={24}
                            sx={{
                              // color: green[500],
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: "-12px",
                              marginLeft: "-12px",
                            }}
                          />
                        )}
                      </Box>

                      <Button
                        color="secondary"
                        onClick={() => {
                          setExpanded(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </MDBox>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </>
      );
    } catch (error) {
      // console.log("inp comp", error);
    }
    return res;
  };

  const uploadFiles = async (filesD, filePathD) => {
    console.log("uploadFiles", filesD, filePathD);
    let res = [];
    let dropzoneTempImages1 = filesD || dropzoneFiles;
    try {
      let filePath = "./public/files/users/user_25/new-folder3";
      const formData = new FormData();
      _.filter(dropzoneTempImages1, (file) => {
        // formData.append("folderPath", uploadingFolderPath);
        formData.append("folderPath", filePath);
        formData.append("file", file);
        return file;
      });

      let getHeaderAndURLRes = await HttpService.getHeaderAndURL();
      let baseUrl = getHeaderAndURLRes.baseUrl;
      let additionalData = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        // onUploadProgress: progressEvent => {
        //   if(progressEvent){
        //     // console.log("progressEvent", Math.round((progressEvent.loaded * 100) / progressEvent.total))
        //       setuploadPercentage(parseInt(
        //       Math.round((progressEvent.loaded * 100) / progressEvent.total)
        //         ))
        //         }
        // }
      };

      let uploadFileRes = await postDocuments(
        "uploadFile",
        "/api/file/photos",
        formData,
        additionalData
      );
      if (uploadFileRes) {
        if (uploadFileRes.record) {
          if (uploadFileRes.record.length) {
            uploadFileRes.record.forEach((imgFile) => {
              // var destination=imgFile.destination.replace('./public',"");
              var destination = imgFile.destination.replace(
                "./public",
                "/public"
              );
              var filename = "/" + imgFile.filename;
              var pathN = baseUrl + destination + filename;
              var imgpath = pathN;
              res.push(imgpath);
            });
          }
        }
      }
      console.log("uploadFileRes  ", uploadFileRes);
      console.log("uploadFileRes res", res);
      // await axios.post(baseUrl+"/api/file/photos", formData,{additionalData})
      // .then(function (response) {
      //   let imgs=[]
      //   // console.log("file  dropzoneval, uploaded",dropzoneTempImages1,response.data);
      //   response.data.forEach(imgFile => {
      //     var destination=imgFile.destination.replace('./public',"");
      //     var filename="/"+imgFile.filename
      //     var pathN=baseUrl+destination+filename;
      //     var imgpath=pathN
      //     imgs.push(imgpath)
      //   });
      //   // props.uplodedImages(imgs,props.uploadingImageType);
      //     // setuploadedFiles([...uploadedFiles, ...imageFiles])
      // })
      // .catch(function (error) {
      // });
    } catch (err) {
      console.log("uploadFiles err", err);
    }
    return res;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar2 />
      <MDBox component="section">
        {/* <Container> */}
        {/* <Grid xs={12} justifyContent="center" mx="auto">
          <Tabs
            value={profileSectionIndex}
            onChange={(e, val) => {
              console.log(e, val);
              setProfileSectionIndex(val);
            }}
            aria-label="icon label tabs example"
          >
            <Tab icon={<PersonPin />} label="Job Info" />
            <Tab icon={<PersonPin />} label="Personal Info" /> 
          </Tabs>
        </Grid> */}
 
          <Grid container item xs={12} justifyContent="center" mx="auto">
            {myBusinessEmploymentAgency && (
              <Grid container justifyContent="center" py={6}> 

                       
                        <Grid container justifyContent="center" py={3}>
                  <Grid item xs={12} md={4}>
                    <MDBox textAlign="left">
                      {/* <MDAvatar src={profilePicture} alt="Burce Mars" size="xxl" shadow="xl" /> */}
                      <MDBox
                        component="img"
                        // alt={name}
                        // width="50%"
                        borderRadius="md"
                        shadow="lg"
                      />
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }} 
                      >
                        <Avatar
                          alt="Travis Howard"
                          sx={{ width: 120, height: 120 }}
                          src={
                            myBusinessEmploymentAgency
                              ?  myBusinessEmploymentAgency.logo
                              : profilePicture
                          }
                        />
                      </Badge>
                    </MDBox>
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <MDBox
                      justifyContent="space-between"
                      alignItems="center"
                      mb={1}
                    >
                      {myBusinessEmploymentAgency.fullName && (
                        <MDTypography variant="h3">
                          {myBusinessEmploymentAgency.fullName||myBusinessEmploymentAgency.brandName||"My Agency"}
                        </MDTypography>
                      )}

                      {myBusinessEmploymentAgency.locationTown && (
                        <MDTypography variant="h6" mb={1}>
                          {myBusinessEmploymentAgency.locationCountry +
                            " , " +
                            myBusinessEmploymentAgency.locationTown}
                        </MDTypography>
                      )}

                      {/* <MDTypography
                    variant="h4"
                    // color={color === "transparent" || color === "light" ? "text" : "white"}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      ml: 0.375,

                      "& .material-icons-round": {
                        ml: -0.375,
                      },
                    }}
                  >
                    {ratings[5]}
                  </MDTypography> */}
                    </MDBox>
                    {/* <Grid container spacing={3} mb={3}>
                  <Grid item>
                    <MDTypography component="span" variant="body2" fontWeight="bold">
                      323&nbsp;
                    </MDTypography>
                    <MDTypography component="span" variant="body2" color="text">
                      Posts
                    </MDTypography>
                  </Grid>
                  <Grid item>
                    <MDTypography component="span" variant="body2" fontWeight="bold">
                      3.5k&nbsp;
                    </MDTypography>
                    <MDTypography component="span" variant="body2" color="text">
                      Followers
                    </MDTypography>
                  </Grid>
                  <Grid item>
                    <MDTypography component="span" variant="body2" fontWeight="bold">
                      260&nbsp;
                    </MDTypography>
                    <MDTypography component="span" variant="body2" color="text">
                      Following
                    </MDTypography>
                  </Grid>
                </Grid> */}
                    <MDTypography
                      variant="body1"
                      fontWeight="light"
                      color="text"
                    >
                      {truncateString(
                        myBusinessEmploymentAgency.description,
                        isMobile ? 100 : 150,
                        "Description"
                      )}
                      <MDTypography
                        component="a"
                        href="#"
                        variant="body1"
                        fontWeight="light"
                        color="info"
                        mt={3}
                        sx={{
                          width: "max-content",
                          display: "flex",
                          alignItems: "center",

                          "& .material-icons-round": {
                            transform: `translateX(3px)`,
                            transition:
                              "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                          },

                          "&:hover .material-icons-round, &:focus .material-icons-round":
                            {
                              transform: `translateX(6px)`,
                            },
                        }}
                      ></MDTypography>
                    </MDTypography>
                  </Grid>
                </Grid>

                  <Grid container item xs={12} py={3} justifyContent="center">
                    <Card sx={{ width: "100%" }}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} lg={12}>
                          <MDBox py={3} px={3}>
                            <MDTypography variant="h3" mb={1}>
                            Agency Business Informations
                            </MDTypography>
                          </MDBox>
                          <MDBox py={{ sm: 0, md: 3 }} px={{ sm: 0, md: 3 }}>
                            {/* educationalDocumentAttachementUrl */}
                         
                            {editInputComponent(myBusinessEmploymentAgency,"employmentagency",
                      "jobsCategoryId", 
                      "Jobs Catagory",
                      getDataFromArray(jobCategories,"id",myBusinessEmploymentAgency.jobsCategoryId,"single"), "selectOption",jobCategories,"id","title")}
                            {editInputComponent(myBusinessEmploymentAgency,"employmentagency",
                      "jobsId", 
                      "Jobs to work on",
                      filterJobsFromString(myBusinessEmploymentAgency.jobsId), "chip",categoryJobs,"id","title")}
                      {editInputComponent(myBusinessEmploymentAgency,"employmentagency",
                      "isAvailable", 
                      "Is Available?", myBusinessEmploymentAgency.isAvailable , "boolean")}
                      
                      {/* {editInputComponent(myBusinessEmploymentAgency,"employmentagency",
                      "isActive", 
                      "Is Active?", myBusinessEmploymentAgency.isActive , "boolean")} */}
                      {editInputComponent(myBusinessEmploymentAgency,"employmentagency",
                      "requireRegistrationFee", 
                      "Require Registration Fee?", myBusinessEmploymentAgency.requireRegistrationFee , "boolean")}
                     {(myBusinessEmploymentAgency.requireRegistrationFee==true)&& <>
                      {editInputComponent(myBusinessEmploymentAgency,"employmentagency",
                      "registrationFeeAmount", 
                      "Registration Fee Amount?", myBusinessEmploymentAgency.registrationFeeAmount , "number")}
                      {editInputComponent(myBusinessEmploymentAgency,"employmentagency",
                      "registrationFeeCurrency", 
                      "Registration Fee Currency?", myBusinessEmploymentAgency.registrationFeeCurrency , "textField")}
                      </>}

                      {editInputComponent(myBusinessEmploymentAgency,"employmentagency",
                      "isLicensedBusiness", 
                      "Is it Licensed Business?", myBusinessEmploymentAgency.isLicensedBusiness , "boolean")}
                      {editInputComponent(myBusinessEmploymentAgency,"employmentagency","businessLicenseCertificatePaper", "Business License Certificate Paper", myBusinessEmploymentAgency.businessLicenseCertificatePaper, "fileUrl")}


                          </MDBox>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid> 

                  <Grid container item xs={12} py={3} justifyContent="center">
                    <Card sx={{ width: "100%" }}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} lg={12}>
                          <MDBox py={3} px={3}>
                            <MDTypography variant="h3" mb={1}>
                             General Informations
                            </MDTypography>
                          </MDBox>
                          <MDBox py={{ sm: 0, md: 3 }} px={{ sm: 0, md: 3 }}>
                            {/* educationalDocumentAttachementUrl */}
                            {editInputComponent(myBusinessEmploymentAgency,"employmentagency","brandName","Website Brand Name", myBusinessEmploymentAgency.brandName, "textField")}
                      
                            {editInputComponent(myBusinessEmploymentAgency,"employmentagency",
                        "fullName",
                        "Full Business Name",
                        myBusinessEmploymentAgency.fullName,
                        "textField"
                      )}
                       {editInputComponent(myBusinessEmploymentAgency,"employmentagency","logo", "logo", myBusinessEmploymentAgency.logo, "imageUrl")}
                      {editInputComponent(myBusinessEmploymentAgency,"employmentagency","cardImage", "Card Image", myBusinessEmploymentAgency.cardImage, "imageUrl")}
                      {editInputComponent(myBusinessEmploymentAgency,"employmentagency","bannerImage", "Banner Image", myBusinessEmploymentAgency.bannerImage, "imageUrl")}
                      {editInputComponent(myBusinessEmploymentAgency,"employmentagency","description", "Short description", myBusinessEmploymentAgency.description, "textArea")}
                     
                            {/* {editInputComponent(
                              myBusinessEmploymentAgency,
                              "myBusinessEmploymentAgency",
                              "email",
                              "Contact Email",
                              myBusinessEmploymentAgency.email,
                              "email"
                            )}
                            {editInputComponent(
                              myBusinessEmploymentAgency,
                              "myBusinessEmploymentAgency",
                              "phone",
                              "Contact Phone",
                              myBusinessEmploymentAgency.phone,
                              "phone"
                            )}
                            {editInputComponent(
                              myBusinessEmploymentAgency,
                              "myBusinessEmploymentAgency",
                              "sex",
                              "Sex",
                              myBusinessEmploymentAgency.sex,
                              "radio",
                              ["male", "female"]
                            )} */}
                            
                            <MDTypography mt={5} mb={2} mx={2}>
                              Website Domains
                            </MDTypography> 

                      {editInputComponent(myBusinessEmploymentAgency,"employmentagency","websiteDomain", "website custom domain ", myBusinessEmploymentAgency.websiteDomain, "textField")}
                      {editInputComponent(myBusinessEmploymentAgency,"employmentagency","agencySubDomainFromPlaform", "Sub-Domain From Plaform", myBusinessEmploymentAgency.agencySubDomainFromPlaform, "textField")}
                     


                            <MDTypography mt={5} mb={2} mx={2}>
                              Contact
                            </MDTypography> 

                      {editInputComponent(myBusinessEmploymentAgency,"employmentagency","phone1", "Phone 1", myBusinessEmploymentAgency.phone1, "phone")}
                      {editInputComponent(myBusinessEmploymentAgency,"employmentagency","phone2", "Phone 2", myBusinessEmploymentAgency.phone2, "phone")}
                     

                            <MDTypography mt={5} mb={2} mx={2}>
                              Address
                            </MDTypography> 
                            
                      {editInputComponent(myBusinessEmploymentAgency,"employmentagency",
                        "locationCountry",
                        "Country",
                        myBusinessEmploymentAgency.locationCountry,
                        "textField"
                      )}
                      {/* {editInputComponent(myBusinessEmploymentAgency,"employmentagency","locationTown", "Head office city",
                      getCityById(myBusinessEmploymentAgency.locationTown,"single") , "autocompleteOption", cities,"TOWN_NAME","TOWN_NAME")} */}

                            {editInputComponent(
                              myBusinessEmploymentAgency,
                              "employmentagency",
                              "locationTown",
                              "Head office city",
                              cities
                                ? getCityById(myBusinessEmploymentAgency.locationTown, "single")
                                : "",
                              "selectOption",
                              cities,
                              "TOWN_NAME",
                              "TOWN_NAME",
                              "REGION"
                            )}  
                          </MDBox>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid> 
 
              </Grid>
            )}
          </Grid> 
 
        {/* </Container> */}
        {forceRerender}

        <Dialog
          open={openedDialog == "addWorkExperiance"}
          onClose={() => {
            setOpenedDialog("");
          }}
        >
          <DialogTitle>Add Profession and Work Experiance</DialogTitle>
          <DialogContent style={{ paddingTop: "15px" }}>
            {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}

            {jobs && (
              <Autocomplete
                fullWidth
                // sx={{ width: 400 }}
                onChange={(e, val) => {
                  if (val) {
                    console.log("val", val);
                    //  value=val;
                    handleNewWorkExperianceData("jobId", val.id);
                    // console.log("value",value);
                  }
                }}
                options={jobs}
                // defaultValue={options?(optionsSelectedField?value[optionsSelectedLable]:value):""}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    label="Profession or Job"
                    variant="outlined"
                  />
                )}
              />
            )}

            <TextField
              autoFocus
              margin="dense"
              id="expectingSalaryPerMonth"
              defaultValue={newWorkExperianceData.expectingSalaryPerMonth}
              label="Expecting Salary (Per Month)"
              type="number"
              onChange={(e) => {
                handleNewWorkExperianceData(
                  "expectingSalaryPerMonth",
                  e.target.value
                );
              }}
              fullWidth
              variant="outlined"
            />
            <TextField
              autoFocus
              margin="dense"
              id="workExperianceInMonth"
              defaultValue={newWorkExperianceData.workExperianceInMonth}
              onChange={(e) => {
                handleNewWorkExperianceData(
                  "workExperianceInMonth",
                  e.target.value
                );
              }}
              label="Work Experiance (In Month)"
              type="number"
              fullWidth
              variant="outlined"
            />

            <Autocomplete
              multiple
              id="tags-filled"
              options={[]}
              // defaultValue={[top100Films[13].title]}
              // defaultValue={newWorkExperianceData.skillsOrSubjects}
              onChange={(e, data) => {
                handleNewWorkExperianceData("skillsOrSubjects", data);
              }}
              freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label="Skills Or Subjects"
                  placeholder="Add Skill"
                />
              )}
            />

            <TextField
              autoFocus
              margin="dense"
              multiline
              rows={4}
              id="Describe"
              defaultValue={newWorkExperianceData.description}
              onChange={(e) => {
                handleNewWorkExperianceData("description", e.target.value);
              }}
              label="More Describtion (about your skills) "
              // type="number"
              fullWidth
              variant="outlined"
            />
            {/* newWorkExperianceData */}
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={()=>{setOpenedDialog("")}}>Cancel</Button> */}
            <MDButton
              color="success"
              // variant="text"
              // size="small"
              disabled={!newWorkExperianceData.jobId}
              onClick={() => {
                addNewWorkExperiance();
              }}
            >
              <strong> Save </strong>
            </MDButton>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openedDialog == "moreText"}
          onClose={() => {
            setOpenedDialog("");
          }}
        >
          {moreText && (
            <>
              {moreText.title && <DialogTitle>{moreText.title}</DialogTitle>}
              {moreText.content && (
                <DialogContent>
                  <DialogContentText>{moreText.content}</DialogContentText>
                </DialogContent>
              )}
            </>
          )}
          <DialogActions>
            <Button
              onClick={() => {
                setOpenedDialog("");
              }}
            >
              close
            </Button>
          </DialogActions>
        </Dialog>
      </MDBox>
    </DashboardLayout>
  );
}

export default AgencySettings;
