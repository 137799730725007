import { HttpService } from '../Api-Services/httpService';
import { createContext,useEffect, useState, useContext } from 'react';
import { DataManagementService } from '../Api-Services/dataManagementService';
// import { SideBarRoutes } from 'sideBarRoutes';
import { UseAuth } from 'Api-Services/useAuth';

const CurrentUserContext = createContext();
//now we have a context object

function CurrentUserProvider(props) {
  //create the provider and its functionality
  const shape = {
    type: 'mesfin', //films, planets, people
    id: 0, //id of film, or planet, or person
    data: {}, //the actual data object
  };
  const currentWebsiteShape = null;
  const [currentWebsite, setCurrentWebsite] = useState( {
                            website: null,
                            websiteMasterFields: null,
                          });
  const [currentUser, setCurrentUser] = useState({});
  const [websiteUsers, setWebsiteUsers] = useState(null);//websiteUserTypes
  const [employmentAgencyJobSeekers, setEmploymentAgencyJobSeekers] = useState(null);//websiteUserTypes
  const [websiteUserTypes, setWebsiteUserTypes] = useState([]);//setJobCategories
  const [jobCategories, setJobCategories] = useState([]);//
  const [jobs, setJobs] = useState([]);//
  const [myJobVacancyPosts, setMyJobVacancyPosts] = useState([]);
  const [educationLevels, setEducationLevels] = useState([]);
  const [myWebsites, setMyWebsites] = useState(null);
  const [myBusinesses, setMyBusinesses] = useState(null);
  const [myCurrentProfile, setMyCurrentProfile] = useState(null);
  const [myCurrentProfileLoading, setMyCurrentProfileLoading] = useState(false);
  const [sections, setSections] = useState([]);
  const [websiteTestDomainSuffixs, setWebsiteTestDomainSuffixs] = useState([]);
  const [websiteForms, setWebsiteForms] = useState([]);
  const [websiteFiles, setWebsiteFiles] = useState({});
  const [myBusinessEmploymentAgency, setMyBusinessEmploymentAgency] = useState(null);
  const [myBusinessEmploymentAgencies, setMyBusinessEmploymentAgencies] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [employmentAgencyPaymentAccounts, setEmploymentAgencyPaymentAccounts] = useState([]);
  const [employmentAgencyPaymentConfirmationRequests, setEmploymentAgencyPaymentConfirmationRequests] = useState([]);
  const [userFiles, setUserFiles] = useState({});
  const [userLoading, setUserLoading] = useState(false);
  const [websiteContentTypes, setWebsiteContentTypes] = useState([]);
  
  const [adminUIComponent, setAdminUIComponent] = useState({sidebarUiComponents:[],customUiComponents:[]});
  const [forceRerender, setForceRerender] = useState(true); 
  const [officeUiComponents, setOfficeUiComponents] = useState([]);

  ////////MEGENAGNA
  ////////MEGENAGNA
  ////////MEGENAGNA
  const [employeeHiringRequests, setEmployeeHiringRequests] = useState([]);//websiteUserTypes
  const [agencyContactMessages, setAgencyContactMessages] = useState([]);//websiteUserTypes
  const [agencyJobVacancies, setAgencyJobVacancies] = useState([]);//websiteUserTypes



  useEffect(() => { 
    getStarted();    
  },[]);


  const getStarted=async()=>{   
    
    getWebsiteContentTypes()  
    setUserLoading(true)
    let isUserLoggedIn= await UseAuth.verifyUser()  
    setUserLoading(false)
    
    console.log(   isUserLoggedIn, "isUserLoggedIn  ")
      // isWebsiteLoggedIn= true// await UseAuth.verifyWebsite()  
    if(isUserLoggedIn){
      let isWebsiteLoggedIn=await UseAuth.verifyWebsite()  
      getCurrentUser()
      getMyBusinesses()
      // getMyWebsites()
      getUserFiles()
      getWebsiteTestDomainSuffixs()
      if(isWebsiteLoggedIn){ 
        getMyCurrentProfile()
        // let isEmploymentAgencyLoggedIn= await UseAuth.verifyEmploymentAgency()  
        // getAdminUIComponent()
        // getMyBusinessEmploymentAgencies()
        // getCurrentWebsite()
        // getSections()
        // getOfficesUIelements()
        // getWebsiteFiles()
        // getCurrentWebsiteUsers()
        // getWebsiteUserTypes()
        // getWebsiteForms()

        ////////MEGENAGNA
        ////////MEGENAGNA 
        // let agencyDataD2=await getMyBusinessEmploymentAgency()
             
        // if(agencyDataD2){
        // if(agencyDataD2.id){ 
        //   // getPaymentMethods()
        //     getEmploymentAgencyPaymentAccounts(agencyDataD2.id)
        //     getEmploymentAgencyPaymentConfirmationRequests(agencyDataD2.id)
        //   //  getNewJobSeekers(agencyDataD.id)
        //     }
        //   }
        
        // getMyFeedPosts();
        getMyJobVacancyPosts();
        // getMyJobApplications();

        getEducationLevels()
        getJobCategories()
        getPaymentMethods()
        getJobs()
        getCurrentWebsiteEmploymentAgencyJobSeekers()
        getEmployeeHiringRequests() 
        getAgencyContactMessages() 
        getAgencyJobVacancies() 
 
      }

    }
  }

  const getCurrentUser = ()=>{   
     let mydetails
    getDocumentsAll("mydetails","/api/auth/mydetails").then((mydetailsRes)=>{
    if(mydetailsRes.success){
       mydetails =mydetailsRes.record 
     setCurrentUser(mydetails)
   }
    // console.log( mydetails, "getCurrentUser  CurrentUserContext")
   })
  }

  const getUserFiles = ()=>{    
     let fileRelativePath= "/api/file/allFoldersAndFiles_user"    
    getDocumentsAll("getphotosalbum",fileRelativePath).then((resdata)=>{
    if(resdata.success){ 
      setUserFiles(resdata.record )
     }
    // console.log( mydetails, "getCurrentUser  CurrentUserContext")
   })
  }

  const getWebsiteFiles = ()=>{    
     let fileRelativePath="/api/file/allFoldersAndFiles"   
    getDocumentsAll("getphotosalbum",fileRelativePath).then((resdata)=>{
    if(resdata.success){ 
     setWebsiteFiles(resdata.record )
     }
    // console.log( mydetails, "getCurrentUser  CurrentUserContext")
   })
  }

  const getWebsiteForms = ()=>{    
   postDocuments("formTemplates","/api/websiteContentType/getmultplecontentbycontenttypefield",{ "contentTypeKind":"form" }).then((resdata)=>{
    if(resdata.success){ 
     setWebsiteForms(resdata.record )
     }
    // console.log( mydetails, "getCurrentUser  CurrentUserContext")
   })
  }


  // const getAdminUIComponent= ()=>{  
  // // let localUIComponentsRes= DataManagementService.getLocalstorageDataSecured("uIComponents")
  // // if(localUIComponentsRes){
  // //   if(localUIComponentsRes.customUiComponents){
  // //   let localUIComponents =SideBarRoutes.getRoutesFromLocalstorage(localUIComponentsRes);
  // //   setAdminUIComponent(localUIComponents) 
  // //   }
  // // }

  // getDocumentsAll("AdminUIComponent","/api/auth/myloggedwebsiteuicomponents",{}).then((AdminUIComponent)=>{
  //   if(AdminUIComponent.success){ 
  //     let  APIUIComponents= SideBarRoutes.getRoutesFromLocalstorage(AdminUIComponent.record);
  //      DataManagementService.setLocalstorageDataSecured(APIUIComponents,"uIComponents")
  //      setAdminUIComponent(APIUIComponents) 
  //   }
  // }) 
 
  // } 
  
  const getOfficesUIelements = ()=>{  
    getDocumentsAll("allOffices","/api/officeUserRole/myoffices",{}).then((allOfficesRes)=>{
      if(allOfficesRes.success){
       let officesData=allOfficesRes.record
       setOfficesUIelements(officesData);  
       }
      })//init user offices and data  
  }

  
  const getMyBusinessEmploymentAgency = async()=>{ 
    let details=null; 
    await getDocumentsAll("mybusinessagencies","/api/auth/mybusinessemploymentagency",{}).then((allOfficesRes)=>{
      if(allOfficesRes.success){
        details=allOfficesRes.record
       setMyBusinessEmploymentAgency(details);  
       DataManagementService.setLocalstorageDataSecured(details,"currentProfile")
       }
      })//init user offices and data  
      return details
  }

  const getMyBusinessEmploymentAgencies = ()=>{  
    getDocumentsAll("mybusinessagencies","/api/auth/mybusinessemploymentagencies",{}).then((allOfficesRes)=>{
      if(allOfficesRes.success){
       let officesData=allOfficesRes.record
       setMyBusinessEmploymentAgencies(officesData);  
       }
      })//init user offices and data  
  }

  const getEmploymentAgencyPaymentConfirmationRequests = (id) => {
    let mydetails;
    let agentId=id
    if(!id){
      if(myBusinessEmploymentAgency){
        if(myBusinessEmploymentAgency.id){
         agentId=myBusinessEmploymentAgency.id
        }
      }
    }  
    let relativePath = "/api/employmentagencypaymentconfirmationrequest/getbyfield";
    // agentId
    
    try {
        
    if(agentId){
    let data =  { employmentAgencyId:agentId}
    postDocuments("contactus", relativePath, data, {}).then((mydetailsRes) => {
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        if(mydetails.length){ 
          mydetails=mydetails.reverse() 
          setEmploymentAgencyPaymentConfirmationRequests(mydetails);  
        } 
      }
      else{
        setEmploymentAgencyPaymentConfirmationRequests([]); 
      }
      // console.log(mydetails ,jobSeekersD , "getNewJobSeekers  ")
      
    }
    );
  }
  } catch (error) {
      // console.log(error , "error  ")
        
      }


  };

  const getEmploymentAgencyPaymentAccounts = (id) => {
    let mydetails;
    let agentId=id
    if(!id){
      if(myBusinessEmploymentAgency){
        if(myBusinessEmploymentAgency.id){
         agentId=myBusinessEmploymentAgency.id
        }
      }
    }  
    let relativePath = "/api/employmentagencypaymentaccount/getbyfield";
    // agentId
    
    try {
        
    if(agentId){
    let data =  { employmentAgencyId:agentId}
    postDocuments("contactus", relativePath, data, {}).then((mydetailsRes) => {
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        if(mydetails.length){ 
        setEmploymentAgencyPaymentAccounts(mydetails);  
        } 
      }
      else{
        setEmploymentAgencyPaymentAccounts([]); 
      }
      // console.log(mydetails ,jobSeekersD , "getNewJobSeekers  ")
      
    }
    );
  }
  } catch (error) {
      // console.log(error , "error  ")
        
      }


  };

  const getWebsiteTestDomainSuffixs = ()=>{  
    getDocumentsAll("WebsiteTestDomainSuffixs","/api/websiteTestDomainSuffix/all",{}).then((allOfficesRes)=>{
      if(allOfficesRes.success){
       let officesData=allOfficesRes.record
       setWebsiteTestDomainSuffixs(officesData);  
       }
      })//init user offices and data  
  }

  const getCurrentWebsite = ()=>{ 
    getDocumentsAll("currentloggedwebsite","/api/auth/currentloggedwebsite").then((currentloggedwebsiteRes)=>{
          //  console.log("currentloggedwebsiteRes",currentloggedwebsiteRes)

      if(currentloggedwebsiteRes.success){
       if(currentloggedwebsiteRes.record){ 
          setCurrentWebsite(currentloggedwebsiteRes.record )
       }
     }
   }
   )    
  }

  const getCurrentWebsiteUsers = ()=>{ 
    
   getDocumentsAll("allWebsiteUsers","/api/websiteUser/currentwebsiteUsers").then((allWebsiteUsersRes)=>{
          //  console.log("currentloggedwebsiteRes",currentloggedwebsiteRes)

      if(allWebsiteUsersRes.success){
       if(allWebsiteUsersRes.record){ 
       setWebsiteUsers(allWebsiteUsersRes.record )
       }
     }
   }
   )    
  }

  const getCurrentWebsiteEmploymentAgencyJobSeekers = ()=>{ 
   getDocumentsAll("allWebsiteUsers","/api/employmentagencyjobseeker/all").then((allWebsiteUsersRes)=>{
          //  console.log("employmentagencyjobseeker",allWebsiteUsersRes)
      if(allWebsiteUsersRes.success){
       if(allWebsiteUsersRes.record){ 
       setEmploymentAgencyJobSeekers(allWebsiteUsersRes.record )
       }
     }
   }
   )    
  }

  const getEmployeeHiringRequests = ()=>{ 
   getDocumentsAll("allWebsiteUsers","/api/employeehiringrequest/myagencyhiringrequests").then((allWebsiteUsersRes)=>{
          //  console.log("employmentagencyjobseeker",allWebsiteUsersRes)
      if(allWebsiteUsersRes.success){
       if(allWebsiteUsersRes.record){ 
       if(allWebsiteUsersRes.record.length){ 
       let dataa= allWebsiteUsersRes.record.reverse();
        setEmployeeHiringRequests(dataa )
       }
       }
     }
   }
   )    
  }

  const getAgencyContactMessages = ()=>{ 
   getDocumentsAll("allWebsiteUsers","/api/employmentContactMessage/myagencycontactmessages").then((allWebsiteUsersRes)=>{
          //  console.log("employmentagencyjobseeker",allWebsiteUsersRes)
      if(allWebsiteUsersRes.success){
       if(allWebsiteUsersRes.record){ 
       if(allWebsiteUsersRes.record.length){ 
       let dataa= allWebsiteUsersRes.record.reverse();
       setAgencyContactMessages(dataa )
       }
       }
     }
   }
   )    
  }

  const getAgencyJobVacancies = ()=>{ 
   getDocumentsAll("allWebsiteUsers","/api/employmentjobvacancy/myagencyjobvacancies").then((allWebsiteUsersRes)=>{
          //  console.log("employmentagencyjobseeker",allWebsiteUsersRes)
      if(allWebsiteUsersRes.success){
       if(allWebsiteUsersRes.record){ 
       if(allWebsiteUsersRes.record.length){ 
       let dataa= allWebsiteUsersRes.record.reverse();
       setAgencyJobVacancies(dataa )
       }
       }
     }
   }
   )    
  }

  const getWebsiteUserTypes= ()=>{ 
    getDocumentsAll("websiteUserTypes","/api/websiteUserType/all").then((websiteUserTypesRes)=>{
          //  console.log("currentloggedwebsiteRes",currentloggedwebsiteRes)
      if(websiteUserTypesRes.success){
       if(websiteUserTypesRes.record){ 
       setWebsiteUserTypes(websiteUserTypesRes.record )
       }
     }
   }
   )    
  }
  

  const getJobCategories= ()=>{ 
    getDocumentsAll("jobcatagory","/api/jobcatagory/all").then((websiteUserTypesRes)=>{
          //  console.log("currentloggedwebsiteRes",currentloggedwebsiteRes)
      if(websiteUserTypesRes.success){
       if(websiteUserTypesRes.record){ 
        setJobCategories(websiteUserTypesRes.record )
       }
     }
   }
   )    
  }


  const getJobs= ()=>{ 
    getDocumentsAll("job","/api/job/all").then((websiteUserTypesRes)=>{
          //  console.log("currentloggedwebsiteRes",currentloggedwebsiteRes)
      if(websiteUserTypesRes.success){
       if(websiteUserTypesRes.record){ 
        setJobs(websiteUserTypesRes.record )
       }
     }
   }
   )    
  }
  
  const getPaymentMethods= ()=>{ 
    
    getDocumentsAll("job","/api/paymentmethod/all").then((websiteUserTypesRes)=>{
          //  console.log("currentloggedwebsiteRes",currentloggedwebsiteRes)
      if(websiteUserTypesRes.success){
       if(websiteUserTypesRes.record){ 
        setPaymentMethods(websiteUserTypesRes.record )
       }
     }
   }
   )    
  }
  

  const getMyJobVacancyPosts = () => {
    try {
      let mydetails = [];
      getDocumentsAll("getMyJobVacancyPosts", "/api/post/myjobvacancyposts").then(
        (mydetailsRes) => {
          if (mydetailsRes.success) {
            if (mydetailsRes.record) {
              mydetails = mydetailsRes.record;
            }
          }
          if (mydetails.length) {
            // console.log(mydetails,"getMyFeedPosts");
            mydetails.reverse();
          }
          setMyJobVacancyPosts(mydetails);
        }
      );
    } catch (error) {
      // console.log(error);
    }
  };

  const getEducationLevels =()=>{   
      let mydetails
    getDocumentsAll("mydetails","/api/educationLevel/all").then((mydetailsRes)=>{
    if(mydetailsRes.success){
        mydetails =mydetailsRes.record 
        setEducationLevels(mydetails)
    }
    // console.log( mydetails, "getCurrentUser  CurrentUserContext")
    })
  }

  const getMyWebsites = ()=>{ 
    getDocumentsAll("mywebsites","/api/auth/mywebsites") .then((myWebsitesRes)=>{
      if(myWebsitesRes.success){
       if(myWebsitesRes.record){ 
       if(myWebsitesRes.record.length){ 
         setMyWebsites(myWebsitesRes.record)
         }
       }
      }
     }
     ) 
  }
  
  const getMyBusinesses = ()=>{ 
    getDocumentsAll("mywebsites","/api/profile/myprofiles") .then((myWebsitesRes)=>{
      if(myWebsitesRes.success){
       if(myWebsitesRes.record){ 
       if(myWebsitesRes.record.length){ 
        setMyBusinesses(myWebsitesRes.record)
         }
       }
      }
     }
     ) 
  }
  

  const getMyCurrentProfile = async () => {
    setMyCurrentProfileLoading(true);
    let mydetails = null;
    await getDocumentsAll("myjobseekerinformation", "/api/profile/myprofile").then(
      (mydetailsRes) => {
        if (mydetailsRes.success) {
          if (mydetailsRes.record) {
            mydetails = mydetailsRes.record;
          }
        }
      }
    );
    setMyCurrentProfile(mydetails);
    setMyCurrentProfileLoading(false);
  };

  const getSections = ()=>{  
    getDocumentsAll("sections","/api/websiteSection/all",{}).then((sectionsRes)=>{
      if(sectionsRes.success){
       let sectionsD =sectionsRes.record 
       setSections(sectionsD)
     }
     }) 
  }

  const getWebsiteContentTypes = ()=>{  
    getDocumentsAll("websiteContentTypes","/api/websiteContentType/all",{}).then((contentTypesRes)=>{
      if(contentTypesRes.success){
       let contentTypesResD =contentTypesRes.record 
       setWebsiteContentTypes(contentTypesResD)
     }
     }) 
  } 
 
  
  const getDocumentsAll=async( documentName,relativePath ,additionalData )=>{ 
    let responseD={success:false,message:"",record: null};
    let documentsResponse={success:false}  
    ////////// UPDATED/////////////
    documentsResponse=  await HttpService.getService(relativePath,additionalData?additionalData:{},""); 
    if(documentsResponse.data){  
      responseD.record=documentsResponse.data.record; 
      responseD.message=documentsResponse.data.message;  
    }
    if(documentsResponse.success){  
      responseD.success=true;
      let record=responseD.record;
      if((documentName=="allOffices")){     
        await setOfficesUIelements(record); 
        DataManagementService.setLocalstorageDataSecured(record,"allOffices")
      }    
      }  
      setForceRerender(!forceRerender)
      return responseD;
    ////////// UPDATED///////////// 
  } 

  const postDocuments=async( documentName,relativePath ,data,additionalData )=>{ 
    let responseD={success:false,message:"",record: null};
    let documentsResponse={success:false}  
    ////////// UPDATED/////////////
    documentsResponse=  await HttpService.postService(relativePath,data,additionalData?additionalData:{},""); 
    if(documentsResponse.data){  
      responseD.record=documentsResponse.data.record; 
      responseD.message=documentsResponse.data.message;  
    }
    if(documentsResponse.success){  
      responseD.success=true;
      let record=responseD.record;  
      }  
      setForceRerender(!forceRerender)
      return responseD;
    ////////// UPDATED///////////// 
  } 

    const setOfficesUIelements =async( officesD  )=>{
    let allUis= []
        if(officesD.officesRoles){ 
          if( officesD.officesRoles.length>0){ 
          //  console.log("setOfficesUIelements",officesD.officesRoles)
          let officesRolesD=officesD.officesRoles
          officesRolesD.forEach(officeRoleD => {
          let officeUiComponentsD = officeRoleD.officeUiComponents;
          if(officeUiComponentsD.success){ 
            var a = [1, 2, 3], b = [101, 2, 1, 10]
            if(allUis.length){
            allUis = allUis.concat(officeUiComponentsD.record.details)
            }
            else{
            allUis=(officeUiComponentsD.record.details);
            }
          //  console.log("concat UIelements",allUis)
          }
          });
          if(allUis.length){
          setOfficeUiComponents(allUis) 
          }
          }
        }
  } 

    async function updateCurrentUser(variableName) { 
        if(variableName){
          if(variableName=="currentUser"){
            getCurrentUser() 
          }
          if(variableName=="websiteUsers"){
            getCurrentWebsiteUsers() 
          }
          if(variableName=="employmentAgencyJobSeekers"){
            getCurrentWebsiteEmploymentAgencyJobSeekers() 
          }
          if(variableName=="myBusinessEmploymentAgency"){
            getMyBusinessEmploymentAgency() 
          }
          if(variableName=="myBusinessEmploymentAgencies"){
            getMyBusinessEmploymentAgencies() 
          }
          if(variableName=="paymentMethods"){
            getPaymentMethods() 
          }
          if(variableName=="employeeHiringRequests"){
            getEmployeeHiringRequests() 
          }
          if(variableName=="agencyContactMessages"){
            getAgencyContactMessages() 
          }
          if(variableName=="agencyJobVacancies"){
            getAgencyJobVacancies() 
          }
          if(variableName=="myJobVacancyPosts"){
            getMyJobVacancyPosts() 
          }
          if(variableName=="websiteUserTypes"){
            getWebsiteUserTypes() 
          }
          if(variableName=="jobCategories"){
            getJobCategories() 
          }
          if (variableName == "employmentAgencyPaymentAccounts") {
            getEmploymentAgencyPaymentAccounts();
          }
          if (variableName == "employmentAgencyPaymentConfirmationRequests") {
            getEmploymentAgencyPaymentConfirmationRequests();
          }
          if(variableName=="jobs"){
            getJobs() 
          }
          if(variableName=="educationLevels"){
            getEducationLevels() 
          }
          if(variableName=="currentWebsite"){
            getCurrentWebsite() 
          }
          if(variableName=="adminUIComponent"){
            // getAdminUIComponent() 
          }
          if(variableName=="officesUIelements"){
            getOfficesUIelements() 
          }
          if(variableName=="myWebsites"){
            getMyWebsites() 
          } 
          if(variableName=="myBusinesses"){
            getMyBusinesses() 
          } 
          if(variableName=="myCurrentProfile"){
            getMyCurrentProfile() 
          } 
          if(variableName=="sections"){
            getSections() 
          } 
          if(variableName=="websiteContentTypes"){
            getWebsiteContentTypes() 
          }  
          if(variableName=="websiteFiles"){
            getWebsiteFiles() 
          }  
          if(variableName=="websiteForms"){
            getWebsiteForms() 
          }  
          if(variableName=="userFiles"){
            getUserFiles() 
          }  
        }
        else{
          getStarted()
        }
    // await getDocumentsAll("allOffices","/api/officeUserRole/myoffices",{})  
    // await getDocumentsAll("currentloggedwebsite","/api/auth/currentloggedwebsite") 
  } 

  return <CurrentUserContext.Provider 
            value={[{ 
              "myBusinessEmploymentAgency":myBusinessEmploymentAgency,
              "myBusinessEmploymentAgencies":myBusinessEmploymentAgencies,
              "userLoading":userLoading,
              "websiteUserTypes":websiteUserTypes,
              "adminUIComponent":adminUIComponent,
              "currentUser":currentUser,
              "currentWebsite":currentWebsite,
              "websiteUsers":websiteUsers,
              "paymentMethods":paymentMethods,
              "myJobVacancyPosts":myJobVacancyPosts,
              "employmentAgencyPaymentAccounts":employmentAgencyPaymentAccounts,
              "employmentAgencyPaymentConfirmationRequests":employmentAgencyPaymentConfirmationRequests,
              "employmentAgencyJobSeekers":employmentAgencyJobSeekers,
              "employeeHiringRequests":employeeHiringRequests,
              "agencyContactMessages":agencyContactMessages,
              "agencyJobVacancies":agencyJobVacancies,
              "jobCategories":jobCategories,
              "jobs":jobs,
              "educationLevels":educationLevels,
              "myWebsites":myWebsites,
              "myBusinesses":myBusinesses,
              "myCurrentProfile":myCurrentProfile,
              "myCurrentProfileLoading":myCurrentProfileLoading,
              "sections":sections,
              "websiteContentTypes":websiteContentTypes,
              "officeUiComponents":officeUiComponents,
              "userFiles":userFiles,
              "websiteFiles":websiteFiles,
              "websiteForms":websiteForms,
              "websiteTestDomainSuffixs":websiteTestDomainSuffixs,
              "forceRerenderContext":forceRerender
                  } ,
              updateCurrentUser]} 
              {...props} 
            />;
}

function useCurrentUser() {
  //for pages that want to access the context object's value
  //custom hook use...
  try{
   const context = useContext(CurrentUserContext);
    // console.log('  inside the Provider',context);
      // if (!context) throw new Error('Not inside the Provider');
      return context; // [fav, updateFav]

    }
    catch(err){}
}

export { useCurrentUser, CurrentUserProvider };
