/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/ecommerce/products/new-product/components/FormField";

function Application(props) {  
  const [forceRerender, setForceRerender] = useState(false);
  const [sectionValues, setSectionValues] = useState(props.sectionValues);
   
  useEffect(() => {
    console.log("props.sectionValues", props.sectionValues)
    props.validateSection(props.sectionValues)
  }, []);

  const changeInputValue = (field,value) =>
  {
    let sectionValuesD=sectionValues
    sectionValuesD[field].value=value
    setSectionValues(sectionValuesD)
    setForceRerender(!forceRerender)
    props.changeSectionValues("applicationSectionValues",sectionValuesD)
  }

  return (<>
  {forceRerender}
    <MDBox>
      <MDTypography variant="h5">Application</MDTypography>
       
      <MDBox mt={5}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                How To Apply&nbsp;&nbsp;
              {(sectionValues.howToApply.required)&& <MDTypography variant="caption" color="text">
                  (required)
                </MDTypography>}
              </MDTypography>
            </MDBox>
            <MDEditor value={sectionValues.howToApply.value}  
            //  onChange={setDescription}  
              onChange={(e)=>{
              if(e){
                  changeInputValue("howToApply", e )
              }
            }}
             />
          </Grid>
         
        </Grid>
      </MDBox>
    </MDBox>
 </> );
}

export default Application;
