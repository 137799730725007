/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Images
import masterCardLogo from "assets/images/logos/mastercard.png";
import visaLogo from "assets/images/logos/visa.png";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { Link, Navigate } from "react-router-dom";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useEffect } from "react";
import MDInput from "components/MDInput";
import { HttpService } from "Api-Services/httpService";
import { Refresh } from "@mui/icons-material";

function PaymentMethod(props) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
    const [currentData, updateContext] = useCurrentUser();
    const { employmentAgencyPaymentAccounts, paymentMethods, myBusinessEmploymentAgency  } =
      currentData;

    const [loading , setLoading] = React.useState(false)
    const [forceRerender , setForceRerender] = React.useState(false)
    const [newPaymentMethodId , setNewPaymentMethodId] = React.useState("")
    const [newPaymentAccountName , setNewPaymentAccountName] = React.useState("")
    const [newPaymentAccountNumber , setNewPaymentAccountNumber] = React.useState("")
    const [selectedPaymentAccount , setSelectedPaymentAccount] = React.useState(null)
    const [openedDialog , setOpenedDialog] = React.useState(""); //setJobSeeker

    useEffect(() => {
      console.log("employmentAgencyPaymentAccounts", employmentAgencyPaymentAccounts);
      // setForceRerender(!forceRerender);
    }, [employmentAgencyPaymentAccounts]);

    
  const getDataFromArray = (dataArray, searchField, searchValue, returnRows, returnField) => {
    let res = {};
    // console.log("dataArray   ", dataArray,searchField, searchValue, returnRows, returnField);
    try {
      if (dataArray && searchField && searchValue) {
        if (dataArray.length > 0) {
          let filteredLevels = dataArray.filter((level) => level[searchField] == searchValue);
          if (filteredLevels.length) {
            res = filteredLevels;
            if (returnRows == "single") {
              let row = filteredLevels[0];
              res = row;
              if (returnField) {
                let rowField = row[returnField];
                res = rowField;
              }
            }
          }
        }
      }
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
    return res;
  };

    const PaymentMethodsAccounts=()=>{


    return (
      <>
        <Card id="delete-account">
          <MDBox
            pt={2}
            px={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDTypography variant="h6" fontWeight="medium">
              Payment Methods  
            </MDTypography>
            {props.view ? (
              <> 
              <MDButton
                variant="gradient"
                color="dark"
                component={Link}
                to={"/pages/account/payment/payment-method"}
              >
                <Icon sx={{ fontWeight: "bold" }}>edit</Icon>
                &nbsp;Manage Methods
              </MDButton>
              </>
            ) : (<>
              <MDButton
                  variant="outlined"
                  color="dark"
                  size={"small"}
                  // component={Link}
                  onClick={()=>{
                    updateContext("employmentAgencyPaymentAccounts");
                    updateContext("paymentMethods");
                    updateContext("myBusinessEmploymentAgency");
                  }}
                >
                  <Refresh sx={{ fontWeight: "bold" }}></Refresh>
                  &nbsp;Refresh
                </MDButton>
              <MDButton
                variant="gradient"
                color="dark"
                onClick={() => {
                  setOpenedDialog("addNewMethod");
                  
                  setSelectedPaymentAccount(null)
                  setNewPaymentMethodId("")
                  setNewPaymentAccountName("")
                  setNewPaymentAccountNumber("")
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                &nbsp;add new method
              </MDButton>
           </> )}

          </MDBox>
          <MDBox p={2}>
           {(employmentAgencyPaymentAccounts?(employmentAgencyPaymentAccounts.length?true:false):false)&& <Grid container spacing={3}>
             {employmentAgencyPaymentAccounts.map((paymentAccount)=>{
                let pMethod=getDataFromArray(
                  paymentMethods,
                  "id",
                  paymentAccount.paymentMethodId,
                  "single"
                )
             return <Grid item xs={12} md={6}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  sx={{
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDBox
                    component="img"
                    src={pMethod.logo}
                    alt="master card"
                    width="10%"
                    mr={2}
                  />
                  <MDTypography variant="h6" fontWeight="medium">
                    {/* ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;7852
                     */}
                     {paymentAccount.accountOwnerNumber}
                  </MDTypography>
                  <MDBox
                    ml="auto"
                    lineHeight={0}
                    color={darkMode ? "white" : "dark"}
                  >
                    <Tooltip title="Edit Method" placement="top">
                      <Icon sx={{ cursor: "pointer" ,mx:2}} fontSize="small"
                      onClick={()=>{
                        setOpenedDialog("editMethod")
                        setSelectedPaymentAccount(paymentAccount)
                        setNewPaymentMethodId(paymentAccount.paymentMethodId)
                        setNewPaymentAccountName(paymentAccount.accountOwnerFullName)
                        setNewPaymentAccountNumber(paymentAccount.accountOwnerNumber)
                      }}>
                        edit
                      </Icon>
                    </Tooltip>
                    <Tooltip title="Delet Method" placement="top">
                      <Icon sx={{ cursor: "pointer",mx:2 }} fontSize="small"
                      onClick={()=>{
                        deletePaymentAccount(paymentAccount.id) 
                      }}>
                        delete
                      </Icon>
                    </Tooltip>
                  </MDBox>
                </MDBox>
              </Grid>} )}
            </Grid>}
          </MDBox>
        </Card>
      </>
    );
  }
  
  const postDocuments = async (
    documentName,
    relativePath,
    data,
    additionalData
  ) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    // setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(
      relativePath,
      data,
      additionalData || {},
      ""
    );
    // console.log(documentName, " data: ", documentsResponse   );
    // setLoading(false);

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    } else {
      responseD.record = documentsResponse.data;
    } 
    return responseD; 
  };


  const getDocumentsAll = async (
    documentName,
    relativePath,
    additionalData
  ) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    ////////// UPDATED/////////////
    documentsResponse = await HttpService.getService(
      relativePath,
      additionalData ? additionalData : {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }

    if (documentsResponse.success) {
      responseD.success = true;
    }
    setForceRerender(!forceRerender);
    return responseD;
  };

  const addNewPaymentAccount= async () => {
    console.log("data",selectedPaymentAccount );
    // return
    try {
      if(myBusinessEmploymentAgency){
        
      let data = { 
        employmentAgencyId: myBusinessEmploymentAgency.id, 
        paymentMethodId: newPaymentMethodId ,
        accountOwnerFullName:newPaymentAccountName ,
        accountOwnerNumber :newPaymentAccountNumber , 
      };
      let relativePath = "/api/employmentagencypaymentaccount/new";
      if(openedDialog=="editMethod"){
         data.id=selectedPaymentAccount.id
         relativePath = "/api/employmentagencypaymentaccount/edit";
      }
      let refreshVariable = "employmentAgencyPaymentAccounts";
     
 
    console.log("data",data );
        if (data) {
          setLoading(true);
          let changeUserProfileRes = await postDocuments(
            "newemploymentAgencyPaymentAccount",
            relativePath,
            data
          );
          //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});
          setLoading(false);

          if (changeUserProfileRes.success) {
            setOpenedDialog("");
            updateContext(refreshVariable);
          }
        } 
      }
    } catch (error) {
    console.log("data error",error );

    }
  };

  const deletePaymentAccount= async (id) => {
    // console.log("data",selectedPaymentAccount );
    // return
    try {
      if(myBusinessEmploymentAgency){
         
      let relativePath = "/api/employmentagencypaymentaccount/delete/"+id;
       
      let refreshVariable = "employmentAgencyPaymentAccounts";
     
  
          setLoading(true);
          let changeUserProfileRes = await getDocumentsAll(
            "newemploymentAgencyPaymentAccount",
            relativePath,
            {}
          );
          //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});
          setLoading(false);

          if (changeUserProfileRes.success) {
            setOpenedDialog("");
            updateContext(refreshVariable);
          }
      
      }
    } catch (error) {
    console.log("data error",error );

    }
  };

   
  const dialogs=()=> {
    return (
      <>
      {/* <h1>mesfin</h1> */}
        <Dialog
          // fullWidth={fullWidth}
          // maxWidth={maxWidth}
          open={(openedDialog == "addNewMethod"||openedDialog == "editMethod")}
          onClose={() => setOpenedDialog("")}
        >
          <DialogTitle>{(openedDialog == "addNewMethod")?"ADD NEW ":"EDIT "} PAYMENT ACCOUNT</DialogTitle>
          <DialogContent>
            {/* <DialogContentText>
              You can set my maximum width and whether to adapt or not.
            </DialogContentText> */}
            <Box
              noValidate
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                m: "auto",
                width: "fit-content",
              }}
            >
              <FormControl 
              fullWidth
              sx={{ mt: 2, minWidth: 220 }}
              >
                
                </FormControl>
              <FormControl 
              fullWidth
              sx={{ mt: 2, minWidth: 220 }}
              >
                <InputLabel htmlFor="max-width">Bank / Method</InputLabel>
                <Select
                  sx={{ py:2  }}
                  // autoFocus
                  variant="outlined"
                  defaultValue= {newPaymentMethodId}
                  value={newPaymentMethodId}
                  onChange={(e)=>{
                      // console.log("e",e)
                      setNewPaymentMethodId(e.target.value)
                  }}
                  label="Bank / Method"
                  inputProps={{
                    name: "max-width",
                    id: "max-width",
                  }}
                >
                  {/* <MenuItem selected value={""}>{"Choose Bank"}</MenuItem> */}
                  {(paymentMethods)?paymentMethods.map((pMethod)=><MenuItem value={pMethod.id}>{pMethod.name}</MenuItem>):<></> }
                </Select>
              </FormControl>
              <FormControl 
              fullWidth
              sx={{ mt: 2, minWidth: 220 }}
              >
                {/* <InputLabel htmlFor="max-width">Account Owner Name</InputLabel> */}
               
              <MDInput
                placeholder="Enter text"                
                label={"Account Owner Name"}
                  value={newPaymentAccountName}
                onChange={(e) => {
                  setNewPaymentAccountName(e.target.value)
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
              </FormControl>
              <FormControl 
              fullWidth
              sx={{ mt: 2, minWidth: 220 }}
              >
                {/* <InputLabel htmlFor="max-width">Account Number</InputLabel> */}
                
              <MDInput
                placeholder="Enter text"
                label={"Account Number"}
                defaultValue={newPaymentAccountNumber}
                onChange={(e) => {  
                  setNewPaymentAccountNumber(e.target.value)
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <MDButton

              onClick={() => {
                setOpenedDialog("");
              }}
            >
              Cancel
            </MDButton>
            <MDButton
                disabled={!(newPaymentAccountName&&newPaymentAccountNumber&&newPaymentMethodId)}
                variant="gradient"
                color="success"
              onClick={() => {
              addNewPaymentAccount();
              }}
            >
             {loading?"Saving":"Save"}
            </MDButton>
          </DialogActions>
        </Dialog>
         
      </>
    );
  }

  return (
    <>
      {props.view ? (
        <>{PaymentMethodsAccounts()} </>
      ) : (
        <>
          <BaseLayout stickyNavbar>
            <PaymentMethodsAccounts />
          </BaseLayout>

          {dialogs()}
          {forceRerender}
        </>
      )}
    </>
  );
}

export default PaymentMethod;
