 
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ResetCover from "layouts/authentication/reset-password/cover";

// Material Dashboard 2 PRO React components 

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";
import JobSeekerFullDetail from "layouts/Employment/Job-Seekers/components/JobSeekerDetail/jobSeekerFullDetail";
import { Message } from "@mui/icons-material";
import PaymentMethod from "layouts/pages/account/billing/components/PaymentMethod";
import BillConfirmationRequests from "layouts/pages/account/billing/components/BillingInformation";
import NewVacancy from "layouts/Employment/job-vacancies/new-vacancy";
import JobVacancyApplications from "layouts/Employment/job-vacancies/job-vacancy-applications";

const routesCustom = [ 
  {
    type: "collapse",
    name: "Job Seekers Full Detail",
    key: "Job Seekers Full Detail",
    icon: <Message/>,
    route: "/Employment/Job-Seekers/Job-Seeker-Full-Detail",
    component: <JobSeekerFullDetail />,
    noCollapse: true,
  },  
  {
    type: "collapse",
    name: "New Vacancy",
    key: "New Vacancy",
    icon: <Message/>,
    route: "/Employment/Job-Vacancies/New",
    component: <NewVacancy />,
    noCollapse: true,
  }, 
  {
    type: "collapse",
    name: "New Vacancy",
    key: "New Vacancy",
    icon: <Message/>,
    route: "/Employment/Job-Vacancy-Applications",
    component: <JobVacancyApplications />,
    noCollapse: true,
  },  
  {
    type: "collapse",
    name: "Payment Method",
    key: "Payment Method",
    icon: <Message/>,
    route: "/pages/account/payment/payment-method",
    component: <PaymentMethod />,
    noCollapse: true,
  },  
  {
    type: "collapse",
    name: "Bill Confirmation Requests",
    key: "Bill Confirmation Requests",
    icon: <Message/>,
    route: "/pages/account/payment/bill-confirmation-requests",
    component: <BillConfirmationRequests />,
    noCollapse: true,
  },  
];

export default routesCustom;
