/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Settings page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import Sidenav from "layouts/pages/account/settings/components/Sidenav";
import Header from "layouts/pages/account/settings/components/Header";
import BasicInfo from "layouts/pages/account/settings/components/BasicInfo";
import ChangePassword from "layouts/pages/account/settings/components/ChangePassword";
import Authentication from "layouts/pages/account/settings/components/Authentication";
import Accounts from "layouts/pages/account/settings/components/Accounts";
import Notifications from "layouts/pages/account/settings/components/Notifications";
import Sessions from "layouts/pages/account/settings/components/Sessions";
import DeleteAccount from "layouts/pages/account/settings/components/DeleteAccount";

import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";

// @mui material components
// import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";

import kal from "assets/images/kal-visuals-square.jpg";
import marie from "assets/images/marie.jpg";
import ivana from "assets/images/ivana-square.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import { Avatar, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import React, { useState } from "react";
// import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
// import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import burceMars from "assets/images/bruce-mars.jpg";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import MDBadge from "components/MDBadge";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import PageLayout from "examples/LayoutContainers/PageLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { ArrowBackIosNewSharp } from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import DashboardNavbar2 from "examples/Navbars/DashboardNavbar2";
function ContactUsMessages() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [visible, setVisible] = useState(true);
  const [forceRerender,setForceRerender] = useState(true);

  const [currentContextData, updateContext  ] = useCurrentUser();  
  const {websiteUsers,employeeHiringRequests,agencyContactMessages,employmentAgencyJobSeekers,websiteUserTypes}  = currentContextData;  
 
  const [currentConversationWith, setCurrentConversationWith] = useState(null);

  const handleSetVisible = () => setVisible(!visible);
 
  React.useEffect(() => {
    (async () => { 
      setForceRerender(!forceRerender)
    console.log("agencyContactMessages",agencyContactMessages)

    })() 
  }, [agencyContactMessages]);
  
 
  const truncateString = (str, num, lableName) => {
    let res = str;
    try {
      if (str) {
        if (str.length > num) {
          // let btn = (
          //   <Button
          //     variant="text"
          //     onClick={() => {
          //       setMoreText({ content: str, title: lableName });
          //       setOpenedDialog("moreText");
          //       setForceRerender(!forceRerender);
          //     }}
          //   >
          //     {"View more"}
          //   </Button>
          // );
          let txt = str.slice(0, num) + "...";
          res = (
            <>
              {txt}
               {/* {btn} */}
            </>
          );
        }
      }
    } catch (error) {
      // console.log("errrr",error)
    }
    return res;
  };


  const renderSidenavItems = agencyContactMessages.map((message, key) => {
    const itemKey = `item-${key}`;

    return (
      <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <MDTypography
          component="a"
          // href={`#${href}`}
          onClick={()=>{setCurrentConversationWith(message);setForceRerender(!forceRerender)}}
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light },
            transitions,
          }) => ({
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: light.main,
            },
            backgroundColor:(currentConversationWith?((currentConversationWith.fullName==message.fullName)?light.main:'none'):"none")
          })}
        >
          {/* <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}> */}
          <MDBox mr={2}>
        <MDAvatar src={message.image} alt="something here" shadow="md" />  
      </MDBox>
      <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
        <MDTypography variant="button" fontWeight="medium">
          {message.fullName}
        </MDTypography>
        <MDTypography variant="caption" color="text">
          {  (currentConversationWith||isMobile)?truncateString(message.message,40):truncateString(message.message,200)}
        </MDTypography>
      </MDBox>
          {/* {message.description} */}
        </MDTypography>
      </MDBox>
    );
  });
  return (<>
    <DashboardLayout>
      <DashboardNavbar2 />
      <MDBox mt={4}>
       
       <Grid container spacing={3}>
      {(!(currentConversationWith&&isMobile))&&
          <Grid item xs={12} lg={currentConversationWith?3:12}>

          <Card  
      sx={{
        maxHeight: (isMobile?'700px':'700px'), overflowY: 'auto',
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
      }}
    >
      <MDBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >
        {renderSidenavItems}
      </MDBox>
    </Card>
    </Grid>}
         {(currentConversationWith)&&
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>

                <Card id="profile">
                  <MDBox p={2}>
                    <Grid container spacing={3} alignItems="center">
                     { (isMobile)&&<Grid item>
                        <ArrowBackIosNewSharp onClick={()=>{setCurrentConversationWith(null)}} alt="profile-image" size="xl" shadow="sm" />
                      </Grid>}
                      <Grid item>
                        <MDAvatar src={currentConversationWith.image} alt="profile-image" size="xl" shadow="sm" />
                      </Grid>
                      <Grid item>
                        <MDBox height="100%" mt={0.5} lineHeight={1}>
                          <MDTypography variant="h5" fontWeight="medium">
                            {currentConversationWith.fullName}
                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="medium">
                          {currentConversationWith.email}
                          </MDTypography>
                          <MDTypography variant="body2" color="text" fontWeight="medium">
                          {currentConversationWith.phone}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
                        <MDBox
                          display="flex"
                          justifyContent={{ md: "flex-end" }}
                          alignItems="center"
                          lineHeight={1}
                        >
                          <MDTypography variant="caption" fontWeight="regular">
                            {/* Switch to {visible ? "invisible" : "visible"} */}
                           {currentConversationWith.createdAt}
                          </MDTypography>
                          {/* <MDBox ml={1}>
                            <Switch checked={visible} onChange={handleSetVisible} />
                          </MDBox> */}
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Card>
                </Grid>
                
                <Grid item xs={12}>


                <Card id="2fa" sx={{ overflow: "visible" }}>
                  <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                    <MDTypography variant="h5">Message</MDTypography>
                    <MDBadge variant="contained" color="success" badgeContent={currentConversationWith.createdAt} container />
                  </MDBox>
                  <MDBox p={3}>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems={{ xs: "flex-start", sm: "center" }}
                      flexDirection={{ xs: "column", sm: "row" }}
                    >
                     {(currentConversationWith.message)&& <MDTypography variant="body2" color="text">
                        {currentConversationWith.message}
                      </MDTypography>}
                      {/* <MDBox
                        display="flex"
                        alignItems={{ xs: "flex-start", sm: "center" }}
                        flexDirection={{ xs: "column", sm: "row" }}
                      >
                        <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            Not Configured
                          </MDTypography>
                        </MDBox>
                        <MDButton variant="outlined" color="dark" size="small">
                          set up
                        </MDButton>
                      </MDBox> */}
                    </MDBox>
                  </MDBox>
                </Card>                </Grid>
                {forceRerender}
              </Grid>
            </MDBox>
          </Grid>}
        </Grid>
      </MDBox>
    </DashboardLayout>
    {forceRerender}
 </> );
}

export default ContactUsMessages;
