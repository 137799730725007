/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/ecommerce/products/new-product/components/FormField";
import { employmentTypes } from "Api-master-datas/masterDatas";
import { Checkbox, TextField } from "@mui/material";
import { useCurrentUser } from "Api-contexts/currentUserContext";

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function BasicInfo(props) {  
  const [forceRerender, setForceRerender] = useState(false);
  const [sectionValues, setSectionValues] = useState(props.sectionValues);
  const [currentData, updateContext] = useCurrentUser();
  const {myBusinessEmploymentAgency,currentUser ,educationLevels,jobs,jobCategories}  = currentData;  
 
  useEffect(() => {
    console.log("props.sectionValues", jobs)
    props.validateSection(props.sectionValues)
  }, []);

  const changeInputValue = (field,value) =>
  {
    let sectionValuesD=sectionValues
    sectionValuesD[field].value=value
    setSectionValues(sectionValuesD)
    setForceRerender(!forceRerender)
    props.changeSectionValues("basicInfoSectionValues",sectionValuesD)
  }

  return (<>
  {forceRerender}
    <MDBox>
      <MDTypography variant="h5">Basic Information</MDTypography>
      <MDBox mt={5}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
              Title&nbsp;&nbsp;
               {(sectionValues.title.required)&& <MDTypography variant="caption" color="text">
                  (required)
                </MDTypography>}
              </MDTypography>
            </MDBox>
            <FormField type="text" label={"Title"}
               defaultValue={sectionValues.title.value}
               onChange={(e)=>{
                  if(e){
                    changeInputValue("title",e.target.value )
                  }
                }} 
                />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <FormField type="text" label="Weight" />
          </Grid> */}
        </Grid>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Short Description&nbsp;&nbsp;
               {(sectionValues.description.required)&& <MDTypography variant="caption" color="text">
                  (required)
                </MDTypography>}
              </MDTypography>
            </MDBox>
            <MDEditor value={sectionValues.description.value}  
            //  onChange={setDescription}  
              onChange={(e)=>{
              if(e){
                  changeInputValue("description", e )
              }
            }}
             />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDBox mb={3}>
              <MDBox
              //  mb={2}
               display="inline-block">
                <MDTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  Employment Type&nbsp;&nbsp;
               {(sectionValues.employmentTypeId.required)&& <MDTypography variant="caption" color="text">
                  (required)
                </MDTypography>}
                </MDTypography>
              </MDBox>
              <Autocomplete
                defaultValue={props.getDataFromArray(
                  employmentTypes,
                    "id",
                    sectionValues.employmentTypeId.value,
                    "single"
                  )}
                 onChange={(e,val)=>{
                  if(val){
                    if(val.id){  
                      changeInputValue("employmentTypeId", val.id)
                    }
                  }
                }}  
                getOptionLabel={(option) => option.title}
                options={employmentTypes}
                renderInput={(params) => <MDInput {...params}
                //  variant="standard"
                  />}
              />
            </MDBox>
            </Grid>
          <Grid item xs={12} sm={6}>
            {/* <MDBox mb={2} display="inline-block"> */}
              <MDTypography
                component="label"
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
              >
                Number Of Required Employees&nbsp;&nbsp;
               {(sectionValues.requiredNumberOfEmployees.required)&& <MDTypography variant="caption" color="text">
                  (required)
                </MDTypography>}
              </MDTypography> 
            {/* </MDBox> */}
            
            <FormField type="number"  defaultValue={sectionValues.requiredNumberOfEmployees.value}
                 onChange={(e)=>{
                  if(e){
                    changeInputValue("requiredNumberOfEmployees",e.target.value )
                  }
                }}
                 label={"Employees"  }/> 
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <MDBox mb={3}>
              <MDBox display="inline-block">
                <MDTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  Employee Gender&nbsp;&nbsp;
               {(sectionValues.applicantGender.required)&& <MDTypography variant="caption" color="text">
                  (required)
                </MDTypography>}
                </MDTypography>
              </MDBox>
              <Autocomplete
                defaultValue={ sectionValues.applicantGender.value}
                 onChange={(e,val)=>{
                  if(val){ 
                      changeInputValue("applicantGender", val)
                    }
                }}  
                getOptionLabel={(option) => option}
                options={["any","male","female",]}
                renderInput={(params) => <MDInput {...params}  />}
              />
            </MDBox>
            </Grid>
            
          <Grid item xs={12} sm={6}>
            {/* <MDBox mb={2} display="inline-block"> */}
              <MDTypography
                component="label"
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
              >
                Dead Line&nbsp;&nbsp;
               {(sectionValues.deadLineDateTime.required)&& <MDTypography variant="caption" color="text">
                  (required)
                </MDTypography>}
              </MDTypography>
            {/* </MDBox> */}
            
            <FormField type="date"  variant="outlined"
             defaultValue={sectionValues.deadLineDateTime.value}
                 onChange={(e)=>{
                  if(e){
                    changeInputValue("deadLineDateTime",e.target.value )
                  }
                }} /> 
          </Grid>
          <Grid item xs={12} sm={12}>
            {/* <MDBox mb={2} display="inline-block"> */}
              <MDTypography
                component="label"
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
              >
                Required Field of Studies&nbsp;&nbsp;
               {(sectionValues.deadLineDateTime.required)&& <MDTypography variant="caption" color="text">
                  (required)
                </MDTypography>}
              </MDTypography>
            {/* </MDBox> */}
            
            {/* <FormField type="date"  variant="outlined"
             defaultValue={sectionValues.deadLineDateTime.value}
                 onChange={(e)=>{
                  if(e){
                    changeInputValue("deadLineDateTime",e.target.value )
                  }
                }} />  */}
                 {jobs&& <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={jobs}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.title}
                    groupBy={(option) => option.jobCategoryId}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.title}
                      </li>
                    )}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Checkboxes" placeholder="Add Field" />
                    )}
                  />}
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
 </> );
}

export default BasicInfo;
