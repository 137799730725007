/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
// Images
import {
  cities,
  subCities,
  specificAreas,
} from "Api-master-datas/masterDatas.js";

// NewProduct page components
import FormField from "layouts/ecommerce/products/new-product/components/FormField";
import { useEffect, useState } from "react";
import { jobLocationTypes } from "Api-master-datas/masterDatas";

function Location(props) {  
  const [forceRerender, setForceRerender] = useState(false);
  const [sectionValues, setSectionValues] = useState(props.sectionValues);
   
  useEffect(() => {
    console.log("props.sectionValues", props.sectionValues)
    props.validateSection(props.sectionValues)
  }, []);

  const changeInputValue = (field,value) =>
  {
    let sectionValuesD=sectionValues
    sectionValuesD[field].value=value
    setSectionValues(sectionValuesD)
    setForceRerender(!forceRerender)
    props.changeSectionValues("locationSectionValues",sectionValuesD)
  }

  
  const getDataFromArray = (dataArray, searchField, searchValue, returnRows, returnField) => {
    let res = {};
    console.log("dataArray   ", dataArray,searchField, searchValue, returnRows, returnField);
    try {
      if (dataArray && searchField && searchValue) {
        if (dataArray.length > 0) {
          let filteredLevels = dataArray.filter((level) => level[searchField] == searchValue);
          if (filteredLevels.length) {
            res = filteredLevels;
            if (returnRows == "single") {
              let row = filteredLevels[0];
              res = row;
              if (returnField) {
                let rowField = row[returnField];
                res = rowField;
              }
            }
          }
        }
      }
      console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
    return res;
  };


  return (<>
  {forceRerender}
   {(sectionValues) && <MDBox>


{/* //
      //location
      jobLocationType: DataTypes.DOUBLE,//inPerson//remote//ontheroad
      country: DataTypes.TEXT,
      cityId: DataTypes.DOUBLE,
      city: DataTypes.TEXT,
      subCity: DataTypes.TEXT,
      specificArea: DataTypes.TEXT,
      specificAreaId: DataTypes.DOUBLE,  */}

      <MDTypography variant="h5">Location</MDTypography>
      <MDBox mb={5}>
              <MDBox mb={2} display="inline-block">
                <MDTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  Job Location Type  &nbsp;&nbsp;
               {(sectionValues.jobLocationTypeId.required)&& <MDTypography variant="caption" color="text">
                  (required)
                </MDTypography>}
                </MDTypography>
              </MDBox>
              <Autocomplete 
                
                defaultValue={props.getDataFromArray(
                  jobLocationTypes,
                    "id",
                    sectionValues.jobLocationTypeId.value,
                    "single"
                  )}
                
                onChange={(e,val)=>{
                  if(val.id){  
                    changeInputValue("jobLocationTypeId", val.id)
                  }
                }}
                getOptionLabel={(option) => option.title}
                options={jobLocationTypes}
                renderInput={(params) => <MDInput {...params} />}
              />
       </MDBox>
{(sectionValues.jobLocationTypeId.value!=2)&&
      <MDBox mt={3}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} sm={3}>
            <FormField type="text" label="Salary" placeholder="99.00" />
          </Grid> */}
          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
            {/* <Autocomplete
              defaultValue="ETB"
              options={cities}
              renderInput={(params) => <MDInput {...params} variant="standard" />}
            /> */}
            
            <MDBox mb={2} display="inline-block">
                <MDTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  City  &nbsp;&nbsp;
               {(sectionValues.city.required)&& <MDTypography variant="caption" color="text">
                  (required)
                </MDTypography>}
                </MDTypography>
              </MDBox>

          {(cities)&& <Autocomplete
                  fullWidth
                  id="optionsListComp"
                  // sx={{ width: 400 }}
                  groupBy={(option) =>  option["REGION"] } 
                  onChange={(e, val) => {
                    if (val) {
                    if(val.TOWN_NAME){  
                      changeInputValue("city", val.TOWN_NAME)
                    }
                    }
                  }}
                  value={props.getDataFromArray(
                    cities,
                    "TOWN_NAME",
                    sectionValues.city.value,
                    "single"
                  )}
                  // "TOWN_NAME",
                  // "REGION"
                  options={cities} 
                  // defaultValue={options?(optionsSelectedField? value :{}):{}}
                  // defaultValue="ETB"
                  getOptionLabel={(option) =>  option["TOWN_NAME"] 
                  }
                  renderInput={(params) => (
                    <MDInput {...params}  id="optionsList"/>
                  )}
                />}



          </Grid>
         { (sectionValues.city.value=="Addis Ababa")&&<>
          <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
            {/* <Autocomplete
              defaultValue="ETB"
              options={cities}
              renderInput={(params) => <MDInput {...params} variant="standard" />}
            /> */}

<MDBox mb={2} display="inline-block">
                <MDTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  SubCity &nbsp;&nbsp;
               {(sectionValues.subCity.required)&& <MDTypography variant="caption" color="text">
                  (required)
                </MDTypography>}
                </MDTypography>
              </MDBox>

          {(subCities)&& <Autocomplete
                  fullWidth
                  id="optionsListComp"
                  // sx={{ width: 400 }}
                  // groupBy={(option) =>  option["REGION"] } 
                  value={getDataFromArray(
                    subCities,
                    "title",
                    sectionValues.subCity.value,
                    "single"
                  )}
                  onChange={(e, val) => {
                    if (val) {
                    if(val.title){  
                      changeInputValue("subCity", val.title)
                    }
                    }
                  }}
                  
                  // "TOWN_NAME",
                  // "REGION"
                  options={subCities} 
                  // defaultValue={options?(optionsSelectedField? value :{}):{}}
                  // defaultValue="ETB"
                  getOptionLabel={(option) =>  option["title"] 
                  }
                  renderInput={(params) => (
                    <MDInput {...params}   id="optionsList"/>
                  )}
                />}



          </Grid>
          <Grid item xs={12} sm={6} sx={{ mt: 2 }}>  
        <MDBox mb={2} display="inline-block">
                <MDTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  Specific Area&nbsp;&nbsp;
               {(sectionValues.specificArea.required)&& <MDTypography variant="caption" color="text">
                  (required)
                </MDTypography>}
                </MDTypography>
              </MDBox>
              {(specificAreas)&& <Autocomplete
                  fullWidth
                  id="optionsListComp"
                  // sx={{ width: 400 }}
                  // groupBy={(option) =>  option["REGION"] } 
                  value={getDataFromArray(
                    specificAreas,
                    "title",
                    sectionValues.specificArea.value,
                    "single"
                  )}
                  onChange={(e, val) => {
                    if (val) {
                    if(val.title){  
                      changeInputValue("specificArea", val.title)
                    }
                    }
                  }}
                  
                  // "TOWN_NAME",
                  // "REGION"
                  options={specificAreas} 
                  // defaultValue={options?(optionsSelectedField? value :{}):{}}
                  // defaultValue="ETB"
                  getOptionLabel={(option) =>  option["title"] 
                  }
                  renderInput={(params) => (
                    <MDInput {...params}  id="optionsList"/>
                  )}
                />}
            {/* <FormField type="text" label="SKU" placeholder="71283476591" /> */}
          </Grid>
          </>}
        </Grid>
      </MDBox>}
      {/* <MDBox mt={1}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox my={2} display="inline-block">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Tags
              </MDTypography>
            </MDBox>
            <Autocomplete
              multiple
              defaultValue={["In Stock", "Out of Stock"]}
              options={["Black Friday", "Expired", "Out of Stock", "In Stock", "Sale"]}
              renderInput={(params) => <MDInput {...params} variant="standard" />}
            />
          </Grid>
        </Grid>
      </MDBox> */}
    </MDBox>}
    </>
  );
}

export default Location;
