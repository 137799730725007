/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { AgricultureRounded, AssignmentReturn, Compare, CompareArrows, Refresh } from "@mui/icons-material";
import { Icon } from "@mui/material";
import Card from "@mui/material/Card";
import { HttpService } from "Api-Services/httpService";
import { useCurrentUser } from "Api-contexts/currentUserContext";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";

// Billing page components
import Bill from "layouts/pages/account/billing/components/Bill";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function  BillConfirmationRequests(props) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
    const [currentData, updateContext] = useCurrentUser();
    const { employmentAgencyPaymentConfirmationRequests,employmentAgencyPaymentAccounts, paymentMethods, myBusinessEmploymentAgency, myAgencies, jobs } =
      currentData;

    const [loading , setLoading] = React.useState(false)
    const [forceRerender , setForceRerender] = React.useState(false)
    const [newPaymentMethodId , setNewPaymentMethodId] = React.useState("")
    const [newPaymentAccountName , setNewPaymentAccountName] = React.useState("")
    const [newPaymentAccountNumber , setNewPaymentAccountNumber] = React.useState("")
    const [selectedPaymentAccount , setSelectedPaymentAccount] = React.useState(null)
    const [openedDialog , setOpenedDialog] = React.useState(""); //setJobSeeker

    useEffect(() => {
      console.log("employmentAgencyPaymentAccounts", employmentAgencyPaymentAccounts);
      // setForceRerender(!forceRerender);
    }, [employmentAgencyPaymentAccounts]);

    
  const getDataFromArray = (dataArray, searchField, searchValue, returnRows, returnField) => {
    let res = {};
    // console.log("dataArray   ", dataArray,searchField, searchValue, returnRows, returnField);
    try {
      if (dataArray && searchField && searchValue) {
        if (dataArray.length > 0) {
          let filteredLevels = dataArray.filter((level) => level[searchField] == searchValue);
          if (filteredLevels.length) {
            res = filteredLevels;
            if (returnRows == "single") {
              let row = filteredLevels[0];
              res = row;
              if (returnField) {
                let rowField = row[returnField];
                res = rowField;
              }
            }
          }
        }
      }
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
    return res;
  };

    const paymentConfirmationRequests=()=>{
      let employmentAgencyPaymentConfirmationRequestsD=[]
if(employmentAgencyPaymentConfirmationRequests){
  if(employmentAgencyPaymentConfirmationRequests.length){
    employmentAgencyPaymentConfirmationRequestsD=employmentAgencyPaymentConfirmationRequests 
}}

    return (
      <>

<Card id="delete-account">
      <MDBox pt={3} px={2}   
            display="flex"
            justifyContent="space-between"
            alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          {/* Billing Information */}
          Bill Confirmation Requests
        </MDTypography>
            {props.view ? (
              <MDButton
                variant="gradient"
                color="dark"
                component={Link}
                to={"/pages/account/payment/bill-confirmation-requests"}
              >
                <Icon sx={{ fontWeight: "bold" }}>edit</Icon>
                &nbsp;Manage Billings
              </MDButton>
            ) : (

              <>
              <MDButton
                  variant="outlined"
                  color="dark"
                  size={"small"}
                  // component={Link}
                  onClick={()=>{
                    updateContext("employmentAgencyPaymentConfirmationRequests"); 
                  }}
                >
                  <Refresh sx={{ fontWeight: "bold" }}></Refresh>
                  &nbsp;Refresh
                </MDButton>
              <MDButton
                variant="gradient"
                color="dark"
                onClick={() => {
                  setOpenedDialog("addNewMethod");
                  
                  setSelectedPaymentAccount(null)
                  setNewPaymentMethodId("")
                  setNewPaymentAccountName("")
                  setNewPaymentAccountNumber("")
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                &nbsp;add new requests
              </MDButton>
              </>
            )}
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        {/* <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <Bill
            name="oliver liam"
            company="viking burrito"
            email="oliver@burrito.com"
            vat="FRB1235476"
          /> 
        </MDBox>  */}
           {(employmentAgencyPaymentConfirmationRequestsD?(employmentAgencyPaymentConfirmationRequestsD.length?true:false):false)&&
            <>
             {employmentAgencyPaymentConfirmationRequestsD.map((paymentAccount)=>{
                let pMethod=getDataFromArray(
                  paymentMethods,
                  "id",
                  paymentAccount.paymentMethodId,
                  "single"
                )
                // paymentMethodId:  DataTypes.DOUBLE ,
                // employmentAgencyId:  DataTypes.DOUBLE ,
                // jobSeekerId:  DataTypes.DOUBLE ,
                // employmentAgencyPaymentAccountId:  DataTypes.DOUBLE ,
                // modifiedBy:  DataTypes.DOUBLE ,
                // createdBy:  DataTypes.DOUBLE ,


             return <>
             <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {/* <Bill
            name={paymentAccount.transeredByFullName}
            company="viking burrito"
            email="oliver@burrito.com"
            vat="FRB1235476"
          />  */}
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      // mb={noGutter ? 0 : 1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
        >
          <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
          {paymentAccount.transeredByFullName}
          </MDTypography>

          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            {/* <MDBox mr={1}>
              <MDButton variant="text" color="error">
                <Icon>delete</Icon>
              </MDButton>
            </MDBox> */}
            <MDButton variant="text" color={darkMode ? "error" : "error"}>
              <Compare/>&nbsp;Compare and take action
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Payment Method:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
            {pMethod.name}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
          Transered To :&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
            {paymentAccount.transeredToFullName}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
          Transered To Account Number:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
            {paymentAccount.transeredToAccountNumber}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
          Transaction Date:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
            {paymentAccount.transactionDate}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
          Transaction Id:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
            {paymentAccount.transactionId}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
          Request Created Date:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
            {paymentAccount.createdAt}
            </MDTypography>
          </MDTypography>
        </MDBox> 
        {/* <MDTypography variant="caption" color="text">
        Request Created Date:&nbsp;&nbsp;&nbsp;
          <MDTypography variant="caption" fontWeight="medium">
          {paymentAccount.createdAt}
          </MDTypography>
        </MDTypography> */}
      </MDBox>
    </MDBox>




        </MDBox> 
             
             {/* <Grid item xs={12} md={6}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  sx={{
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDBox
                    component="img"
                    src={pMethod.logo}
                    alt="master card"
                    width="10%"
                    mr={2}
                  />
                  <MDTypography variant="h6" fontWeight="medium">
                   
                     {paymentAccount.accountOwnerNumber}
                  </MDTypography>
                  <MDBox
                    ml="auto"
                    lineHeight={0}
                    color={darkMode ? "white" : "dark"}
                  >
                    <Tooltip title="Edit Method" placement="top">
                      <Icon sx={{ cursor: "pointer" ,mx:2}} fontSize="small"
                      onClick={()=>{
                        setOpenedDialog("editMethod")
                        setSelectedPaymentAccount(paymentAccount)
                        setNewPaymentMethodId(paymentAccount.paymentMethodId)
                        setNewPaymentAccountName(paymentAccount.accountOwnerFullName)
                        setNewPaymentAccountNumber(paymentAccount.accountOwnerNumber)
                      }}>
                        edit
                      </Icon>
                    </Tooltip>
                    <Tooltip title="Delet Method" placement="top">
                      <Icon sx={{ cursor: "pointer",mx:2 }} fontSize="small"
                      onClick={()=>{
                        deletePaymentAccount(paymentAccount.id) 
                      }}>
                        delete
                      </Icon>
                    </Tooltip>
                  </MDBox>
                </MDBox>
              </Grid> */}
            </> 
            } )}
            </>}
          </MDBox>
    </Card>



        {/* <Card id="delete-account">
          <MDBox
            pt={2}
            px={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDTypography variant="h6" fontWeight="medium">
              Payment Method
            </MDTypography>
            {props.view ? (
              <MDButton
                variant="gradient"
                color="dark"
                component={Link}
                to={"/Account/Settings/Payment-Method"}
              >
                <Icon sx={{ fontWeight: "bold" }}>edit</Icon>
                &nbsp;Manage Methods
              </MDButton>
            ) : (
              <MDButton
                variant="gradient"
                color="dark"
                onClick={() => {
                  setOpenedDialog("addNewMethod");
                  
                  setSelectedPaymentAccount(null)
                  setNewPaymentMethodId("")
                  setNewPaymentAccountName("")
                  setNewPaymentAccountNumber("")
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                &nbsp;add new method
              </MDButton>
            )}
          </MDBox>
          <MDBox p={2}>
           {(employmentAgencyPaymentAccounts?(employmentAgencyPaymentAccounts.length?true:false):false)&& <Grid container spacing={3}>
             {employmentAgencyPaymentAccounts.map((paymentAccount)=>{
                let pMethod=getDataFromArray(
                  paymentMethods,
                  "id",
                  paymentAccount.paymentMethodId,
                  "single"
                )
             return <Grid item xs={12} md={6}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  sx={{
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDBox
                    component="img"
                    src={pMethod.logo}
                    alt="master card"
                    width="10%"
                    mr={2}
                  />
                  <MDTypography variant="h6" fontWeight="medium">
                   
                     {paymentAccount.accountOwnerNumber}
                  </MDTypography>
                  <MDBox
                    ml="auto"
                    lineHeight={0}
                    color={darkMode ? "white" : "dark"}
                  >
                    <Tooltip title="Edit Method" placement="top">
                      <Icon sx={{ cursor: "pointer" ,mx:2}} fontSize="small"
                      onClick={()=>{
                        setOpenedDialog("editMethod")
                        setSelectedPaymentAccount(paymentAccount)
                        setNewPaymentMethodId(paymentAccount.paymentMethodId)
                        setNewPaymentAccountName(paymentAccount.accountOwnerFullName)
                        setNewPaymentAccountNumber(paymentAccount.accountOwnerNumber)
                      }}>
                        edit
                      </Icon>
                    </Tooltip>
                    <Tooltip title="Delet Method" placement="top">
                      <Icon sx={{ cursor: "pointer",mx:2 }} fontSize="small"
                      onClick={()=>{
                        deletePaymentAccount(paymentAccount.id) 
                      }}>
                        delete
                      </Icon>
                    </Tooltip>
                  </MDBox>
                </MDBox>
              </Grid>} )}
            </Grid>}
          </MDBox>
        </Card> */}
      </>
    );
    }
  
  const postDocuments = async (
    documentName,
    relativePath,
    data,
    additionalData
  ) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    // setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(
      relativePath,
      data,
      additionalData || {},
      ""
    );
    // console.log(documentName, " data: ", documentsResponse   );
    // setLoading(false);

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    } else {
      responseD.record = documentsResponse.data;
    } 
    return responseD; 
  };


  const getDocumentsAll = async (
    documentName,
    relativePath,
    additionalData
  ) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    ////////// UPDATED/////////////
    documentsResponse = await HttpService.getService(
      relativePath,
      additionalData ? additionalData : {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }

    if (documentsResponse.success) {
      responseD.success = true;
    }
    setForceRerender(!forceRerender);
    return responseD;
  };

  const addNewPaymentAccount= async () => {
    console.log("data",selectedPaymentAccount );
    // return
    try {
      if(myBusinessEmploymentAgency){
        
      let data = { 
        employmentAgencyId: myBusinessEmploymentAgency.id, 
        paymentMethodId: newPaymentMethodId ,
        accountOwnerFullName:newPaymentAccountName ,
        accountOwnerNumber :newPaymentAccountNumber , 
      };
      let relativePath = "/api/employmentagencypaymentaccount/new";
      if(openedDialog=="editMethod"){
         data.id=selectedPaymentAccount.id
         relativePath = "/api/employmentagencypaymentaccount/edit";
      }
      let refreshVariable = "employmentAgencyPaymentAccounts";
     
 
    console.log("data",data );
        if (data) {
          setLoading(true);
          let changeUserProfileRes = await postDocuments(
            "newemploymentAgencyPaymentAccount",
            relativePath,
            data
          );
          //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});
          setLoading(false);

          if (changeUserProfileRes.success) {
            setOpenedDialog("");
            updateContext(refreshVariable);
          }
        } 
      }
    } catch (error) {
    console.log("data error",error );

    }
  };

  const deletePaymentAccount= async (id) => {
    // console.log("data",selectedPaymentAccount );
    // return
    try {
      if(myBusinessEmploymentAgency){
         
      let relativePath = "/api/employmentagencypaymentaccount/delete/"+id;
       
      let refreshVariable = "employmentAgencyPaymentAccounts";
     
  
          setLoading(true);
          let changeUserProfileRes = await getDocumentsAll(
            "newemploymentAgencyPaymentAccount",
            relativePath,
            {}
          );
          //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});
          setLoading(false);

          if (changeUserProfileRes.success) {
            setOpenedDialog("");
            updateContext(refreshVariable);
          }
      
      }
    } catch (error) {
    console.log("data error",error );

    }
  };

    
  return (<> 


      <>
      {props.view ? (
        <>{paymentConfirmationRequests()} </>
      ) : (
        <>
          <BaseLayout stickyNavbar>
          {paymentConfirmationRequests()}
          </BaseLayout>

          {/* {dialogs()} */}
          {forceRerender}
        </>
      )}
    </>


  </>
  );
}

export default BillConfirmationRequests;
