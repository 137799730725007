/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React from "react";
import { HttpService } from "Api-Services/httpService";

function MyEmploymentAgencies({ title, profiles, shadow }) {
  const [currentBusiness, setCurrentBusiness] = React.useState(null);

  const [currentContextData, updateContext] = useCurrentUser();
  const { currentWebsite,myBusinesses,myBusinessEmploymentAgencies } =
    currentContextData;

  /////////////////
  React.useEffect(() => {
    (async () => {
      // setForceRerender(!forceRerender);
      console.log("myBusinesses", myBusinesses);
      console.log("currentWebsite", currentWebsite.website);
      if(currentWebsite){
        if(currentWebsite.website){
          setCurrentBusiness(currentWebsite.website)
        }
      }
    })();
  }, [currentWebsite,myBusinesses]);

  const postDocuments = async (
    documentName,
    relativePath,
    data,
    additionalData
  ) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    // setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(
      relativePath,
      data,
      additionalData || {},
      ""
    );
    // console.log(documentName, " data: ", documentsResponse   );
    // setLoading(false);

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
      let record = responseD.record;

      //write codtions down here//
    } else {
      responseD.record = documentsResponse.data;
    }

    // console.log(documentName, " responseD: ", responseD);
    // setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  const getDocumentsAll = async (
    documentName,
    relativePath,
    additionalData
  ) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    ////////// UPDATED/////////////
    documentsResponse = await HttpService.getService(
      relativePath,
      additionalData ? additionalData : {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }

    if (documentsResponse.success) {
      responseD.success = true;
    }
    // setForceRerender(!forceRerender);
    return responseD;
  };

  const loginToWebsite = async (websiteData) => {
    let data;
    let relativePath = "/api/authorization/logintoemploymentagency";
    if(currentBusiness==null) return
    if (websiteData.id) {
      // new ||new
      data = {
        employmentAgencyId: websiteData.id,
        websiteId: currentBusiness.id,

        // "userId":websiteData,
        // "command":"new"
        callerSite: "platformadminportal",
      };
    }

    // setPopUpFields({type:"progress",title:"Entering to Business ",subTitle:"",contents:[]})
    // setOpenPopUp(true)
    let addUserToWebsiteRes = await postDocuments(
      "loginToEmploymentAgency",
      relativePath,
      data
    );
    // setOpenPopUp(false)
    if (addUserToWebsiteRes.success) {
      localStorage.clear();
      let path = "/";
      window.location.assign(path);
    } else {
      // setPopUpFields({type:"information",title:"info ",subTitle:addUserToWebsiteRes.message,contents:[]})
      // setOpenPopUp(true)
    }
  };

  const renderProfiles = myBusinesses.map((agency) => (
    <MDBox
      key={name}
      component="li"
      display="flex"
      alignItems="center"
      py={1}
      mb={1}
    >
      <MDBox mr={2}>
        <MDAvatar src={agency.logo} alt="something here" shadow="md" />
      </MDBox>
      <MDBox
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
      >
        <MDTypography variant="button" fontWeight="medium">
          {agency.fullName}
        </MDTypography>
        {/* <MDTypography variant="caption" color="text">
          {"description"}
        </MDTypography> */}
      </MDBox>
      <MDBox ml="auto">
        <MDButton
          // component={Link}
          // to={"action.route"}
          onClick={()=>{loginToWebsite(agency)}}
          variant="contained"
          size="small"
          color="info"
        >
          {"Enter"}
        </MDButton>
      </MDBox>
    </MDBox>
  ));

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={2} px={2}>
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {"My Employment Agencies "}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the MyEmploymentAgencies
MyEmploymentAgencies.defaultProps = {
  shadow: true,
};

// Typechecking props for the MyEmploymentAgencies
MyEmploymentAgencies.propTypes = {
  title: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  shadow: PropTypes.bool,
};

export default MyEmploymentAgencies;
