/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Settings page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import Sidenav from "layouts/pages/account/settings/components/Sidenav";
import Header from "layouts/pages/account/settings/components/Header";
import BasicInfo from "layouts/pages/account/settings/components/BasicInfo";
import ChangePassword from "layouts/pages/account/settings/components/ChangePassword";
import Authentication from "layouts/pages/account/settings/components/Authentication";
import Accounts from "layouts/pages/account/settings/components/Accounts";
import Notifications from "layouts/pages/account/settings/components/Notifications";
import Sessions from "layouts/pages/account/settings/components/Sessions";
import DeleteAccount from "layouts/pages/account/settings/components/DeleteAccount";

import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";

// @mui material components
// import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";

import kal from "assets/images/kal-visuals-square.jpg";
import marie from "assets/images/marie.jpg";
import ivana from "assets/images/ivana-square.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import { Avatar, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import React, { useState } from "react";
// import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
// import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import burceMars from "assets/images/bruce-mars.jpg";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import DashboardNavbar2 from "examples/Navbars/DashboardNavbar2";
function Settings() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [visible, setVisible] = useState(true);
  const [forceRerender,setForceRerender] = useState(true);

  const [currentConversationWith, setCurrentConversationWith] = useState(null);

  const handleSetVisible = () => setVisible(!visible);

  const messagesList = [
    {
      image: kal,
      name: "Sophie B.",
      description: "Hi! I need more information...",
      action: {
        type: "internal",
        route: "/pages/profile/profile-overview",
        color: "info",
        label: "reply",
      },
    },
    {
      image: marie,
      name: "Anne Marie",
      description: "Awesome work, can you..",
      action: {
        type: "internal",
        route: "/pages/profile/profile-overview",
        color: "info",
        label: "reply",
      },
    },
    {
      image: ivana,
      name: "Ivanna",
      description: "About files I can..",
      action: {
        type: "internal",
        route: "/pages/profile/profile-overview",
        color: "info",
        label: "reply",
      },
    },
    {
      image: team4,
      name: "Peterson",
      description: "Have a great afternoon..",
      action: {
        type: "internal",
        route: "/pages/profile/profile-overview",
        color: "info",
        label: "reply",
      },
    },
    {
      image: team3,
      name: "Nick Daniel",
      description: "Hi! I need more information..",
      action: {
        type: "internal",
        route: "/pages/profile/profile-overview",
        color: "info",
        label: "reply",
      },
    },
  ];
  
  const sidenavItems = [
    { icon: "person", label: "profile", href: "profile" },
    { icon: "receipt_long", label: "basic info", href: "basic-info" },
    { icon: "lock", label: "change password", href: "change-password" },
    { icon: "security", label: "2FA", href: "2fa" },
    { icon: "badge", label: "accounts", href: "accounts" },
    { icon: "campaign", label: "notifications", href: "notifications" },
    { icon: "settings_applications", label: "sessions", href: "sessions" },
    { icon: "delete", label: "delete account", href: "delete-account" },
  ];

  const renderSidenavItems = messagesList.map((message, key) => {
    const itemKey = `item-${key}`;

    return (
      <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <MDTypography
          component="a"
          // href={`#${href}`}
          onClick={()=>{setCurrentConversationWith(message);setForceRerender(!forceRerender)}}
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light },
            transitions,
          }) => ({
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: light.main,
            },
            backgroundColor:(currentConversationWith?((currentConversationWith.name==message.name)?light.main:'none'):"none")
          })}
        >
          {/* <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}> */}
          <MDBox mr={2}>
        <MDAvatar src={message.image} alt="something here" shadow="md" />  
      </MDBox>
      <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
        <MDTypography variant="button" fontWeight="medium">
          {message.name}
        </MDTypography>
        <MDTypography variant="caption" color="text">
          {message.description}
        </MDTypography>
      </MDBox>
          {/* {message.description} */}
        </MDTypography>
      </MDBox>
    );
  });
  return (
    <DashboardLayout>
      <DashboardNavbar2 />
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>

          <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
      }}
    >
      <MDBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >
        {renderSidenavItems}
      </MDBox>
    </Card>
    </Grid>
         {(currentConversationWith)&&
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>

                <Card id="profile">
                  <MDBox p={2}>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item>
                        <MDAvatar src={currentConversationWith.image} alt="profile-image" size="xl" shadow="sm" />
                      </Grid>
                      <Grid item>
                        <MDBox height="100%" mt={0.5} lineHeight={1}>
                          <MDTypography variant="h5" fontWeight="medium">
                            {currentConversationWith.name}
                          </MDTypography>
                          {/* <MDTypography variant="button" color="text" fontWeight="medium">
                            CEO / Co-Founder
                          </MDTypography> */}
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
                        <MDBox
                          display="flex"
                          justifyContent={{ md: "flex-end" }}
                          alignItems="center"
                          lineHeight={1}
                        >
                          <MDTypography variant="caption" fontWeight="regular">
                            Switch to {visible ? "invisible" : "visible"}
                          </MDTypography>
                          <MDBox ml={1}>
                            <Switch checked={visible} onChange={handleSetVisible} />
                          </MDBox>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Card>
                </Grid>
                
                <Grid item xs={12}>
                  <Authentication />
                </Grid>
                {forceRerender}
              </Grid>
            </MDBox>
          </Grid>}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Settings;
