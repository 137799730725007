/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Settings page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import Sidenav from "layouts/pages/account/settings/components/Sidenav";
import Header from "layouts/pages/account/settings/components/Header";
import BasicInfo from "layouts/pages/account/settings/components/BasicInfo";
import ChangePassword from "layouts/pages/account/settings/components/ChangePassword";
import Authentication from "layouts/pages/account/settings/components/Authentication";
import Accounts from "layouts/pages/account/settings/components/Accounts";
import Notifications from "layouts/pages/account/settings/components/Notifications";
import Sessions from "layouts/pages/account/settings/components/Sessions";
import DeleteAccount from "layouts/pages/account/settings/components/DeleteAccount";

import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";

// @mui material components
// import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";

import kal from "assets/images/kal-visuals-square.jpg";
import marie from "assets/images/marie.jpg";
import ivana from "assets/images/ivana-square.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import { Avatar, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import React, { useState } from "react";
// import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
// import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import burceMars from "assets/images/bruce-mars.jpg";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import MDBadge from "components/MDBadge";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import PageLayout from "examples/LayoutContainers/PageLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Add, ArrowBackIosNewSharp, Delete, PlayArrow, Refresh, Stop, ViewAgenda, Work } from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import DashboardNavbar2 from "examples/Navbars/DashboardNavbar2";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Tooltip } from "@mui/material";
import VacancyFewDetail from "./vacancy-detail/vacancyFewDetail";
import { HttpService } from "Api-Services/httpService";
import { toast } from "react-toastify";
// import { Navigate } from "react-router-dom";
function JobVacancies() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [visible, setVisible] = useState(true);
  const [forceRerender,setForceRerender] = useState(true);

  const [currentContextData, updateContext  ] = useCurrentUser();  
  const {websiteUsers,employeeHiringRequests,myJobVacancyPosts, agencyJobVacancies,employmentAgencyJobSeekers,websiteUserTypes}  = currentContextData;  
 
  const [currentConversationWith, setCurrentConversationWith] = useState(null);

  const handleSetVisible = () => setVisible(!visible);
 
  const navigate = useNavigate();
  React.useEffect(() => {
    (async () => { 
      setForceRerender(!forceRerender)
    // console.log(" agencyJobVacancies", agencyJobVacancies)

    })() 
  }, [ myJobVacancyPosts]);
  
   
  const updateInformation = async (dataD, dataParentName, command) => {
    // const updateUserInformation= async(dataDDD,parentData,dataParentName,command)=>{
    // console.log("updateUserInformation",dataDDD,dataParentName)
    // let dataD = dataDDD;
    try { 
      let relativePath;
      let contextVariableName = "agencyJobVacancies";

      if (dataParentName == "agencyJobVacancies") { 
        if(command=="update"){
         relativePath = "/api/employmentjobvacancy/edit";
        }   
      }
      if (relativePath&&(dataD?(dataD.id?true:false):false)) { 
        let toastId = toast.loading("Updating data...", {
          position: toast.POSITION.BOTTOM_LEFT,
        });

        let changeUserProfileRes = await postDocuments(
          "changeUserInfo",
          relativePath,
          dataD
        );
        toast.update(toastId, {
          render: changeUserProfileRes.message,
          type: changeUserProfileRes.success ? "success" : "warn",
          isLoading: false,
          autoClose: 5000,
        });

        if (changeUserProfileRes.success) {
          // setExpanded2(false);
          // setExpanded(false);
          updateContext(contextVariableName);
        }
      }
    } catch (error) {}
  };


  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    // setLoading(true);

    documentsResponse = await HttpService.postService(
      relativePath,
      data,
      {},
      ""
    );
    // setLoading(false);

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    } else {
      responseD.message = documentsResponse.message;
    }

    // console.log(documentName, " responseD: ", responseD   );
    // setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };


  const truncateString = (str, num, lableName) => {
    let res = str;
    try {
      if (str) {
        if (str.length > num) {
          // let btn = (
          //   <Button
          //     variant="text"
          //     onClick={() => {
          //       setMoreText({ content: str, title: lableName });
          //       setOpenedDialog("moreText");
          //       setForceRerender(!forceRerender);
          //     }}
          //   >
          //     {"View more"}
          //   </Button>
          // );
          let txt = str.slice(0, num) + "...";
          res = (
            <>
              {txt}
               {/* {btn} */}
            </>
          );
        }
      }
    } catch (error) {
      // console.log("errrr",error)
    }
    return res;
  };

  const parseDate = (dt) => {
    let d = new Date(dt);
    let m = d.getMonth() + 1;
    let dateD = m + "/" + d.getDate() + "/" + d.getFullYear();
    return dateD;
  };

  const renderSidenavItems =  myJobVacancyPosts.map((post, key) => {
    const itemKey = `item-${key}`;
    let jobVac=<></>
    let message=post.EmploymentJobVacancy
    if(message){
      jobVac= ( 
        <List sx={{ width: '100%',  bgcolor: 'background.paper' }}>
         
        <ListItem 
                  secondaryAction={<>
                  
                    
     {message.isJobClosed?  <Tooltip title="Continue Hiring">
      <IconButton 
         onClick={()=>{
          updateInformation({isJobClosed:false,id:message.id},"agencyJobVacancies","update")
         }} 
         edge="end" aria-label="delete">
        <PlayArrow/>
      </IconButton>
    </Tooltip>: <Tooltip title="Stop Hiring">
      <IconButton 
         onClick={()=>{
          updateInformation({isJobClosed:true,id:message.id},"agencyJobVacancies","update")
         }} edge="end" aria-label="delete">
        <Stop/>
      </IconButton>
    </Tooltip> }
               </>   }>
               <ListItemButton 
          selected={(currentConversationWith?((currentConversationWith.title==message.title)?true:false):false)}  dense>
          <ListItemAvatar>
            <Avatar>
              <Work />
            </Avatar>
          </ListItemAvatar>
          <ListItemText 
         onClick={()=>{setCurrentConversationWith(message);setForceRerender(!forceRerender)}} 
         primary= {<MDTypography variant="button" fontWeight="medium">
         { message.title?  ((currentConversationWith||isMobile)?truncateString(( message.title),40):truncateString(( message.title ),100)):null}
         </MDTypography>}

         secondary={<>
          {currentConversationWith?null:<MDTypography variant="caption" color="text">
          {message.deadLineDateTime?  ((currentConversationWith||isMobile)?truncateString(("Dead Line : "+message.deadLineDateTime),40):truncateString(("Dead Line : "+message.deadLineDateTime ),200)):null}
         { message.requiredNumberOfEmployees?( (currentConversationWith||isMobile)?truncateString(( " |  Number Of Employees : "+message.requiredNumberOfEmployees),40):truncateString(( " |  Number Of Employees : "+message.requiredNumberOfEmployees),200)):null}
         { message.city?( (currentConversationWith||isMobile)?truncateString(( " |  City : "+message.city),40):truncateString(( " | City : "+message.city),200)):null}
        </MDTypography>}
        { message.isJobClosed?<MDTypography variant="subtitle2" color="warning">
         {"  Closed"} 
        </MDTypography>:<MDTypography variant="subtitle2" color="success">
          { " Running" }
        </MDTypography> }
      
      </>}/>
         </ListItemButton>
        </ListItem> 
          <Divider variant="inset" component="li" />
      </List>
    );
   }
   return jobVac
  });

  
  return (<>
    <DashboardLayout>
      <DashboardNavbar2 />
      <MDBox mt={4}>
       
            {/* <MDBox p={3} lineHeight={1}> */}
            <MDBox
            pt={2}
            pb={2}
            px={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
              <MDTypography variant="h5" fontWeight="medium">
                Job Vacancies
              </MDTypography>
              
            <MDButton
                variant="outlined"
                color="dark"
                size={"small"}
                // component={Link}
                onClick={()=>{
                  updateContext("agencyJobVacancies"); 
                }}
              >
                <Refresh sx={{ fontWeight: "bold" }}></Refresh>
                &nbsp;Refresh
              </MDButton>
            <MDButton
                // variant="outlined"
                color="dark"
                size={"small"}
                to={"/Employment/Job-Vacancies/New"}
                component={Link} 
                variant="text"
              >
                <Add sx={{ fontWeight: "bold" }}></Add>
                &nbsp;Add New Vacancy
              </MDButton>
               
              {/* <MDTypography variant="button" color="text">
              A lightweight, extendable, dependency-free javascript HTML table plugin.
            </MDTypography> */}
            </MDBox>
       <Grid container spacing={3}>
      {(!(currentConversationWith&&isMobile))&&
          <Grid item xs={12} lg={currentConversationWith?3:12}>

          <Card  
      sx={{
        maxHeight: (isMobile?'700px':'700px'), overflowY: 'auto',
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
      }}
    >  

      <MDBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >
        {renderSidenavItems}
      </MDBox> 

    </Card>
    </Grid>}
         {(currentConversationWith)&&
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>

                <Card id="profile">
                  <MDBox p={2}>
                    <Grid container spacing={3} alignItems="center">
                     {/* { (isMobile)&& */}
                     <Grid item>
                        <ArrowBackIosNewSharp onClick={()=>{setCurrentConversationWith(null)}} alt="profile-image" size="xl" shadow="sm" />
                      </Grid>
                      {/*  } */}
                      {/* <Grid item>
                        <MDAvatar src={currentConversationWith.image} alt="profile-image" size="xl" shadow="sm" />
                      </Grid> */}
                      <Grid item>
                        <MDBox height="100%" mt={0.5} lineHeight={1}>
                          <MDTypography variant="h5" fontWeight="medium">
                            {currentConversationWith.title}
                          </MDTypography>
                          <MDTypography variant="button" color="text" fontWeight="medium">
                          {currentConversationWith.email}
                          </MDTypography>
                          <MDTypography variant="body2" color="text" fontWeight="medium">
                          {currentConversationWith.phone}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
                        <MDBox
                          display="flex"
                          justifyContent={{ md: "flex-end" }}
                          alignItems="center"
                          lineHeight={1}
                        >
                          <MDTypography variant="caption" fontWeight="regular">
                            {/* Switch to {visible ? "invisible" : "visible"} */}
                            { currentConversationWith.isJobClosed?<MDTypography variant="subtitle2" color="warning">
                              {"  Closed"} 
                              </MDTypography>:<MDTypography variant="subtitle2" color="success">
                                { " Running" }
                              </MDTypography> }
                           {parseDate(currentConversationWith.createdAt)}
                          </MDTypography>
                          {/* <MDBox ml={1}>
                            <Switch checked={visible} onChange={handleSetVisible} />
                          </MDBox> */}
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Card>
                </Grid>
                
                <Grid item xs={12}>


                <Card id="2fa" sx={{ overflow: "visible" }}>
                  <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                    <MDTypography variant="h5">Details</MDTypography>
                      <Tooltip title="Continue Hiring">
                        <MDButton  size="small" 
                            variant="outlined"
                            color="dark"
                          onClick={()=>{
                            navigate("/Employment/Job-Vacancy-Applications?id="+currentConversationWith.id)
                            // updateInformation({isJobClosed:false,id:currentConversationWith.id},"agencyJobVacancies","update")
                          }}
                          startIcon={<ViewAgenda/>}
                          edge="end" aria-label="view">
                        view requests
                        </MDButton>
                     </Tooltip>
                    <MDBadge variant="contained" color="success" badgeContent={"Posted Date: "+parseDate(currentConversationWith.createdAt)} container />
                  </MDBox>
                  <MDBox p={3}>
                    <MDBox
                      display="flex" 
                    >
                     {/* {(currentConversationWith.description)&& <MDTypography variant="body2" color="text">
                        {currentConversationWith.description}
                      </MDTypography>} */}

                      
              <VacancyFewDetail 
              vacancyData={currentConversationWith}
              isFullData={true}
              // vacancyData={{basicInfo:basicInfoSectionValues,requirements:requirementsSectionValues,benefits:benefitsSectionValues,location:locationSectionValues,application:applicationSectionValues}}
              />

                      {/* <MDBox
                        display="flex"
                        alignItems={{ xs: "flex-start", sm: "center" }}
                        flexDirection={{ xs: "column", sm: "row" }}
                      >
                        <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            Not Configured
                          </MDTypography>
                        </MDBox>
                        <MDButton variant="outlined" color="dark" size="small">
                          set up
                        </MDButton>
                      </MDBox> */}
                    </MDBox>
                  </MDBox>
                </Card>                </Grid>
                {forceRerender}
              </Grid>
            </MDBox>
          </Grid>}
        </Grid>
      </MDBox>
    </DashboardLayout>
    {forceRerender}
 </> );
}

export default JobVacancies;
