 
// Material Dashboard 2 PRO React layouts
 
 import SignInBasic from "layouts/authentication/sign-in/basic";
 
// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";
 

// Images 
import { Deck, List, ListAltOutlined, Logout  } from "@mui/icons-material";
  import React from "react";
import { DataManagementService } from "Api-Services/dataManagementService"; 
import SwitchProfile from "layouts/authentication/profile";
const routesD=()=>{
      
     let profile= DataManagementService.getLocalstorageDataSecured("currentProfile")
      // console.log("profile",profile)
    //  localStorage.
        let logo= ""
        let agencyBrandName= "Switch Profiles"
        if(profile){
              logo=profile.logo 
              agencyBrandName=profile.brandName|| profile.fullName 
        } 
        agencyBrandName=agencyBrandName.slice(0, 15) + "...";
        //   console.log("myBusinessEmploymentAgency",useCurrentUser())
       
      return [
        {
          type: "collapse",
          name: agencyBrandName,
          key: "Switch Profiles",
          icon: logo?<MDAvatar  src={logo} size="sm" />:<ListAltOutlined/>,
          collapse: [
            // {
            //   name: "All Agencies",
            //   key: "My Agencies",
            //   icon: <Deck/>,
            //   route: "/pages/My-Agencies",
            //   component: <MyAgencies />,
            // },
            {
              name: "Switch Profile",
              key: "SwitchProfile",
              icon: <Deck/>,
              route: "/pages/switch-profile",
              component: <SwitchProfile />,
            },
            // {
            //   name: "My P",
            //   key: "profile-settings",
            //   route: "/pages/account/settings",
            //   component: <Settings />,
            // },
            {
              name: "Logout",
              key: "logout",
              icon: <Logout/>,
              route: "/authentication/sign-in/basic",
              component: <SignInBasic />,
            },
          ],
        },
        
      ];
        // }; 
 


}

const routes =routesD()
// const routes=[]

export default routes;
