/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/ecommerce/products/new-product/components/FormField";

function Requirements(props) {
  const [editorValue, setEditorValue] = useState(
    " "
  );  
    const [forceRerender, setForceRerender] = useState(false);
    const [sectionValues, setSectionValues] = useState(props.sectionValues);
     
    useEffect(() => {
      console.log("props.sectionValues", props.sectionValues)
      props.validateSection(props.sectionValues)
    }, []);
  
    const changeInputValue = (field,value) =>
    {
      let sectionValuesD=sectionValues
      sectionValuesD[field].value=value
      setSectionValues(sectionValuesD)
      setForceRerender(!forceRerender)
      props.changeSectionValues("requirementsSectionValues",sectionValuesD)
    }
  
    return (<>
    {forceRerender}
    <MDBox>

{/*   requiredFieldOfStudies: DataTypes.TEXT, 
      employeeResponsiblity: DataTypes.TEXT,    
      requiredSkills: DataTypes.TEXT,    
      requiredExperiances: DataTypes.TEXT,
      minimumExperianceInYear: DataTypes.INTEGER,
      maximumExperianceInYear: DataTypes.INTEGER, */}


      <MDTypography variant="h5">Requirements</MDTypography>
      {/* <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField type="text" label="Title" />
          </Grid> 
        </Grid>
      </MDBox> */}
      <MDBox mt={5}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
              Employee Responsiblity&nbsp;&nbsp;
              {(sectionValues.employeeResponsiblity.required)&& <MDTypography variant="caption" color="text">
                  (required)
                </MDTypography>}
              </MDTypography>
            </MDBox>
            <MDEditor  value={sectionValues.employeeResponsiblity.value}  
            //  onChange={setDescription}  
              onChange={(e)=>{
              if(e){
                  changeInputValue("employeeResponsiblity", e )
              }
            }}/>
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
              Required Skills&nbsp;&nbsp;
              {(sectionValues.requiredSkills.required)&& <MDTypography variant="caption" color="text">
                  (required)
                </MDTypography>}
              </MDTypography>
            </MDBox>
            <MDEditor value={sectionValues.requiredSkills.value}  
            //  onChange={setDescription}  
              onChange={(e)=>{
              if(e){
                  changeInputValue("requiredSkills", e )
              }
            }} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
              Required Experiances&nbsp;&nbsp;
              {(sectionValues.requiredExperiances.required)&& <MDTypography variant="caption" color="text">
                  (required)
                </MDTypography>}
              </MDTypography>
            </MDBox>
            <MDEditor value={sectionValues.requiredExperiances.value}  
            //  onChange={setDescription}  
              onChange={(e)=>{
              if(e){
                  changeInputValue("requiredExperiances", e )
              }
            }}/>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <MDBox mb={3}>
              <MDBox mb={2} display="inline-block">
                <MDTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  Employment Type
                </MDTypography>
              </MDBox>
              <Autocomplete
                defaultValue="FullTime"
                options={["Full Time", "Part Time", "Contract", "Temporery", "Internship"]}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
              />
            </MDBox>
            </Grid> */}
          <Grid item xs={12} sm={6}>
            {/* <MDBox mb={2} display="inline-block"> */}
              <MDTypography
                component="label"
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
              >
                Minimum Experiance (In Year)
              </MDTypography>
            {/* </MDBox> */}
            
            <FormField type="number" 
               defaultValue={sectionValues.minimumExperianceInYear.value}
               onChange={(e)=>{
                  if(e){
                    changeInputValue("minimumExperianceInYear",e.target.value )
                  }
                }} /> 
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <MDBox mb={2} display="inline-block"> */}
              <MDTypography
                component="label"
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
              >
              Maximum Experiance (In Year)
              </MDTypography>
            {/* </MDBox> */}
            
            <FormField type="number" 
               defaultValue={sectionValues.maximumExperianceInYear.value}
               onChange={(e)=>{
                  if(e){
                    changeInputValue("maximumExperianceInYear",e.target.value )
                  }
                }} /> 
          </Grid>
          {/* <Grid item xs={12} sm={6}> 
              <MDTypography
                component="label"
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
              >
                Dead Line
              </MDTypography> 
            
            <FormField type="date"  /> 
          </Grid> */}
        </Grid>
      </MDBox>
    </MDBox></>
  );
}

export default Requirements;
