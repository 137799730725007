/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/pages/profile/components/Header";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings";

// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useEffect } from "react";
import { Button, Card,CardContent, Icon, Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";

function JobSeekerFewDetail(props) {
  const navigate=useNavigate()
  useEffect(async() => {
    console.log("props. ",props )

    if(props.employmentAgencyJobSeekerDetailsData){
       console.log("props.employmentAgencyJobSeekerDetailsData",props.employmentAgencyJobSeekerDetailsData)
    }
  }, [props.employmentAgencyJobSeekerDetailsData]);
  
  const listItem=(label,value,valueType)=>{
    let res=null
    try {
      if(value!=null){
      res=(
        <MDBox key={label} display="flex" py={1} pr={2}>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
            {label}: &nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text">
            &nbsp;
            {valueType?<>
            {(valueType=="file")&& 
                   <MDButton
                   component="a"
                   href={value} 
                   target="_blank"
                   rel="noreferrer"
                   variant="text"
                   color={"info"}
                 >
                   {"Open file"}
                 </MDButton> 
                  
                  }
            {(valueType=="boolean")&&
                  <MDTypography variant={"button"} >
                    {value?"YES":"NO"}
                  </MDTypography>}
            </>:<>{value}
            </>}
          </MDTypography>
        </MDBox>)
      }
    } catch (error) {
      
    }
    return res
  }

  return (<>
    {/* // <DashboardLayout> */}
      {/* <DashboardNavbar /> */}
      <MDBox mb={2} />
    { (props.employmentAgencyJobSeekerDetailsData)&& <Card my={3} mx={3}>
        <CardContent>
        <MDBox  mb={3}>
          <Grid container spacing={1}>
            {/* <Grid item xs={12} md={6} xl={4}>
              <PlatformSettings />
            </Grid> */}
            {/* <Grid item xs={12} md={6} xl={6} sx={{ display: "flex" }}> */}
            {/* <Grid item  xs={12} md={6} xl={5}  sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />


              <Card sx={{ height: "100%", boxShadow: !false && "none" }}>
               <Divider orientation="horizontal"  />
                <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                  <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                    {"Requester information"}
                  </MDTypography> 
                </MDBox>
                <MDBox px={3}  >
                 { (props.employmentAgencyJobSeekerDetailsData.description)&&<MDBox mb={2} lineHeight={1}>
                    <MDTypography variant="button" color="text" fontWeight="light">
                      {props.employmentAgencyJobSeekerDetailsData.description}
                    </MDTypography>
                  </MDBox>}
                  <MDBox opacity={0.3}>
                    <Divider />
                  </MDBox>
                <MDBox>  
    {listItem("Requester Full Name",props.employmentAgencyJobSeekerDetailsData.requesterFullName)}
    {listItem("Requester Phone",props.employmentAgencyJobSeekerDetailsData.requesterPhone)}
    {listItem("Requester Email",props.employmentAgencyJobSeekerDetailsData.requesterEmail)}
    <MDTypography variant="body2" fontWeight="medium" color="text" textTransform="capitalize" mt={3}>
                    {"Requester Working Address:"}
                  </MDTypography>
    {listItem("Country",props.employmentAgencyJobSeekerDetailsData.requesterWorkingCountry)}
    {listItem("City",props.employmentAgencyJobSeekerDetailsData.requesterWorkingCity)}
    {listItem("Sub City",props.employmentAgencyJobSeekerDetailsData.requesterWorkingSubCity)}
    {listItem("Specific Area",props.employmentAgencyJobSeekerDetailsData.requesterWorkingSpecificArea)}
    
    <MDTypography variant="body2" fontWeight="medium" color="text" textTransform="capitalize" mt={3}>
                    {"Requested Criaterias:"}
                  </MDTypography>
    {listItem("JOb",props.employmentAgencyJobSeekerDetailsData.jobsId)}
    {listItem("JOb seeker",props.employmentAgencyJobSeekerDetailsData.jobSeekerId)}
    {listItem("JOb",props.employmentAgencyJobSeekerDetailsData.jobSeekerCareerId)}
    {listItem("Date",props.employmentAgencyJobSeekerDetailsData.createdAt)}
                 
                  </MDBox> 
                </MDBox>
              </Card>

              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid> */}

            {(props.employmentAgencyJobSeekerDetailsData.jobSeeker)&&
    <Grid item xs={12} md={6} xl={7} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <>
                <Card sx={{ height: "100%", boxShadow: !false && "none" }}>
                   <Divider orientation="horizontal"  />
                <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                  <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                    {"Job Seeker information"}
                  </MDTypography>
                  {/* <MDTypography component={Link} to={"action.route"} variant="body2" color="secondary">
                    <Tooltip title={"action.tooltip"} placement="top">
                      <Icon>edit</Icon>
                    </Tooltip>
                  </MDTypography> */}
                </MDBox>

                <MDBox px={3}  >


                {/* </MDBox>
                
                <MDBox p={2}> */}
                  <MDBox opacity={0.3}>
                    <Divider />
                  </MDBox>
                <MDBox> 
                <MDBox key={"name"} component="li" display="flex" alignItems="center" py={1} mb={1}>
                  <MDBox mr={2}>
                    <MDAvatar src={props.employmentAgencyJobSeekerDetailsData.jobSeeker.profileImageUrl} alt="something here" shadow="md" />
                  </MDBox>
                  <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                    <MDTypography variant="button" fontWeight="medium">
                      {props.employmentAgencyJobSeekerDetailsData.jobSeeker.firstName 
                       +" "+props.employmentAgencyJobSeekerDetailsData.jobSeeker.lastName}
                    </MDTypography>
                    {(props.employmentAgencyJobSeekerDetailsData )&&
                    <MDTypography variant="subtitle2" color={props.employmentAgencyJobSeekerDetailsData.isActive?"success":"warning"}>
                      {props.employmentAgencyJobSeekerDetailsData.isActive?"Active":"Not Active"}
                    </MDTypography>}
                    <MDTypography variant="subtitle2" color={props.employmentAgencyJobSeekerDetailsData.jobSeeker.isAvailable?"success":"warning"}>
                      {props.employmentAgencyJobSeekerDetailsData.jobSeeker.isAvailable?"Available":"Not Available"}
                    </MDTypography>
                  </MDBox>
                  <MDBox > 
                      <Button
                           onClick={()=>{
                            window.open("https://baally.com/"+props.employmentAgencyJobSeekerDetailsData.jobSeeker.userName,'_blank' )}}
                        // target="_blank"
                        // rel="noreferrer"
                        variant="text"
                        color={"info"}
                      >
                        {"More Info"}
                      </Button> 
                  </MDBox>
                </MDBox>
    {/* // createdAt
    // createdBy
    // description
    // requesterUserId 
    // statusId
    // statusName */}
    {listItem("Full Name",props.employmentAgencyJobSeekerDetailsData.jobSeeker.firstName+
    " "+props.employmentAgencyJobSeekerDetailsData.jobSeeker.lastName+" "+props.employmentAgencyJobSeekerDetailsData.jobSeeker.middleName)}
    {listItem("Phone",props.employmentAgencyJobSeekerDetailsData.jobSeeker.phone)}
    {listItem("Email",props.employmentAgencyJobSeekerDetailsData.jobSeeker.email)}
      <MDTypography variant="body2" fontWeight="medium" color="text" textTransform="capitalize" mt={3}>
                      {"Careers and Availablity:"}
                    </MDTypography>
    {(props.employmentAgencyJobSeekerDetailsData)&& <> 
      {listItem("Is Active?",props.employmentAgencyJobSeekerDetailsData.isActive,"boolean")}
    </>}
      {listItem("Is Available?",props.employmentAgencyJobSeekerDetailsData.jobSeeker.isAvailable,"boolean")}
    <MDTypography variant="body2" fontWeight="medium" color="text" textTransform="capitalize" mt={3}>
                    {"Address:"}
                  </MDTypography>
    {listItem("Country",props.employmentAgencyJobSeekerDetailsData.jobSeeker.country)}
    {listItem("City",props.employmentAgencyJobSeekerDetailsData.jobSeeker.city)}
    {listItem("Sub City",props.employmentAgencyJobSeekerDetailsData.jobSeeker.subCity)}
    {listItem("Specific Area",props.employmentAgencyJobSeekerDetailsData.jobSeeker.specificArea)}
    
    <MDTypography variant="body2" fontWeight="medium" color="text" textTransform="capitalize" mt={3}>
                    {"Educational files:"}
                  </MDTypography>
    {listItem("Document",props.employmentAgencyJobSeekerDetailsData.jobSeeker.educationalDocumentAttachementUrl,"file")} 
                 
                  </MDBox> 
                 { (props.employmentAgencyJobSeekerDetailsData.jobSeeker.description)&&<MDBox mb={2} lineHeight={1}>
                    <MDTypography variant="body2" fontWeight="medium" color="text" textTransform="capitalize" mt={3}>
                    {"Description:"}
                  </MDTypography>
                   <MDTypography variant="button" color="text" fontWeight="light">
                      {props.employmentAgencyJobSeekerDetailsData.jobSeeker.description}
                    </MDTypography>
                  </MDBox>}
                  
                  {/* <MDBox mb={2} lineHeight={1}>
                  <Button variant="link" fontWeight="medium" color="text" textTransform="capitalize" mt={3}>
                    {"See More Information"}
                  </Button></MDBox> */}
                </MDBox>
              </Card>

              </>
             
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>  } 
            {/* <Grid item xs={12} xl={4}>
              <ProfilesList title="conversations" profiles={profilesListData} shadow={false} />
            </Grid> */}
          </Grid>
        </MDBox>  </CardContent>
      </Card>}
      {/* <Footer /> */}
    {/* // </DashboardLayout> */}
  </>);
}

export default JobSeekerFewDetail;
