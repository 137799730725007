/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/ecommerce/products/new-product/components/FormField";
import { useEffect, useState } from "react";
import { salaryDealTypes } from "Api-master-datas/masterDatas";
import MDEditor from "components/MDEditor";

function Benefits(props) {  
  const [forceRerender, setForceRerender] = useState(false);
  const [sectionValues, setSectionValues] = useState(props.sectionValues);
   
  useEffect(() => {
    console.log("props.sectionValues", props.sectionValues)
    props.validateSection(props.sectionValues)
  }, []);

  const changeInputValue = (field,value) =>
  {
    let sectionValuesD=sectionValues
    sectionValuesD[field].value=value
    setSectionValues(sectionValuesD)
    setForceRerender(!forceRerender)
    props.changeSectionValues("benefitsSectionValues",sectionValuesD)
  }

  return (<>
  {forceRerender} 
    <MDBox>


{/* //Benefits 
      isSalaryMentioned: DataTypes.BOOLEAN, 
      salary: DataTypes.DOUBLE,     
      salaryCurrency: DataTypes.DOUBLE,     
      allowances: DataTypes.TEXT,   
      salaryDuration: DataTypes.DOUBLE,    */}

      <MDTypography variant="h5">Benefits</MDTypography>
      <MDBox mb={5}>
              <MDBox mb={2} display="inline-block">
                <MDTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  Salary Deal&nbsp;&nbsp;
               {(sectionValues.salaryDealTypeId.required)&& <MDTypography variant="caption" color="text">
                  (required)
                </MDTypography>}
                </MDTypography>
              </MDBox>
              <Autocomplete 
                
                defaultValue={props.getDataFromArray(
                  salaryDealTypes,
                    "id",
                    sectionValues.salaryDealTypeId.value,
                    "single"
                  )}
                 
                onChange={(e,val)=>{
                  if(val){
                    if(val.id){  
                      changeInputValue("salaryDealTypeId", val.id)
                    }
                  }
                }}  
                
                getOptionLabel={(option) => option.title}
                options={salaryDealTypes}
                renderInput={(params) => <MDInput {...params}   />}
              />
       </MDBox>
         <>
       {(sectionValues.salaryDealTypeId.value==1) &&
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} lg={12}>
          <MDBox mb={2} display="inline-block">
                <MDTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  Salary Amount &nbsp;&nbsp;
               {(sectionValues.salary.required)&& <MDTypography variant="caption" color="text">
                  (required)
                </MDTypography>}
                </MDTypography>
              </MDBox>
            <FormField type="number" label="Salary"
               defaultValue={sectionValues.salary.value}
               onChange={(e)=>{
                  if(e){
                    changeInputValue("salary",e.target.value )
                  }
                }}  placeholder="99.00" />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
          <MDBox mb={2} display="inline-block">
                <MDTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                 Currency   &nbsp;&nbsp;
               {(sectionValues.salaryCurrency.required)&& <MDTypography variant="caption" color="text">
                  (required)
                </MDTypography>}
                </MDTypography>
              </MDBox>
            <Autocomplete defaultValue={sectionValues.salaryCurrency.value}
              onChange={(e,val)=>{
                if(val){   
                    changeInputValue("salaryCurrency", val)
                  } 
              }}
              options={["ETB", "USD","BTC", "CNY", "EUR", "GBP", "INR"]}
              renderInput={(params) => <MDInput {...params}  />}
            />
          </Grid>
          <Grid item xs={12} sm={5} sx={{ mt: 2 }}>  
          <MDBox mb={2} display="inline-block">
                <MDTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                 Salary Duration    &nbsp;&nbsp;
               {(sectionValues.salaryDuration.required)&& <MDTypography variant="caption" color="text">
                  (required)
                </MDTypography>}
                </MDTypography>
              </MDBox>
          <Autocomplete 
              defaultValue={sectionValues.salaryDuration.value}
              onChange={(e,val)=>{
                if(val){   
                    changeInputValue("salaryDuration", val)
                  } 
              }}
              options={["Hourly", "Daily","Weekly", "Monthly", "Annaul",]}
              renderInput={(params) => <MDInput {...params}   />}
            />
            {/* <FormField type="text" label="SKU" placeholder="71283476591" /> */}
          </Grid> 
        </Grid>
      </MDBox>}
      <MDBox mt={5}>
      <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
              Allowances&nbsp;&nbsp;
              {(sectionValues.allowances.required)&& <MDTypography variant="caption" color="text">
                  (required)
                </MDTypography>}
              </MDTypography>
            </MDBox>
            <MDEditor value={sectionValues.allowances.value}  
            //  onChange={setDescription}  
              onChange={(e)=>{
              if(e){
                  changeInputValue("allowances", e )
              }
            }}
             />
         </MDBox>
          </>
      {/* <MDBox mt={1}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox my={2} display="inline-block">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Tags
              </MDTypography>
            </MDBox>
            <Autocomplete
              multiple
              defaultValue={["In Stock", "Out of Stock"]}
              options={["Black Friday", "Expired", "Out of Stock", "In Stock", "Sale"]}
              renderInput={(params) => <MDInput {...params} variant="standard" />}
            />
          </Grid>
        </Grid>
      </MDBox> */}
    </MDBox>
    </>
  );
}

export default Benefits;
